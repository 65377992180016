import Config from '../Config';
import { RestService } from './RestService';

export const ReturnsService = {
    getReturns: async function(start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction, type){
        return RestService.get(`${Config.get_returns}from=${start}&offset=${numberItems}${ warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${ filter ? `&filter=${filter}` : ''}${ fc_id ? `&fc_id=${fc_id}` : ''}${ interstate ? `&interstate=${interstate}` : ''}${ status ? `&statuses=${status}` : ''}${ item_id ? `&item_id=${item_id}` : ''}${ startdate ? `&startdate=${startdate}` : ''}${ enddate ? `&enddate=${enddate}` : ''}${ type ? `&type=${type}` : ''}&sort=${sort}&direction=${direction}`, {})
        .then(response => response.json());
    },
    getReturnsExport: async function(start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction, type, exportType = 'normal'){
        return RestService.get(`${Config.get_returns_export}from=${start}&offset=${numberItems}${ warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${ filter ? `&filter=${filter}` : ''}${ fc_id ? `&fc_id=${fc_id}` : ''}${ interstate ? `&interstate=${interstate}` : ''}${ status ? `&statuses=${status}` : ''}${ item_id ? `&item_id=${item_id}` : ''}${ startdate ? `&startdate=${startdate}` : ''}${ enddate ? `&enddate=${enddate}` : ''}${ type ? `&type=${type}` : ''}${ exportType ? `&exporttype=${exportType}` : ''}&sort=${sort}&direction=${direction}`, {})
    },
    createReturn: async function(headers, body){
        return RestService.post(`${Config.create_returns}`, headers, body)
        .then(async response => {
            return response.json().then(json => {
                return response.ok ? json : Promise.reject(json)
            });

            // if(response.ok) {
            //     return response.json();
            // } else if(response.status === 409){
            //     throw new Error('shipment_id_taken');
            // }
        });
    },
    getTotalCount: async function(warehouse_id, fc_id, item_id, status, interstate, filter, startdate, enddate, type) {
        return RestService.get(`${Config.total_count}data_type=RETURNS${ warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${ filter ? `&filter=${filter}` : ''}${ fc_id ? `&fc_id=${fc_id}` : ''}${ interstate ? `&interstate=${interstate}` : ''}${ status ? `&statuses=${status}` : ''}${ item_id ? `&item_id=${item_id}` : ''}${ startdate ? `&startdate=${startdate}` : ''}${ enddate ? `&enddate=${enddate}` : ''}${ type ? `&type=${type}` : ''}`, {})
        .then(response => response.json());
    },
    removeReturn: async function(id) {
        return RestService.post(`${Config.remove_returns}id=${id}`, {})
        .then(response => response.json());
    },
    changeReturnStatus: async function(headers, body){
        return RestService.post(`${Config.change_returns_status}`, headers, body);
        // .then(response => response.json());
    },
    importStockTransfer: async function(body) {
        return RestService.postFormData(Config.import_stock_transfer, {}, body)
        .then(response => response.json());
    }
};