import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Divider, Button, Typography, TextField, Paper } from "@material-ui/core";
import { OrganisationService } from "../Services/OrganisationService";

const styles = theme =>({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop : theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        width: "80%",
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    buttonBase: {
        marginLeft: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '30%'
    }
  });

class InvoiceTemplate extends Component {

    constructor(props) {
      super(props);
      this.state = {
        company_data: {
            name: '',
            pan: '',
            bill_to: ''
        },
        error_fields: {pan: false, name: false, bill_to: false}
      }
    }

    componentDidMount(){
        this.props.setTitle('Invoice Template');
        OrganisationService.getOrganisationDetails()
        .then(data => {
            this.setState({company_data: data.organisation});
        })
    }

    handleChange = (e) => {
        let change = this.state.company_data;
        change[e.target.name] = e.target.value;
        let error_fields = this.state.error_fields;
        error_fields[e.target.name] = e.target.value ? false : true;
        
        this.setState({
            company_data: change,
            error_fields: error_fields
        });
    }

    update = () => {
        this.props.showConfirmDialog("Confirm update details?", "Confirm", () => {
            this.props.isLoading(true);
            let headers = {user_id : this.props.user.id };
            OrganisationService.updateOrganisationDetails(headers, this.state.company_data)
            .then(data => {
                this.props.closeConfirmDialog();
                this.props.isLoading(false);
                this.props.showSnackBar(`Updated the invoice template.` , 'success');
            })
            .catch(error => {
                this.props.showSnackBar(`Something went wrong. Please try again later.` , 'error');
                this.props.closeConfirmDialog();
                this.props.isLoading(false);
            });
        })
    }
    
    render() {
        let company_data = this.state.company_data;
        return (
            <div className="Home" style={{color: '#7E7E7E'}}>
                <Paper className={this.props.classes.root} >
                    <TextField
                        id="name"
                        label="Company name Title"
                        placeholder="Company name Title"
                        name="name"
                        value={company_data.name}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        className={this.props.classes.textField}
                        required
                        error={this.state.error_fields.name}
                        helperText="Enter Company name Title"
                        type="text"
                        style={{marginTop: '8px'}}
                        />
                    <TextField
                        id="bill_to"
                        label="Bill to Company name"
                        placeholder="Bill to Company name"
                        name="bill_to"
                        value={company_data.bill_to}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        className={this.props.classes.textField}
                        required
                        error={this.state.error_fields.bill_to}
                        helperText="Enter Bill to Company name"
                        type="text"
                        style={{marginTop: '8px'}}
                        />
                    <TextField
                        id="pan"
                        label="PAN"
                        placeholder="PAN"
                        name="pan"
                        value={company_data.pan}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        className={this.props.classes.textField}
                        required
                        error={this.state.error_fields.pan}
                        helperText="Enter PAN No"
                        type="text"
                        style={{marginTop: '8px'}}
                        />
                    <div className={this.props.classes.buttonBase}>
                        <Button size='large' variant="outlined" color="secondary"  style={{marginRight: 10, marginTop: 8, marginBottom: 5}} onClick={this.update}>
                            Save
                        </Button>
                    </div>
                    <Divider variant="fullWidth"/>
                    <Typography variant="h5" component="h2" align="justify" style={{marginLeft: 60, marginBottom: 15, marginTop: 10}}>
                        <b>Preview</b>
                    </Typography>
                    <Divider variant="fullWidth"/>
                    <div style={{width: '100%', marginLeft: 20, marginRight: 20, backgroundColor: 'white', height: 800, padding: 10}} align="center">
                        <div style={{width: '90%', height: '95%', borderLeft: 2, borderRight: 2, borderTop: 2, borderBottom: 0, marginTop: 20, borderColor: 'black', borderStyle: 'solid'}}>
                            <div style={{height: '25%', borderBottom: 2, borderColor: 'black', borderStyle: 'solid', borderLeft: 0, borderRight: 0, borderTop: 0 }} align="center">
                                <section style={{ paddingTop: 2, paddingLeft: 5, fontSize: 20}}><b>TAX INVOICE</b></section>
                                <section style={{textAlign : 'center', paddingTop: 2, paddingLeft: 6, color: 'black', fontSize: 32, border: 1, borderStyle: 'dotted', borderColor: '#7E7E7E', marginRight: 5, marginLeft: 5}}><b>{company_data.name}</b></section>
                                <section style={{paddingTop: 2, paddingLeft: 5}}>XXX, XXXX XXXX, XXXXX XX. XX, XXXXXXXXX XXXX</section>
                                <section style={{paddingTop: 2, paddingLeft: 5}}>XX XX XXXXX XXXXX X XXXX XXXXXXXX, XXX XXXXX - XXXXXX</section>
                                <section style={{paddingTop: 2, paddingLeft: 5}}>GSTIN No XXXXXXXXXXXXX</section>
                            </div>
                            <div style={{height: '25%', borderBottom: 2, borderColor: 'black', borderStyle: 'solid', borderLeft: 0, borderRight: 0, borderTop: 0 }}>
                                <div style={{float: 'left', width: '75%', paddingTop: 5, paddingLeft: 5, height: '100%', borderBottom: 0, borderLeft: 0, borderRight: 2, borderColor: 'black', borderStyle: 'solid', borderTop: 0 }}>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}><b>Bill To:</b></section>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 6, color: 'black', fontSize: 22, border: 1, borderStyle: 'dotted', borderColor: '#7E7E7E', marginRight: 5}}><b>{company_data.bill_to}</b></section>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXXX</section>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXXX XX XXXXXXX XXXXX XXX XXX</section>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXXX, XX, XXXXXXX, XXXXX, XXX XXX</section>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXXX, XX, XXXXXXX, XXXXX</section>
                                    <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXXX, XXXXXXX</section>
                                </div>
                                <div align='left' style={{float: 'right', width: '25%', height: '100%'}}>
                                    <div style={{height: '50%', borderBottom: 2, borderColor: 'black', borderStyle: 'solid', borderLeft: 0, borderRight: 0, borderTop: 0 }}>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}><b>Invoice No.</b></section>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XX/XX/XX</section>
                                    </div>
                                    <div style={{height: '50%'}}>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}><b>Date</b></section>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXth XX, XXX</section>
                                    </div>
                                </div>
                            </div>
                            <div align="center" style={{paddingTop: 30, height: '30%', borderBottom: 2, borderColor: 'black', borderStyle: 'solid', borderLeft: 0, borderRight: 0, borderTop: 0 }}>
                                ........................
                            </div>
                            <div style={{height: '20%', borderBottom: 2, borderColor: 'black', borderStyle: 'solid', borderLeft: 0, borderRight: 0, borderTop: 0 }}>
                                <div style={{float: 'left', width: '40%', paddingTop: 5, height: '100%', borderBottom: 0, borderLeft: 0, borderRight: 2, borderColor: 'black', borderStyle: 'solid', borderTop: 0 }}>
                                    <div style={{height: '53%', borderBottom: 2, borderColor: 'black', borderStyle: 'solid', borderLeft: 0, borderRight: 0, borderTop: 0 }}>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>Amount (in words)</section>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>Rupees XXXXXX XXXXXX XXXXXX XXXXXX </section>
                                        <section style={{textAlign : 'left', paddingTop: 2, paddingLeft: 5}}>XXXXXX XXXXXX only </section>
                                    </div>
                                    <div style={{height: '47%', width: '100%'}}>
                                        <section style={{paddingTop: 20 ,textAlign : 'left', paddingLeft: 5}}><b>Company's PAN: </b><span style={{color: 'black', border: 1, borderStyle: 'dotted', borderColor: '#7E7E7E'}}><b>{company_data.pan}</b></span></section>
                                    </div>
                                </div>
                                <div align='right' style={{float: 'right', width: '60%', height: '100%'}}>
                                    <section style={{height: '75%',textAlign : 'right', paddingTop: 15, paddingLeft: 6, marginRight: 5}}>For <span style={{color: 'black', border: 1, borderStyle: 'dotted', borderColor: '#7E7E7E'}}>{company_data.name}</span></section>
                                    <section style={{textAlign : 'right', paddingTop: 2, paddingRight: 5}}>Authorised Signatory</section>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(InvoiceTemplate);