import { Button, FormControl, Table, TableBody, TableCell, TableHead, TableRow, TextField, withStyles } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { differenceBy } from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import MyDialog from '../components/MyDialog'

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const AddCartonItems = ({ items: prevItems = [], masterItems, open, loading = false, onClose, onSave }) => {

    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(prevItems)
    }, [prevItems])

    const onChange = (index, name, value) => {
        const itemList = items.map((item, i) => {
            if (index === i) {
                return {
                    ...item,
                    [name]: value
                }
            }
            return item
        })
        setItems(itemList)
    }

    const save = () => {
        onSave(items)
    }

    const remove = (index) => {
        setItems(items.filter((c, i) => i !== index))
    }

    const add = () => {
        setItems(items => ([...items, { item: null, units: 1 }]))
    }

    return (
        <MyDialog
            maxWidth={"lg"}
            content={
                <div>
                    <Table size="small">
                        <TableHead>
                            <Titles />
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => (
                                <ItemRow
                                    key={index}
                                    index={index}
                                    items={items}
                                    masterItems={masterItems}
                                    item={item}
                                    onChange={onChange}
                                    onRemove={remove}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <div style={{ marginTop: 8, marginBottom: 8, textAlign: 'center' }}>
                        <Button variant='contained' color='primary' onClick={add}>Add Item</Button>
                    </div>
                </div>
            }
            title='Items in carton'
            open={open}
            options={<Button variant='contained' color="primary" disabled={loading} onClick={() => save()}>Save</Button>}
            loading={loading}
            close={onClose} />
    )
}

const Titles = () => (
    <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Item</TableCell>
        <TableCell>Units</TableCell>
        <TableCell>Remove</TableCell>
    </TableRow>
)


const ItemRow = ({ index, items, masterItems, item, onChange, onRemove }) => {

    return (
        <StyledTableRow>
            <TableCell size='small'>{index + 1}</TableCell>
            <TableCell size='small'>
                <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                        id="item-selection"
                        disableClearable
                        size='small'
                        options={differenceBy(masterItems, items.map(i => i.item), 'id')}
                        getOptionLabel={option => `${option.name} ${option.fnsku ? `[${option.fnsku}]` : ''} ${option.sku ? `[${option.sku}]` : ''}`}
                        value={item.item}
                        onChange={(event, item) => {
                            onChange(index, 'item', item);
                        }}
                        style={{ marginRight: 16 }}
                        renderInput={params => (
                            <TextField {...params} label="Add Item" variant="outlined" fullWidth />
                        )}
                    />
                </FormControl>
            </TableCell>
            <TableCell size='small'>
                <TextField
                    label="Units"
                    defaultValue={item.units}
                    onChange={(e) => onChange(index, e.target.name, e.target.value)}
                    type="number"
                    name='units'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                />
            </TableCell>
            <TableCell size='small'>
                <DeleteOutline style={{ cursor: 'pointer' }} onClick={() => onRemove(index)} />
            </TableCell>
        </StyledTableRow>
    )
}

export default AddCartonItems