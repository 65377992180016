import Config from '../Config';
import { RestService } from './RestService';

export const FCService = {
    getFCs: async function(){
        return RestService.get(`${Config.get_fc}`, {})
        .then(response => response.json());
    },
    create: async function(headers, body){
        return RestService.post(`${Config.create_fc}`, headers, body);
        // .then(response => response.json());
    },
    remove: async function(headers, id){
        return RestService.post(`${Config.remove_fc}id=${id}`, headers, {});
        // .then(response => response.json());
    }
};