import { Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, withStyles } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import MyDialog from '../components/MyDialog'

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const AddCartons = ({ cartons: prevCartons = [], open, loading = false, onClose, onSave }) => {

    const [cartons, setCartons] = useState([])
    const [header, setHeader] = useState({ weight: '', len: '', breadth: '', height: '' })
    const totalRef = useRef(null)

    useEffect(() => {
        setCartons(prevCartons)
    }, [prevCartons])

    const onChangeTotalRow = (count) => {
        if (typeof count === 'string') count = parseInt(count)
        if (isNaN(count)) return
        if (cartons.length === 0) {
            setCartons(Array(count).fill({
                weight: header.weight,
                len: header.len,
                breadth: header.breadth,
                height: header.height
            }))
        } else if (cartons.length < count) {
            setCartons(prev => ([
                ...prev,
                Array(count - cartons.length).fill({
                    weight: header.weight,
                    len: header.len,
                    breadth: header.breadth,
                    height: header.height
                })
            ]))
        } else if (cartons.length > count) {
            setCartons(cartons.slice(0, count))
        }
    }

    const onChangeHeader = (name, value) => {
        setHeader(header => ({ ...header, [name]: value }))
        const cartonList = [...cartons]
        cartonList.forEach(carton => {
            carton[name] = value
        })
        setCartons(cartonList)
    }

    const onChange = (index, name, value) => {
        const cartonList = cartons.map((carton, i) => {
            if (index === i) {
                return {
                    ...carton,
                    [name]: value
                }
            }
            return carton
        })
        setCartons(cartonList)
    }

    const save = () => {
        onSave(cartons)
    }

    const remove = (index) => {
        setCartons(cartons.filter((c, i) => i !== index))
    }

    return (
        <MyDialog
            maxWidth={"lg"}
            content={
                <Grid container alignItems='center' spacing={3}>
                    <Grid item>
                        Total number of cartons
                    </Grid>
                    <Grid item>
                        <TextField
                            label=""
                            ref={totalRef}
                            defaultValue={prevCartons.length || 0}
                            onBlur={(e) => onChangeTotalRow(e.target.value)}
                            type="number"
                            name='total'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Table size="small">
                                <TableHead>
                                    <Titles />
                                    <HeaderRow onChangeHeader={onChangeHeader} />
                                </TableHead>
                                <TableBody>
                                    {cartons.map((carton, index) => (
                                        <CartonRow
                                            key={index}
                                            index={index}
                                            carton={carton}
                                            onChange={onChange}
                                            onRemove={remove}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Card>
                    </Grid>
                </Grid>
            }
            title='Add Cartons'
            open={open}
            options={<Button variant='contained' color="primary" disabled={loading} onClick={() => save()}>Save</Button>}
            loading={loading}
            close={onClose} />
    )
}

const Titles = () => (
    <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Weight</TableCell>
        <TableCell colSpan={3}>Dimensions</TableCell>
        <TableCell>Remove</TableCell>
    </TableRow>
)

const HeaderRow = ({ onChangeHeader }) => {
    return (
        <TableRow>
            <TableCell size='small' variant='body'></TableCell>
            <TableCell size='small' variant='body'>
                <TextField
                    label=""
                    size='small'
                    defaultValue={0}
                    onChange={(e) => onChangeHeader(e.target.name, e.target.value)}
                    type="number"
                    name='weight'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="kg"
                />
            </TableCell>
            <TableCell size='small' variant='body'>
                <TextField
                    label="Length"
                    size='small'
                    defaultValue={0}
                    onChange={(e) => onChangeHeader(e.target.name, e.target.value)}
                    type="number"
                    name='len'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="cm"
                />
            </TableCell>
            <TableCell size='small' variant='body'>
                <TextField
                    label="Breadth"
                    size='small'
                    defaultValue={0}
                    onChange={(e) => onChangeHeader(e.target.name, e.target.value)}
                    type="number"
                    name='breadth'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="cm"
                />
            </TableCell>
            <TableCell size='small' variant='body'>
                <TextField
                    label="Height"
                    size='small'
                    defaultValue={0}
                    onChange={(e) => onChangeHeader(e.target.name, e.target.value)}
                    type="number"
                    name='height'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="cm"
                />
            </TableCell>
            <TableCell size='small' variant='body'>

            </TableCell>
        </TableRow>
    )
}


const CartonRow = ({ index, carton, onChange, onRemove }) => {
    return (
        <StyledTableRow>
            <TableCell size='small'>{index + 1}</TableCell>
            <TableCell size='small'>
                <TextField
                    label=""
                    size='small'
                    value={carton.weight}
                    onChange={(e) => onChange(index, e.target.name, e.target.value)}
                    type="number"
                    name='weight'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="kg"
                />
            </TableCell>
            <TableCell size='small'>
                <TextField
                    label="Length"
                    size='small'
                    value={carton.len}
                    onChange={(e) => onChange(index, e.target.name, e.target.value)}
                    type="number"
                    name='len'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="cm"
                />
            </TableCell>
            <TableCell size='small'>
                <TextField
                    label="Breadth"
                    size='small'
                    value={carton.breadth}
                    onChange={(e) => onChange(index, e.target.name, e.target.value)}
                    type="number"
                    name='breadth'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="cm"
                />
            </TableCell>
            <TableCell size='small'>
                <TextField
                    label="Height"
                    size='small'
                    value={carton.height}
                    onChange={(e) => onChange(index, e.target.name, e.target.value)}
                    type="number"
                    name='height'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="cm"
                />
            </TableCell>
            <TableCell size='small'>
                <DeleteOutline style={{ cursor: 'pointer' }} onClick={() => onRemove(index)} />
            </TableCell>
        </StyledTableRow>
    )
}

export default AddCartons