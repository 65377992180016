import React, { Component } from "react";
import { Button, ListItemText, TableCell, TableRow, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ShipmentService } from "../../Services/ShipmentService";
import MyDialog from '../../components/MyDialog';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { formatNumber } from "../../Utils";

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class ItemDisplayDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            link: null,
            openPreview: false,
            weight: 0,
            file: null,
            loading: false
        }
        this.uploadImage = this.uploadImage.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    uploadImage(event) {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            this.setState({ link: URL.createObjectURL(fileUploaded), openPreview: true, file: fileUploaded });
        }
    }

    onSave() {
        if (this.state.file && this.state.weight) {
            const item = this.props.item;
            let reader = new FileReader();
            reader.readAsDataURL(this.state.file);
            let name = this.state.file.name;
            reader.onload = (e) => {
                this.setState({ loading: true })
                ShipmentService.uploadItemImage({ name: name, file: e.target.result, shipment_id: item.shipment_id, item_id: item.item_id, weight: this.state.weight })
                    .then(() => {
                        this.setState({ link: null, openPreview: false, file: null, weight: 0 });
                        this.props.refresh && this.props.refresh()
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => this.setState({ loading: false }))
            }
        }
    }

    render() {
        const item = this.props.item;
        const interstate = this.props.interstate;
        const classes = this.props.classes;
        const item_details = this.props.master_items.filter(i => i.id === item.item_id)[0];
        return (
            <TableRow key={item.item_id} className={classes.row}>
                <CustomTableCell style={{ paddingRight: 5 }}>
                    {
                        (item.images ? item.images.length : 0) < item.no_of_cases && (
                            <label htmlFor={`image-${item.item_id}`}>
                                <UploadIcon fontSize='small' />
                                <input onChange={this.uploadImage} id={`image-${item.item_id}`} type="file" name="item-image" accept="image/*" multiple={false} style={{ display: 'none' }} />
                            </label>
                        )
                    }
                    <div>Uploaded: {item.images ? item.images.length : 0}/{item.no_of_cases}</div>
                </CustomTableCell>
                <CustomTableCell style={{ paddingRight: 5 }}>
                    <ListItemText
                        primary={item.item_name}
                        secondary={interstate ? `IGST : ${item.igst}` : `CGST : ${item.cgst} SGST : ${item.sgst}`}
                        style={{ width: 140, paddingRight: 5 }} />
                </CustomTableCell>
                <CustomTableCell align="left" padding='none'>{item_details.fnsku}</CustomTableCell>
                <CustomTableCell align="right" padding='none'>{item_details.hsn_code}</CustomTableCell>
                <CustomTableCell align="right" padding='none'>{formatNumber(item.rate)}</CustomTableCell>
                <CustomTableCell align="right" padding='none' style={{paddingRight: 8}}>{item.units}</CustomTableCell>
                <MyDialog
                    maxWidth={"xs"}
                    content={
                        <div style={{ width: 200, height: 200 }}>
                            {this.state.loading ? (
                                <div style={{ position: "absolute", top: '50%', left: "50%" }}>
                                    <HourglassEmptyIcon />
                                </div>
                            ) : (
                                <div>
                                    <img
                                        src={this.state.link}
                                        height="300" width="300"
                                        alt="" />

                                    <div style={{ width: '100%' }}>
                                        <TextField
                                            label="Weight"
                                            onChange={(e) => this.setState({ weight: e.target.value })}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            required
                                            helperText="Actual Weight"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    }
                    title={item.item_name}
                    open={this.state.openPreview}
                    options={<Button variant='contained' color="primary" disabled={this.state.loading} onClick={this.onSave}>Save</Button>}
                    loading={this.state.loading}
                    close={() => {
                        this.setState({ link: null, openPreview: false, file: null, weight: 0 });
                    }} />
            </TableRow>
        );
    }
}

export default ItemDisplayDetails