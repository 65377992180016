import React from 'react';
import {
    Grid,
    Divider,
    Fade,
    LinearProgress,
    Button,
    IconButton,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Paper,
    TextField,
    Icon,
    Tooltip,
    Card,
    CardHeader,
    CardContent,
    Avatar,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { WarehouseService } from '../Services/WarehouseService';
import { ItemService } from '../Services/ItemService';
import { FCService } from '../Services/FCService';
import XLSX from 'xlsx';
import moment from 'moment';
import { format, format_export_planner } from '../Utils';
import { ShipmentService } from '../Services/ShipmentService';
import AddCartonItems from './add-carton-items';
import { flatten, isEmpty, orderBy, uniqBy, uniqueId, uniqWith } from 'lodash';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import { grey } from '@material-ui/core/colors';
import { useRef } from 'react';
import { useState } from 'react';
import MyDialog from '../components/MyDialog';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    subheadingLink: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left",
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer"
    },
    formControl: {
        margin: theme.spacing(1),
        // marginTop: theme.spacing(1),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
        float: 'left'
    },
    formControlItem: {
        width: 120,
        float: 'left',
        marginTop: 10,
        marginBottom: 2
    },
    formControlLeft: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    itemListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        height: '65vh',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            minHeight: 0,
            height: '100%'
        },
        overflow: 'auto',
        paddingLeft: 5,
        paddingRight: 5,
    },
    divider: {
        marginTop: 10,
        marginBottom: 10
    }
}));

// const dummy = [
//     {
//         "fc": {
//             "id": "a831e43e-8b0f-458b-9acf-a4b9ea9f2184",
//             "name": "BLR7",
//             "address1": "Sy No. 524/1,2,3,4,6, 525/1,2,3,4,5,6, 526/3,4,5,6,527 of madivala village",
//             "address2": "Sy no.51/1 of thatanahalli village kasaba hobli",
//             "address3": "anekal taluk, Bangalore - 562107",
//             "address4": "Karnataka",
//             "gstin": "29ABGFA6364G1Z9",
//             "status": 1,
//             "organisation": "aarti",
//             "modified_date": "2021-06-28 12:01:33"
//         },
//         "row_id": "fc-1",
//         "cartons": [
//             {
//                 "carton_id": "carton-2",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
//                             "name": "Lemongrass 15ML",
//                             "asin": "B01MYY2RR6",
//                             "fnsku": "X000NFEOZH",
//                             "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012942",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-4",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "5e242e3d-b172-4e70-88be-6454d11f43e2",
//                             "name": "Rosemary 15ML",
//                             "asin": "B06XX4DTV8",
//                             "fnsku": "X000O2A8CR",
//                             "sku": "ASG-OM-ROSEMARY-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012938",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-5",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
//                             "name": "Lemongrass 15ML",
//                             "asin": "B01MYY2RR6",
//                             "fnsku": "X000NFEOZH",
//                             "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012942",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-6",
//                 "weight": "6.3",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "a227e5ed-0bd8-4cf1-8f22-55dbd6a5042b",
//                             "name": "Witch Hazel Astrigent",
//                             "asin": "B07T1L7MRV",
//                             "fnsku": "X0011C4POB",
//                             "sku": "ASG-OM-ASTRIGENT-120ML",
//                             "hsn_code": "33049190",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 199,
//                             "status": 1
//                         },
//                         "units": "5"
//                     },
//                     {
//                         "item": {
//                             "id": "0232658f-9f3d-498b-b0be-2d4cb051c8ad",
//                             "name": "Peppermint 15ML",
//                             "asin": "B07SWJ9SXX",
//                             "fnsku": "X0011BHGS9",
//                             "sku": "ASG-OM-PEPPERMINT-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012400",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "88"
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         "fc": {
//             "id": "0d1e907a-eeb8-4ef4-91df-b209a57a2182",
//             "name": "BLR8",
//             "address1": "Amazon Seller Services Pvt. Ltd.",
//             "address2": "Building 2 (Wh 2), Plot no. 12/P2 (IT Sector)",
//             "address3": "Bengaluru 562149",
//             "address4": "Karnataka",
//             "gstin": "29ABGFA6364G1Z9",
//             "status": 1,
//             "organisation": "aarti",
//             "modified_date": "2021-08-14 18:28:47"
//         },
//         "row_id": "fc-7",
//         "cartons": [
//             {
//                 "carton_id": "carton-8",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
//                             "name": "Lemongrass 15ML",
//                             "asin": "B01MYY2RR6",
//                             "fnsku": "X000NFEOZH",
//                             "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012942",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-9",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "5e242e3d-b172-4e70-88be-6454d11f43e2",
//                             "name": "Rosemary 15ML",
//                             "asin": "B06XX4DTV8",
//                             "fnsku": "X000O2A8CR",
//                             "sku": "ASG-OM-ROSEMARY-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012938",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-10",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
//                             "name": "Lemongrass 15ML",
//                             "asin": "B01MYY2RR6",
//                             "fnsku": "X000NFEOZH",
//                             "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012942",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-11",
//                 "weight": "6.3",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "a227e5ed-0bd8-4cf1-8f22-55dbd6a5042b",
//                             "name": "Witch Hazel Astrigent",
//                             "asin": "B07T1L7MRV",
//                             "fnsku": "X0011C4POB",
//                             "sku": "ASG-OM-ASTRIGENT-120ML",
//                             "hsn_code": "33049190",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 199,
//                             "status": 1
//                         },
//                         "units": "5"
//                     },
//                     {
//                         "item": {
//                             "id": "0232658f-9f3d-498b-b0be-2d4cb051c8ad",
//                             "name": "Peppermint 15ML",
//                             "asin": "B07SWJ9SXX",
//                             "fnsku": "X0011BHGS9",
//                             "sku": "ASG-OM-PEPPERMINT-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012400",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "88"
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         "fc": {
//             "id": "b92bd9fe-8989-4f1a-8876-a8aa06e7fe61",
//             "name": "BOM5",
//             "address1": "Renaissance Industrial Smart City, Kalyan Sape Road",
//             "address2": "Vashere Village, Amane Post,",
//             "address3": "Thane, 421302",
//             "address4": "Maharashtra",
//             "gstin": "27ABGFA6364G1ZD",
//             "status": 1,
//             "organisation": "aarti",
//             "modified_date": "2021-06-29 05:58:54"
//         },
//         "row_id": "fc-12",
//         "cartons": [
//             {
//                 "carton_id": "carton-13",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
//                             "name": "Lemongrass 15ML",
//                             "asin": "B01MYY2RR6",
//                             "fnsku": "X000NFEOZH",
//                             "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012942",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-14",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "5e242e3d-b172-4e70-88be-6454d11f43e2",
//                             "name": "Rosemary 15ML",
//                             "asin": "B06XX4DTV8",
//                             "fnsku": "X000O2A8CR",
//                             "sku": "ASG-OM-ROSEMARY-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012938",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-15",
//                 "weight": "10.2",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
//                             "name": "Lemongrass 15ML",
//                             "asin": "B01MYY2RR6",
//                             "fnsku": "X000NFEOZH",
//                             "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012942",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "176"
//                     }
//                 ]
//             },
//             {
//                 "carton_id": "carton-16",
//                 "weight": "6.3",
//                 "dimension": "34x24x16",
//                 "items": [
//                     {
//                         "item": {
//                             "id": "a227e5ed-0bd8-4cf1-8f22-55dbd6a5042b",
//                             "name": "Witch Hazel Astrigent",
//                             "asin": "B07T1L7MRV",
//                             "fnsku": "X0011C4POB",
//                             "sku": "ASG-OM-ASTRIGENT-120ML",
//                             "hsn_code": "33049190",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 199,
//                             "status": 1
//                         },
//                         "units": "5"
//                     },
//                     {
//                         "item": {
//                             "id": "0232658f-9f3d-498b-b0be-2d4cb051c8ad",
//                             "name": "Peppermint 15ML",
//                             "asin": "B07SWJ9SXX",
//                             "fnsku": "X0011BHGS9",
//                             "sku": "ASG-OM-PEPPERMINT-ESSENTIAL-OIL-15ML",
//                             "hsn_code": "33012400",
//                             "igst": 18,
//                             "cgst": 9,
//                             "sgst": 9,
//                             "rate": 75,
//                             "status": 1
//                         },
//                         "units": "88"
//                     }
//                 ]
//             }
//         ]
//     }
// ]

const dummy = [] || [
    {
        "fc": {
            "id": "0d1e907a-eeb8-4ef4-91df-b209a57a2182",
            "name": "BLR8",
            "address1": "Amazon Seller Services Pvt. Ltd.",
            "address2": "Building 2 (Wh 2), Plot no. 12/P2 (IT Sector)",
            "address3": "Bengaluru 562149",
            "address4": "Karnataka",
            "gstin": "29ABGFA6364G1Z9",
            "status": 1,
            "organisation": "aarti",
            "modified_date": "2021-08-14 18:28:47"
        },
        "row_id": "fc-dum-0",
        "cartons": [
            {
                "carton_id": "carton-dum-2",
                "weight": "4.8",
                "dimension": "34x24x16",
                "items": [
                    {
                        "item": {
                            "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
                            "name": "Lemongrass 15ML",
                            "asin": "B01MYY2RR6",
                            "fnsku": "X000NFEOZH",
                            "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33012942",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "88"
                    },
                    {
                        "item": {
                            "id": "41d55726-6091-4bbb-a057-3441e518c75e",
                            "name": "Lemon 15ML",
                            "asin": "B0797WYQKS",
                            "fnsku": "X000SVV50D",
                            "sku": "ASG-OM-LEMON-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33011300",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "25"
                    },
                    {
                        "item": {
                            "id": "c745e10e-0866-445e-ba79-c8a5c543a3ac",
                            "name": "Jojoba30ML",
                            "asin": "B082MJTZ35",
                            "fnsku": "X0014D6BLH",
                            "sku": "OM-JOJOBA-ORGANIC-CARRIER-OIL-30ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 99,
                            "status": 1
                        },
                        "units": "5"
                    },
                    {
                        "item": {
                            "id": "78a7ddb9-472a-4c66-928f-31f318a7649d",
                            "name": "Sweet Almond Oil 120ML",
                            "asin": "B0BGXPP65Q",
                            "fnsku": "X001P92VPF",
                            "sku": "AOM-SWEET-ALMOND-CARRIER-OIL-120ML",
                            "hsn_code": "33049990",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 347,
                            "status": 1
                        },
                        "units": "5"
                    },
                    {
                        "item": {
                            "id": "efe7b467-20a5-4a56-8d15-88cb9d9041e2",
                            "name": "Olive Oil 120ML",
                            "asin": "B0BRYN54XH",
                            "fnsku": "X001P8ZPY5",
                            "sku": "OM-OLIVE-CARRIER-OIL-120ML",
                            "hsn_code": "15099090",
                            "igst": 5,
                            "cgst": 2.5,
                            "sgst": 2.5,
                            "rate": 479,
                            "status": 1
                        },
                        "units": "3"
                    }
                ]
            }
        ]
    },
    {
        "fc": {
            "id": "a831e43e-8b0f-458b-9acf-a4b9ea9f2184",
            "name": "BLR7",
            "address1": "Sy No. 524/1,2,3,4,6, 525/1,2,3,4,5,6, 526/3,4,5,6,527 of madivala village",
            "address2": "Sy no.51/1 of thatanahalli village kasaba hobli",
            "address3": "anekal taluk, Bangalore - 562107",
            "address4": "Karnataka",
            "gstin": "29ABGFA6364G1Z9",
            "status": 1,
            "organisation": "aarti",
            "modified_date": "2021-06-28 12:01:33"
        },
        "row_id": "fc-dum-1",
        "cartons": [
            {
                "carton_id": "carton-dum-2",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "f0572566-55e6-42b5-9ddc-35465803bc3c",
                            "name": "Lemongrass 15ML",
                            "asin": "B01MYY2RR6",
                            "fnsku": "X000NFEOZH",
                            "sku": "ASGA-OM-LEMONGRASS-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33012942",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-3",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "5e242e3d-b172-4e70-88be-6454d11f43e2",
                            "name": "Rosemary 15ML",
                            "asin": "B06XX4DTV8",
                            "fnsku": "X000O2A8CR",
                            "sku": "ASG-OM-ROSEMARY-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33012938",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-4",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "5e242e3d-b172-4e70-88be-6454d11f43e2",
                            "name": "Rosemary 15ML",
                            "asin": "B06XX4DTV8",
                            "fnsku": "X000O2A8CR",
                            "sku": "ASG-OM-ROSEMARY-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33012938",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-5",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "a55a3d8b-4920-492f-adb5-32835696600f",
                            "name": "TeaTree15ML",
                            "asin": "B082MGDKCL",
                            "fnsku": "X0014CWIWJ",
                            "sku": "OM-TEA-TREE-ESSENTIAL-OIL-ORGANIC-15ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-6",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "a55a3d8b-4920-492f-adb5-32835696600f",
                            "name": "TeaTree15ML",
                            "asin": "B082MGDKCL",
                            "fnsku": "X0014CWIWJ",
                            "sku": "OM-TEA-TREE-ESSENTIAL-OIL-ORGANIC-15ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-7",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "a55a3d8b-4920-492f-adb5-32835696600f",
                            "name": "TeaTree15ML",
                            "asin": "B082MGDKCL",
                            "fnsku": "X0014CWIWJ",
                            "sku": "OM-TEA-TREE-ESSENTIAL-OIL-ORGANIC-15ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-8",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "83b90538-2126-4b02-bb36-6fa33a2fba2c",
                            "name": "Lavender15ML",
                            "asin": "B082MDMPTV",
                            "fnsku": "X0014CZPH9",
                            "sku": "OM-LAVENDER-ESSENTIAL-OIL-PURE-15ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-9",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "83b90538-2126-4b02-bb36-6fa33a2fba2c",
                            "name": "Lavender15ML",
                            "asin": "B082MDMPTV",
                            "fnsku": "X0014CZPH9",
                            "sku": "OM-LAVENDER-ESSENTIAL-OIL-PURE-15ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-10",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "83b90538-2126-4b02-bb36-6fa33a2fba2c",
                            "name": "Lavender15ML",
                            "asin": "B082MDMPTV",
                            "fnsku": "X0014CZPH9",
                            "sku": "OM-LAVENDER-ESSENTIAL-OIL-PURE-15ML",
                            "hsn_code": "33019090",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-11",
                "weight": "10.2",
                "dimension": "34x24.x16",
                "items": [
                    {
                        "item": {
                            "id": "0232658f-9f3d-498b-b0be-2d4cb051c8ad",
                            "name": "Peppermint 15ML",
                            "asin": "B07SWJ9SXX",
                            "fnsku": "X0011BHGS9",
                            "sku": "ASG-OM-PEPPERMINT-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33012400",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "176"
                    }
                ]
            },
            {
                "carton_id": "carton-dum-12",
                "weight": "6.3",
                "dimension": "34x24x16",
                "items": [
                    {
                        "item": {
                            "id": "debbe2f1-6593-4e57-a38b-e4867341164f",
                            "name": "Vitamin C Astringent",
                            "asin": "B07T1L7MRV",
                            "fnsku": "X001KDETRT",
                            "sku": "ASG-OM-ASTRINGENT-120ML",
                            "hsn_code": "33049190",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 99,
                            "status": 1
                        },
                        "units": "5"
                    },
                    {
                        "item": {
                            "id": "0232658f-9f3d-498b-b0be-2d4cb051c8ad",
                            "name": "Peppermint 15ML",
                            "asin": "B07SWJ9SXX",
                            "fnsku": "X0011BHGS9",
                            "sku": "ASG-OM-PEPPERMINT-ESSENTIAL-OIL-15ML",
                            "hsn_code": "33012400",
                            "igst": 18,
                            "cgst": 9,
                            "sgst": 9,
                            "rate": 75,
                            "status": 1
                        },
                        "units": "88"
                    }
                ]
            }
        ]
    }
]

export default function ShipmentPlanner(props) {
    const [loading, setLoading] = React.useState(false);

    const [selectedWarehouse, setSelectedWarehouse] = React.useState('');
    const [selectedFC, setSelectedFC] = React.useState('');
    const [warehouses, setWarehouses] = React.useState([]);
    const [warehouseNames, setWarehouseNames] = React.useState([]);
    const [fcs, setFCs] = React.useState([]);
    const [fcNames, setFCNames] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [planRows, setPlanRows] = React.useState(dummy || []);
    const [showAddCartonItems, setShowAddCartonItems] = React.useState({ row_id: null, carton_id: null, items: [], open: false });

    const classes = useStyles()

    React.useEffect(() => {
        props.setTitle('Shipment Planner New');
        WarehouseService.getWarehouses()
            .then((data) => {
                let warehouse_names = data.warehouses.map((w) => (
                    <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
                ));
                setWarehouses(data.warehouses);
                setWarehouseNames(warehouse_names);
            })
            .catch((error) => {
                console.log("Error while fetching Warehouse list");
            });

        ItemService.getItemList()
            .then((data) => {
                setItems(data.items);
            })
            .catch(error => {
                console.log(error);
            });

        FCService.getFCs()
            .then((data) => {
                let fc_names = data.fcs.map((fc) => (
                    <MenuItem key={fc.id} value={fc.id}>{fc.name}</MenuItem>
                ));
                setFCs(data.fcs)
                setFCNames(fc_names);
            })
            .catch(error => {
                console.log(error);
            });

        return () => {
            setSelectedWarehouse(undefined);
        }
    }, []);

    const handleWarehouseSelection = (e) => {
        setSelectedWarehouse(e.target.value);
    }

    const handleFCSelection = () => {
        const fc = fcs.find(fc => fc.id === selectedFC)
        const row = {
            fc,
            row_id: uniqueId('fc-'),
            cartons: []
        }
        setPlanRows([...planRows, row])
        setSelectedFC('')
    }

    const reset = () => {
        props.showConfirmDialog("Confirm Reset Plan?", undefined, () => {
            resetAction();
            props.closeConfirmDialog();
        });
    }

    const resetAction = () => {
        setPlanRows([]);
        setSelectedWarehouse('');
        setSelectedFC('');
    };

    const allFieldsPopulated = () => {
        return planRows.filter(row => row.fc_id === '' || row.weight === '' || row.dimension === '' || row.cartons.length === 0).length === 0;
    }

    const createPlan = () => {
        if (planRows.length > 0 && allFieldsPopulated() && selectedWarehouse) {
            props.showConfirmDialog("Create Plan?", undefined, () => {
                setLoading(true);

                if (planRows.length > 0 && selectedWarehouse) {
                    let warehouse = warehouses.filter(w => w.id === selectedWarehouse)[0];

                    // Step 1 Export Plan
                    let plans = [
                        [
                            { v: 'S.No.', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Shipment Date', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'FC', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Shipment ID', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Invoice No.', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Merchant SKU', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Units', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'No of Cartons', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Weight of Carton', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Total Weight Per SKU', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Dimensions', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Total Cartons FC Wise', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } },
                            { v: 'Total Weight FC Wise', s: { font: { bold: true }, fill: { patternType: 'solid', bgColor: { rgb: '#999999' } } } }]];

                    planRows.forEach((fcRow, index) => {
                        const basic = [null, fcRow.fc.name, null, null]
                        let items = [];

                        const singleItemCartons = fcRow.cartons.filter(c => c.items.length === 1);
                        const multiItemCartons = fcRow.cartons.filter(c => c.items.length > 1);

                        // Processing Single Item Cartons
                        const distinctSKUs = new Set(flatten(singleItemCartons.map(c => c.items)).map(i => i.item.sku))
                        distinctSKUs.forEach(sku => {
                            const skuRows = flatten(singleItemCartons.map(c => c.items)).filter(i => i.item.sku === sku)
                            const units = uniqBy(skuRows, 'units').map(u => u.units)
                            units.forEach(unit => {
                                const rows = skuRows.filter(row => row.units === unit)
                                const c = singleItemCartons.find(c => c.items.findIndex(i => i.item.sku === sku && i.units === unit) > -1)
                                items.push([sku, Number(unit), rows.length || 0, Number(c.weight), (rows.length || 0) * Number(c.weight), c.dimension])
                            })
                        })
                        items.forEach((item, j) => {
                            let first = null
                            let last = [null, null]
                            if (j === 0) {
                                first = index + 1
                                last = [fcRow.cartons.length, fcRow.cartons.reduce((sum, next) => sum + Number(next.weight), 0)]
                            }
                            plans.push([first, ...basic, ...item, ...last])
                        })

                        // Processing Multi Item Cartons
                        multiItemCartons.forEach((carton) => {
                            carton.items.forEach((item, k) => {
                                let first = null
                                let last = [null, null]
                                let itemDetails = [item.item.sku, Number(item.units)]
                                if (k === 0) {
                                    if (singleItemCartons.length === 0) {
                                        first = index + 1
                                        last = [fcRow.cartons.length, fcRow.cartons.reduce((sum, next) => sum + Number(next.weight), 0)]
                                    }
                                    itemDetails.push(1, Number(carton.weight), '-', carton.dimension)
                                } else {
                                    itemDetails.push('-', '-', '-', '-')
                                }
                                plans.push([first, ...basic, ...itemDetails, ...last])
                            })
                        })
                    })

                    // Create Tab delimited data
                    let tabArray = [
                        'Please review the Example tab before you complete this sheet								',
                        '								',
                        '								',
                        'Default prep owner	Seller							',
                        'Default labeling owner	Seller							',
                        'Default prep category	No prep needed							',
                        '								',
                        'Mandatory			Optional 			Optional: Use only for providing tax details		',
                        'Merchant SKU	Quantity	FC	Prep owner	Labeling owner	Prep category	HSN/SAC code	GST rate	Declared value(per unit)',
                    ]

                    const items = flatten(flatten(planRows.map(fcRow => flatten(fcRow.cartons.map(c => c.items.map(i => ({ ...i, fc: fcRow.fc.name })))))))
                    const uniqueItems = orderBy(uniqWith(items, (arr1, arr2) => arr1.item.sku === arr2.item.sku && arr1.fc === arr2.fc), 'item.sku')

                    uniqueItems.forEach(item => {
                        const gst = item.item.igst ? item.item.igst : (item.item.cgst + item.item.sgst)
                        const units = items.filter(i => i.item.sku === item.item.sku && i.fc === item.fc).reduce((sum, next) => sum + Number(next.units), 0)
                        let arr = [item.item.sku, units, item.fc, 'Seller', 'Seller', 'No prep needed', item.item.hsn_code, gst, item.item.rate]
                        tabArray.push(arr.join('\t'))
                    })

                    tabArray.push('')

                    const wb = XLSX.utils.book_new();
                    const wsAll = XLSX.utils.aoa_to_sheet(plans);
                    XLSX.utils.book_append_sheet(wb, wsAll, 'Planning');
                    const fileName = `Shipment_${moment().format(format_export_planner)}`;
                    XLSX.writeFile(wb, `${fileName}.xlsx`);

                    const element = document.createElement("a");
                    const file = new Blob([tabArray.join('\n')], { type: 'text/plain' });
                    element.href = URL.createObjectURL(file);
                    element.download = `${fileName}.txt`;
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();

                    // Step 2 Create Shipments
                    let headers = { user_id: props.user.id };
                    Promise.all(planRows.map(fcRow => {
                        // Insert into Shipment
                        let new_shipment = {
                            warehouse_id: warehouse.id,
                            fc_id: fcRow.fc.id,
                            shipment_date: moment().utc().format(format),
                            invoice_no: '',
                            interstate: '1',
                            is_new: true,
                            items: []
                        };
                        new_shipment.cartons = fcRow.cartons.map(c => ({
                            weight: Number(c.weight),
                            dimension: c.dimension,
                        }))
                        new_shipment.items = []
                        const distinctSKUs = new Set(flatten(fcRow.cartons.map(c => c.items)).map(i => i.item.sku))
                        distinctSKUs.forEach(sku => {
                            const skuRows = flatten(fcRow.cartons.map(c => c.items)).filter(i => i.item.sku === sku)
                            const units = skuRows.reduce((sum, next) => sum + Number(next.units), 0)
                            new_shipment.items.push({
                                item_id: skuRows[0].item.id,
                                units: units,
                                status: "1",
                                rate: skuRows[0].item.rate,
                                igst: skuRows[0].item.igst,
                                cgst: skuRows[0].item.cgst,
                                sgst: skuRows[0].item.sgst
                            })
                        })

                        return ShipmentService.createShipmentV2(headers, new_shipment)
                    }))
                        .then((data) => {
                            props.showSnackBar('Shipments Created', 'error');
                        })
                        .catch(error => console.error(error))

                }
                props.closeConfirmDialog();
                setLoading(false);
            });
        } else {
            let message = `Please select ${selectedWarehouse.length === 0 ? 'warehouse' : ''} ${selectedWarehouse.length === 0 && planRows.length === 0 ? 'and' : ''} ${planRows.length === 0 ? 'atleast one product' : ''}`;
            props.showSnackBar(message, 'error');
        }
    }

    const addCarton = (row_id, cartons) => {
        setPlanRows(planRows => (planRows.map(row => {
            if (row.row_id === row_id) {
                if (isEmpty(cartons)) {
                    return {
                        ...row,
                        cartons: [...row.cartons, {
                            carton_id: uniqueId('carton-'),
                            weight: "",
                            dimension: "",
                            items: []
                        }]
                    }
                }
                return {
                    ...row,
                    cartons: [...row.cartons, ...cartons.map(carton => ({
                        ...carton,
                        carton_id: uniqueId('carton-')
                    }))]
                }
            }
            return row
        })))
    }

    const removeCarton = (row_id, carton_id) => {
        setPlanRows(planRows => (planRows.map(row => {
            if (row.row_id === row_id) {
                return {
                    ...row,
                    cartons: row.cartons.filter(carton => carton.carton_id !== carton_id)
                }
            }
            return row
        })))
    }

    const removeFC = (row_id) => {
        setPlanRows(planRows => planRows.filter(row => row.row_id !== row_id))
    }

    const copyFC = (row_id, fc_id) => {
        const fc = fcs.find(fc => fc.id === fc_id)
        const row = {
            fc,
            row_id: uniqueId('fc-'),
            cartons: planRows.find(row => row.row_id === row_id).cartons.map(carton => ({
                ...carton,
                carton_id: uniqueId('carton-')
            }))
        }
        setPlanRows([...planRows, row])
        setSelectedFC('')
    }

    const onChange = (row_id, carton_id, field, value) => {
        setPlanRows(planRows => (planRows.map(row => {
            if (row.row_id === row_id) {
                return {
                    ...row,
                    cartons: row.cartons.map(carton => {
                        if (carton.carton_id === carton_id) {
                            carton[field] = value
                        }
                        return carton
                    })
                }
            }
            return row
        })))
    }

    const updateItems = (row_id, carton_id) => {
        const items = planRows.find(row => row.row_id === row_id).cartons.find(carton => carton.carton_id === carton_id).items
        setShowAddCartonItems({ row_id, carton_id, items, open: true })
    }

    return (
        <div className="Home" style={{ marginTop: 15 }}>
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '50ms' : '0ms',
                }}
                unmountOnExit
            >
                <LinearProgress id="loader" style={{ height: 5, position: "fixed", top: 64, width: "98%" }} color="secondary" />
            </Fade>
            <Paper >
                <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} lg={2}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth margin='dense'>
                            <InputLabel htmlFor="outlined-warehouse-simple">Warehouse</InputLabel>
                            <Select
                                value={selectedWarehouse}
                                onChange={handleWarehouseSelection}
                                label="Warehouse"
                            >
                                {warehouseNames}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <FormControl disabled={!selectedWarehouse} variant="outlined" className={classes.formControl} fullWidth margin='dense'>
                            <InputLabel htmlFor="outlined-warehouse-simple">Select FC</InputLabel>
                            <Select
                                value={selectedFC}
                                onChange={(e) => setSelectedFC(e.target.value)}
                                label="Select FC"
                            >
                                {fcNames}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={1}>
                        <Button variant="contained" disabled={!selectedWarehouse || !selectedFC} color="primary" onClick={handleFCSelection}>Add</Button>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider variant="middle" className={classes.divider} />
                    </Grid>
                    <Grid item container xs={12} lg={12} spacing={1} style={{ marginLeft: 8, marginRight: 8, marginBottom: 16 }}>
                        {!isEmpty(planRows) ? (
                            planRows.map(row => (
                                <FulfillmentCenterRow
                                    key={row.row_id}
                                    fcs={fcs}
                                    row={row}
                                    addCarton={addCarton}
                                    removeCarton={removeCarton}
                                    copyFC={copyFC}
                                    removeFC={removeFC}
                                    onChange={onChange}
                                    updateItems={updateItems}
                                />
                            ))
                        ) : null}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item container xs={12} lg={12} justifyContent="flex-end" style={{ marginRight: 8, marginBottom: 8 }}>
                        <Grid item lg={8} />
                        <Grid item lg={1}>
                            <Button variant="contained" color="secondary" onClick={reset}>Reset</Button>
                        </Grid>
                        <Grid item lg={2}>
                            <Button disabled={loading || planRows.length === 0 || selectedWarehouse === ''} fullWidth variant="contained" color="primary" onClick={createPlan}>Create Plan</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <AddCartonItems
                items={showAddCartonItems.items}
                masterItems={items}
                onClose={() => setShowAddCartonItems({ row_id: null, carton_id: null, items: [], open: false })}
                open={showAddCartonItems.open}
                onSave={(items) => {
                    onChange(showAddCartonItems.row_id, showAddCartonItems.carton_id, 'items', items)
                    setShowAddCartonItems({ row_id: null, carton_id: null, items: [], open: false })
                }}
                loading={false}
            />
        </div>
    );
}

const FulfillmentCenterRow = ({ row, addCarton, removeFC, removeCarton, onChange, copyFC, fcs = [], updateItems }) => {

    const [open, setOpen] = useState(false)
    const [selectedFC, setSelectedFC] = useState(row.fc.id)

    const confirm = () => {
        copyFC(row.row_id, selectedFC)
        setSelectedFC(row.fc.id)
        setOpen(false)
    }

    return (
        <Grid key={row.row_id} item xs={12} lg={12}>
            <Card style={{ width: '100%' }} raised={true} elevation={10}>
                <CardHeader
                    action={
                        <>
                            <Button style={{ marginTop: 5 }} variant='outlined' aria-label="add carton" onClick={() => addCarton(row.row_id)} startIcon={<Add />}>
                                Add Carton
                            </Button>
                            <Tooltip title="Copy FC row">
                                <IconButton aria-label="copy" onClick={() => setOpen(true)}>
                                    <Icon>file_copy</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete FC row">
                                <IconButton aria-label="copy" onClick={() => removeFC(row.row_id)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    title={row.fc.name}
                    subheader={`Cartons: ${row.cartons.length || 0}`}
                    titleTypographyProps={{ align: 'left' }}
                    subheaderTypographyProps={{ align: 'left' }}
                />
                <CardContent style={{ padding: 0 }}>
                    <div style={{ width: '100%', paddingTop: 8, paddingLeft: 8, paddingRight: 8, paddingBottom: 16, minHeight: 100, backgroundColor: grey[100] }}>
                        {!isEmpty(row.cartons) ? (
                            row.cartons.map((carton, index) => (
                                <CartonRow
                                    key={carton.carton_id}
                                    index={index + 1}
                                    row_id={row.row_id}
                                    carton={carton}
                                    removeCarton={removeCarton}
                                    addCarton={addCarton}
                                    onChange={onChange}
                                    updateItems={updateItems}
                                />
                            ))
                        ) : null}
                    </div>
                </CardContent>
            </Card>
            <MyDialog
                maxWidth={"xs"}
                content={
                    <div style={{ width: 200 }}>
                        <FormControl variant="outlined" fullWidth margin='dense'>
                            <InputLabel htmlFor="outlined-warehouse-simple">Select FC</InputLabel>
                            <Select
                                value={selectedFC}
                                onChange={(e) => setSelectedFC(e.target.value)}
                                label="Select FC"
                            >
                                {fcs.map(fc => <MenuItem key={fc.id} value={fc.id}>{fc.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                }
                title={'Select FC'}
                open={open}
                options={<Button variant='contained' color="primary" disabled={false} onClick={confirm}>Copy</Button>}
                loading={false}
                close={() => {
                    setSelectedFC(row.fc.id)
                    setOpen(false)
                }} />
        </Grid>
    )
}

const CartonRow = ({ row_id, index, carton, removeCarton, addCarton, onChange, updateItems }) => {

    const timesRef = useRef()

    const copy = () => {
        const cartons = Array(Number(timesRef.current.value || 1)).fill(carton)
        addCarton(row_id, cartons)
        timesRef.current.value = 1
    }

    const getItemsString = (items) => {
        return items.map(item => <div key={item.item.id} style={{ textAlign: 'left' }}>{item.item.name} ({item.units} units)</div>)
    }

    return (
        <Paper style={{ paddingRight: 16, paddingLeft: 16, marginTop: 8, width: '100%', backgroundColor: 'white' }}>
            <Grid container spacing={1} alignItems="flex-start" justifyContent='space-between'>
                <Grid item>
                    <Avatar style={{ marginTop: 6 }}>C{index}</Avatar>
                </Grid>
                <Grid item>
                    <TextField
                        style={{ minWidth: 75, marginLeft: 8 }}
                        label="Weight (kg)"
                        size='small'
                        value={carton.weight}
                        onChange={(e) => onChange(row_id, carton.carton_id, e.target.name, e.target.value)}
                        type="number"
                        name='weight'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="dense"
                        variant="outlined"
                        required />
                    <TextField
                        label="Dimension"
                        style={{ minWidth: 150, marginLeft: 8 }}
                        size='small'
                        type="text"
                        name='dimension'
                        value={carton.dimension}
                        onChange={(e) => onChange(row_id, carton.carton_id, e.target.name, e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="dense"
                        variant="outlined"
                        required
                        helperText="Format: LxBxH (cm)" />
                </Grid>
                <Grid container item xs={4} xl={6} alignItems="flex-start">
                    {isEmpty(carton.items) ? (
                        <Grid item style={{ marginTop: 16 }}>
                            Items: {carton.items.length || 0}
                        </Grid>
                    ) : (
                        <Grid item>
                            <Typography variant='subtitle2' align='left'>Items:</Typography>
                            {getItemsString(carton.items)}
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <Button
                        variant='outlined'
                        size='large'
                        style={{ marginTop: 6 }}
                        onClick={() => updateItems(row_id, carton.carton_id)}
                        startIcon={<Edit />}
                    >
                        Items
                    </Button>
                    <Tooltip title="Duplicate Row">
                        <TextField
                            label="No of copies"
                            style={{ maxWidth: 120, marginLeft: 8 }}
                            defaultValue={1}
                            size='small'
                            type="number"
                            name='times'
                            inputRef={timesRef}
                            InputProps={{
                                endAdornment: (
                                    <IconButton size='small' aria-label="copy" onClick={copy}>
                                        <Icon>file_copy</Icon>
                                    </IconButton>
                                )
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            required />
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton aria-label="Remove" onClick={() => removeCarton(row_id, carton.carton_id)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    )
}