import React, { Component } from "react";
import "../css/item.css";
import { Glyphicon, OverlayTrigger, Form } from 'react-bootstrap';
import { tooltip } from '../Utils';
import { ItemService } from "../Services/ItemService";
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        // top: '85%', 
        // left: '90%'
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            display: 'block'
        },
    },
});

class Item extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            originalData: [],
            rows: [],
            show: false,
            new_item: { name: "", asin: "", fnsku: "", sku: "", hsn_code: "", igst: 0, cgst: 0, sgst: 0, rate: 0 },
            sheet: "Items",
            filename: "Item List",
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.edit = this.edit.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    confirmSave = () => {
        this.handleClose();
        this.props.closeConfirmDialog();
        this.createItemAPI(this.state.new_item);
    }

    createModifyItem = (e) => {
        e.preventDefault();
        if (this.state.new_item.name && this.state.new_item.asin && this.state.new_item.fnsku && this.state.new_item.sku && this.state.new_item.hsn_code && this.state.new_item.rate >= 0 && (this.state.new_item.igst > 0 || (this.state.new_item.cgst > 0 && this.state.new_item.sgst > 0))) {
            this.props.showConfirmDialog("Confirm?", undefined, this.confirmSave);
        } else {
            this.props.showSnackBar('Please fill all the details and continue', 'error');
        }
    }

    createItemAPI(new_item) {
        let body = new_item;
        this.props.isLoading(true);
        let headers = { user_id: this.props.user.id };
        ItemService.create(headers, body)
            .then((response) => {
                if (response.status === 409) {
                    this.props.showSnackBar(`${new_item.name} already exists.`, 'error');
                } else {
                    this.setState({
                        new_item: { name: "", asin: "", fnsku: "", sku: "", hsn_code: "", igst: 0, cgst: 0, sgst: 0, rate: 0 },
                    });
                    this.fetchData();
                    if (this.state.show) this.handleClose();
                    this.props.isLoading(false);
                    this.props.showSnackBar(`${new_item.id ? 'Saved' : 'Created'} Item: ${new_item.name}`, 'success');
                }
            })
            .catch(() => {
                this.props.isLoading(false);
                this.props.showConfirmDialog(`Failed to create item : ${new_item.name}. Please try again.`, undefined, undefined);
            });

    }

    edit(e, item) {
        e.preventDefault();
        if (item) {
            this.setState({ new_item: { id: item.id, name: item.name, asin: item.asin, fnsku: item.fnsku, sku: item.sku, hsn_code: item.hsn_code, igst: parseFloat(item.igst), cgst: parseFloat(item.cgst), sgst: parseFloat(item.sgst), rate: parseFloat(item.rate) } }, function () {
                this.handleShow();
            });
        } else {
            this.setState({ show: true });
        }
    }

    componentDidMount() {
        this.props.setTitle("Items");
        this.fetchData();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_item: { name: "", asin: "", fnsku: "", sku: "", hsn_code: "", igst: 0, cgst: 0, sgst: 0, rate: 0 },
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange = (e) => {
        let change = this.state.new_item;
        change[e.target.name] = e.target.value;
        if (e.target.name === 'igst') {
            change.cgst = parseFloat(e.target.value) / 2;
            change.sgst = parseFloat(e.target.value) / 2;
            change[e.target.name] = parseFloat(e.target.value);
        } else if (e.target.name === 'rate') {
            change[e.target.name] = parseFloat(e.target.value);
        }
        e.preventDefault();
        this.setState(change);
    }


    fetchData() {
        this.props.isLoading(true);
        ItemService.getItemList()
            .then((data) => {
                this.setState({ data: data.items, originalData: data.items });
                this.filterCriteria();
                this.props.setTitle(`Items (${data.items.length})`);
                this.props.isLoading(false);
            })
            .catch(error => {
                console.error(error);
                this.props.isLoading(false);
            });
    }

    updateRows(data) {
        let rows = [];
        data.forEach((item) => {
            rows.push(<ItemDetails key={item.id} item={item} disable={this.disable} remove={this.remove} edit={this.edit} />);
        });
        this.setState({ rows: rows });
    }

    filterCriteria(e) {
        this.props.isLoading(true);
        if (e)
            e.preventDefault();
        const searchText = this.refs.inputText.value;
        let filteredList = this.state.originalData.filter(data => (data.name && data.name.toLowerCase().includes(searchText.toLowerCase())) || (data.asin && data.asin.toLowerCase().includes(searchText.toLowerCase())) || (data.fnsku && data.fnsku.toLowerCase().includes(searchText.toLowerCase())) || (data.sku && data.sku.toLowerCase().includes(searchText.toLowerCase())));
        if (this.refs.inputText.value.length === 0) {
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Item'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    remove = (e, id) => {
        e.preventDefault();
        if (id) {
            this.props.showConfirmDialog("This will not effect any of the existing Shipments.", "Confirm removal of Item?", () => {
                let headers = { user_id: this.props.user.id };
                ItemService.remove(headers, id)
                    .then(() => {
                        this.fetchData();
                        this.props.closeConfirmDialog();
                        this.props.showSnackBar(`Removed Item`, 'success');
                    })
                    .catch((error) => {
                        this.props.showSnackBar(`Failed to remove Item. Please try again.`, 'error');
                    });
            });
        }
    }

    disable = (e, id, activate) => {
        e.preventDefault();
        const status = activate === 1 ? 'enabling' : 'disabling'
        if (id) {
            this.props.showConfirmDialog("This will not effect any of the existing Shipments.", `Confirm ${status} of Item?`, () => {
                let headers = { user_id: this.props.user.id };
                ItemService.disable(headers, id, activate)
                    .then(() => {
                        this.fetchData();
                        this.props.closeConfirmDialog();
                        this.props.showSnackBar(`Success`, 'success');
                    })
                    .catch((error) => {
                        this.props.showSnackBar(`Failed ${status} item. Please try again.`, 'error');
                    });
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home">
                <div style={{ marginTop: 40 }}>
                    <Paper style={{ width: "100%", paddingTop: '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: 10, marginBottom: 50 }}>
                        <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{ width: "100%", marginBottom: "10px", padding: "5px", borderColor: "red" }} autoComplete="off"></input>
                        </form>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="subtitle1">ASIN</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="subtitle1">FNSKU</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="subtitle1">Merchant SKU</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">HSN Code</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">GST</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Rate</Typography></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
                <Dialog
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.show}
                    onClose={this.handleClose}
                    disableEnforceFocus
                    fullWidth={true}
                    maxWidth='sm'>
                    <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 5 }}>{this.state.new_item.id ? 'Modify Item' : 'Create Item'}</DialogTitle>
                    <DialogContent>
                        {this.itemForm()}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" size="medium" color="primary" type="submit" onClick={this.createModifyItem} style={{ marginRight: "10px" }}>Save</Button>
                        {!this.state.show ? (<Button type="submit" variant="contained" size="medium" color="primary" onClick={this.export} style={{ marginRight: "10px" }}>Export</Button>) : ""}
                        {this.state.show ? (<Button variant="outlined" size="medium" color="primary" onClick={this.handleClose}>Close</Button>) : ""}
                    </DialogActions>
                </Dialog>
                <OverlayTrigger placement="top" overlay={tooltip("Add Item")} >
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </OverlayTrigger>
            </div>
        );
    }

    itemForm = (submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <TextField
                        id="name"
                        label="Item Name"
                        placeholder="Item Name"
                        name="name"
                        defaultValue={this.state.new_item.name}
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <TextField
                        id="asin"
                        label="ASIN"
                        defaultValue={this.state.new_item.asin}
                        placeholder="ASIN"
                        name="asin"
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="fnsku"
                        label="FNSKU"
                        defaultValue={this.state.new_item.fnsku}
                        placeholder="FNSKU"
                        name="fnsku"
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="sku"
                        label="Merchant SKU"
                        defaultValue={this.state.new_item.sku}
                        placeholder="Merchant SKU"
                        name="sku"
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="hsn_code"
                        className={this.props.classes.textField}
                        variant="outlined"
                        label="HSN Code"
                        margin="normal"
                        defaultValue={this.state.new_item.hsn_code}
                        name="hsn_code"
                        onChange={this.handleChange}
                        type="text"
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        id="rate"
                        className={this.props.classes.textField}
                        variant="outlined"
                        label="RATE"
                        margin="normal"
                        defaultValue={this.state.new_item.rate}
                        name="rate"
                        onChange={this.handleChange}
                        pattern="[0-9]*"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        id="igst"
                        className={this.props.classes.textField}
                        variant="outlined"
                        label="IGST"
                        margin="normal"
                        defaultValue={this.state.new_item.igst}
                        name="igst"
                        onChange={this.handleChange}
                        pattern="[0-9]*"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        id="cgst"
                        className={this.props.classes.textField}
                        variant="outlined"
                        label="CGST"
                        margin="normal"
                        value={this.state.new_item.cgst}
                        name="cgst"
                        onChange={this.handleChange}
                        pattern="[0-9]*"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        id="sgst"
                        className={this.props.classes.textField}
                        variant="outlined"
                        label="SGST"
                        margin="normal"
                        value={this.state.new_item.sgst}
                        name="sgst"
                        onChange={this.handleChange}
                        pattern="[0-9]*"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Form>
    )

}

class ItemDetails extends Component {

    render() {
        const item = this.props.item;
        return (
            <TableRow selected={item.status === 0} key={item.id} >
                <TableCell component="th" scope="row">
                    {item.name}
                </TableCell>
                <TableCell align="left">{item.asin || '-'}</TableCell>
                <TableCell align="left">{item.fnsku}</TableCell>
                <TableCell align="left">{item.sku || '-'}</TableCell>
                <TableCell align="center">{item.hsn_code}</TableCell>
                <TableCell align="center"><b>IGST:</b> {item.igst}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>CGST:</b> {item.cgst}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>SGST:</b> {item.sgst}</TableCell>
                <TableCell align="center">{item.rate}</TableCell>
                <TableCell align="right">
                    <OverlayTrigger placement="bottom" overlay={tooltip(item.status === 1 ? "Disable Item": "Enable Item")}>
                        <Glyphicon style={{ marginRight: "20px" }} glyph={item.status === 0 ? "ok-sign" : "minus-sign"} onClick={(e) => this.props.disable(e, item.id, item.status === 1 ? 0 : 1)} />
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltip("Remove Item")}>
                        <Glyphicon style={{ marginRight: "20px" }} glyph="trash" onClick={(e) => this.props.remove(e, item.id)} />
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltip("Edit")} >
                        <Glyphicon style={{ marginRight: "10px" }} glyph="pencil" onClick={(e) => this.props.edit(e, item)} />
                    </OverlayTrigger>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(Item);