import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { OutlinedInput, InputLabel, MenuItem, FormHelperText, FormControl, Select } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class SelectDropdown extends React.Component {
  state = {
    labelWidth: 0
  };

  componentWillReceiveProps(props) {
    const { options } = this.props;
    if (props.options !== options) {
      this.createOptions(props.options);
    }
  }

  createOptions = (options) => {
    let ops = options.map(options => (
        <MenuItem key={options.id} value={options.id}>
            {options.name}
        </MenuItem>
    ));
    this.setState({
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        options: ops
    });
  }

  componentDidMount() {
    this.createOptions(this.props.options);
  }

  render() {
    const { classes, error, required, label, onChange, value, name, hint, disabled, fullWidth = false } = this.props;

    return (
      <FormControl error={error} variant="outlined" className={classes.formControl} required={required} fullWidth={fullWidth}>
        <InputLabel
          htmlFor="outlined-age-simple"
          ref={ref => {
            this.InputLabelRef = ref;
          }}
        >
          {label}
        </InputLabel>
        <Select
          disabled={disabled}
          value={value}
          name={name}
          onChange={onChange}
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name="age"
              id="outlined-age-simple"
            />
          }
        >
          {this.state.options}
        </Select>
        <FormHelperText>{hint}</FormHelperText>
      </FormControl>
    );
  }
}

SelectDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  hint: PropTypes.string,
  error: PropTypes.bool
};

export default withStyles(styles)(SelectDropdown);
