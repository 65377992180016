import MyDialog from '../components/MyDialog'
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { copyToClipBoard, precision } from '../Utils'

const dividerString = '--------------------------------------------------------'
const Divider = () => <div>{dividerString}</div>

const ShipmentDetails = ({ open = false, onClose = () => { }, data = [], showSnackBar = () => { } }) => {

    const warehouses = useMemo(() => {
        if (!data) return []
        const warehouses = [];
        data.forEach(shipmentRow => {
            if (!warehouses.find(w => w.warehouse_id === shipmentRow.warehouse_id)) {
                warehouses.push({
                    warehouse_id: shipmentRow.warehouse_id,
                    warehouse_name: shipmentRow.warehouse_name,
                    shipments: [],
                    noOfShipments: 0,
                    noOfCartons: 0,
                    weight: 0,
                })
            }
            const row = warehouses.find(w => w.warehouse_id === shipmentRow.warehouse_id)
            const noOfCartons = shipmentRow.items.reduce((sum, next) => sum + next.no_of_cases, 0)
            const weight = shipmentRow.items.reduce((sum, next) => sum + (next.no_of_cases * next.weight), 0)
            const noOfUnits = shipmentRow.items.reduce((sum, next) => sum + (next.no_of_cases * next.case_size), 0)
            row.shipments.push({
                shipment_identifier: shipmentRow.shipment_identifier,
                fc_name: shipmentRow.fc_name,
                noOfCartons: noOfCartons,
                noOfUnits: noOfUnits,
                weight: precision(weight),
            })
            row.noOfShipments = row.noOfShipments + 1
            row.noOfCartons = row.shipments.reduce((sum, next) => sum + next.noOfCartons, 0)
            row.weight = Math.round(row.shipments.reduce((sum, next) => sum + next.weight, 0))
        })
        return warehouses
    }, [data])

    const copy = () => {
        let message = "";
        warehouses.forEach(row => {
            row.shipments.forEach(shipment => {
                message = `${message}FB ID: ${shipment.shipment_identifier}\n`
                message = `${message}FC: ${shipment.fc_name}\n`
                message = `${message}No. of Cartons: ${shipment.noOfCartons}\n`
                message = `${message}No. of Unit: ${shipment.noOfUnits}\n`
                message = `${message}Weight: ${shipment.weight} KG\n`
                message = `${message}\n\n`
            })
            message = `${message}${dividerString}\n`
            message = `${message}Total Shipment - ${row.noOfShipments}\n`
            message = `${message}Total Cartons - ${row.noOfCartons}\n`
            message = `${message}Total Weight - ${row.weight} KG\n`
            message = `${message}${dividerString}\n`
            message = `${message}Pickup Location - ${row.warehouse_name}\n`
            message = `${message}${dividerString}\n\n`
        })
        if (warehouses.length > 1) {
            message = `${message}Grand Total\n`
            message = `${message}${dividerString}\n`
            message = `${message}No. of Shipment - ${warehouses.reduce((sum, next) => sum + next.noOfShipments, 0)}\n`
            message = `${message}Total Cartons - ${warehouses.reduce((sum, next) => sum + next.noOfCartons, 0)}\n`
            message = `${message}Total Weight - ${warehouses.reduce((sum, next) => sum + next.weight, 0)} KG\n`
            message = `${message}${dividerString}\n`
        }
        copyToClipBoard(message)
            .then(() => showSnackBar('Copied', 'info'))
            .catch(err => console.log(err))
        // showSnackBar('Copied', 'info')
    }

    return (
        <MyDialog
            maxWidth={"sm"}
            content={
                <div>
                    <div style={{ marginTop: 16, minHeight: 200, width: '100%', border: '1px dotted black', borderRadius: 8, backgroundColor: 'white' }}>
                        {!warehouses || warehouses.length === 0 ? null : (
                            <Typography component="div" style={{ margin: 8 }}>
                                {warehouses.map(row => {
                                    return (
                                        <div key={row.warehouse_id}>
                                            {row.shipments.map(shipment => (
                                                <div key={shipment.shipment_identifier}>
                                                    <div>FB ID: {shipment.shipment_identifier}</div>
                                                    <div>FC: {shipment.fc_name}</div>
                                                    <div>No. of Cartons: {shipment.noOfCartons}</div>
                                                    <div>No. of Unit: {shipment.noOfUnits}</div>
                                                    <div>Weight: {shipment.weight} KG</div>
                                                    <br />
                                                </div>
                                            ))}
                                            <Divider />
                                            <div>Total Shipment - {row.noOfShipments}</div>
                                            <div>Total Cartons - {row.noOfCartons}</div>
                                            <div>Total Weight - {row.weight} KG</div>
                                            <Divider />
                                            <div style={{ marginTop: 8, marginBottom: 8 }}>Pickup Location - {row.warehouse_name}</div>
                                            <Divider />
                                            <br />
                                        </div>
                                    )
                                })}
                                {warehouses.length > 1 ? (
                                    <>
                                        <div>Grand Total</div>
                                        <Divider />
                                        <div>No. of Shipment - {warehouses.reduce((sum, next) => sum + next.noOfShipments, 0)}</div>
                                        <div>Total Cartons - {warehouses.reduce((sum, next) => sum + next.noOfCartons, 0)}</div>
                                        <div>Total Weight - {warehouses.reduce((sum, next) => sum + next.weight, 0)} KG</div>
                                        <Divider />
                                    </>
                                ) : null}
                            </Typography>
                        )}
                    </div>
                </div>
            }
            title='Shipment Details'
            open={open}
            loading={false}
            close={onClose}
            options={
                <>
                    <Button variant='contained' onClick={copy}>Copy to clipboard</Button>
                </>
            }
        />
    )
}

export default ShipmentDetails