import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { ImportService } from '../Services/ImportService';
import { Button, Paper, Grid, Typography } from '@material-ui/core';

const TYPE = {
    SMYTTEN: 0,
}

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '80%',
            height: 200
        },
        marginTop: theme.spacing(5),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        width: 200,
        margin: 0,
        marginTop: 10,
        height: 50
    },
    table: {
        minWidth: 500,
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px',
        padding: 20,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    }
});

class AmazonShippingLabelSplitter extends Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.fileFBAInput = React.createRef();
        this.fileSmyttenInput = React.createRef();

        props.setTitle("Shipping Label Splitter");
    }

    importFile = () => {
        let files = this.fileInput.current.files;
        if (files.length === 1) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let name = files[0].name;
            reader.onload = (e) => {
                this.props.isLoading(true);
                ImportService.import({ name: name, file: e.target.result })
                    .then((data) => {
                        this.props.isLoading(false);
                        if (data.success) {
                            this.props.showSnackBar("Converted the file", 'success');
                            window.open(data.download_url, '_blank');
                        }
                    })
                    .catch((error) => {
                        this.props.isLoading(false);
                        this.props.showSnackBar("File import failed. Please try again. Check Extension and file name.", 'error');
                        console.log(error);
                    });
            }
        }
    }

    import = (type) => {
        let files
        if (type === TYPE.SMYTTEN) {
            files = this.fileSmyttenInput.current.files;
        }
        if (files.length === 1) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let name = files[0].name;
            reader.onload = (e) => {
                this.props.isLoading(true);
                let p

                if (type === TYPE.SMYTTEN) {
                    p = ImportService.importSmytten({ name: name, file: e.target.result })
                }

                p
                    .then((data) => {
                        this.props.isLoading(false);
                        if (data.success) {
                            this.props.showSnackBar("Converted the file", 'success');
                            window.open(data.download_url, '_blank');
                        }
                    })
                    .catch((error) => {
                        this.props.isLoading(false);
                        this.props.showSnackBar("File import failed. Please try again. Check Extension and file name.", 'error');
                        console.log(error);
                    });
            }
        }
    }

    importFBAFile = () => {
        let files = this.fileFBAInput.current.files;
        if (files.length === 1) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let name = files[0].name;
            reader.onload = (e) => {
                this.props.isLoading(true);
                ImportService.importFBA({ name: name, file: e.target.result })
                    .then((data) => {
                        this.props.isLoading(false);
                        if (data.success) {
                            this.props.showSnackBar("Converted the file", 'success');
                            window.open(data.download_url, '_blank');
                        }
                    })
                    .catch((error) => {
                        this.props.isLoading(false);
                        this.props.showSnackBar("File import failed. Please try again. Check Extension and file name.", 'error');
                        console.log(error);
                    });
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home" style={{ marginLeft: 40 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.root}>
                            <Grid container spacing={1} className={classes.grid}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h2" align="justify">
                                        Amazon Shipping label
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <input type="file" name="label_file" accept=".pdf" multiple={false} ref={this.fileInput} />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <Button variant="contained" color="primary" onClick={this.importFile} fullWidth>
                                        Convert file
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.root}>
                            <Grid container spacing={1} className={classes.grid}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h2" align="justify">
                                        Self Ship FBA Cropper
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <input type="file" name="label_file" accept=".pdf" multiple={false} ref={this.fileFBAInput} />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <Button variant="contained" color="primary" onClick={this.importFBAFile} fullWidth>
                                        Convert file
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.root}>
                            <Grid container spacing={1} className={classes.grid}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h2" align="justify">
                                        Smytten Label Cutter
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <input type="file" name="label_file" accept=".pdf" multiple={false} ref={this.fileSmyttenInput} />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <Button variant="contained" color="primary" onClick={() => this.import(TYPE.SMYTTEN)} fullWidth>
                                        Convert file
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(AmazonShippingLabelSplitter);