import React from 'react';
import {
    Grid,
    Divider,
    Fade,
    LinearProgress,
    Button,
    IconButton,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    Paper,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Icon,
    ListItemText,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { WarehouseService } from '../Services/WarehouseService';
import { ItemService } from '../Services/ItemService';
import { Autocomplete } from '@material-ui/lab';
import { FCService } from '../Services/FCService';
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
import XLSX from 'xlsx';
import moment from 'moment';
import { format, format_export_dash, format_datetime } from '../Utils';
import { ShipmentService } from '../Services/ShipmentService';
import PapaParse from 'papaparse';
import JSZip from 'jszip';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
    },
    subheading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left"
    },
    subheadingLink: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "left",
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer"
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
        float: 'left'
    },
    formControlItem: {
        width: 120,
        float: 'left',
        marginTop: 10,
        marginBottom: 2
    },
    formControlLeft: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    itemListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        height: '65vh',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            minHeight: 0,
            height: '100%'
        },
        overflow: 'auto',
        paddingLeft: 5,
        paddingRight: 5
    },
    divider: {
        marginTop: 10,
        marginBottom: 10
    }
}));

let count = 0;

export default function ShipmentPlanner(props) {
    const [loading, setLoading] = React.useState(false);

    const [selectedWarehouse, setSelectedWarehouse] = React.useState('');
    const [warehouses, setWarehouses] = React.useState([]);
    const [warehouseNames, setWarehouseNames] = React.useState([]);
    const [fcs, setFCs] = React.useState([]);
    const [fcNames, setFCNames] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [planRows, setPlanRows] = React.useState([]);
    const [displayRows, setDisplayRows] = React.useState([]);

    const classes = useStyles();

    React.useEffect(() => {
        props.setTitle('Shipment Planner');
        WarehouseService.getWarehouses()
            .then((data) => {
                let warehouse_names = data.warehouses.map((w) => (
                    <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
                ));
                setWarehouses(data.warehouses);
                setWarehouseNames(warehouse_names);
            })
            .catch((error) => {
                console.log("Error while fetching Warehouse list");
            });

        ItemService.getItemList()
            .then((data) => {
                setItems(data.items);
            })
            .catch(error => {
                console.log(error);
            });

        FCService.getFCs()
            .then((data) => {
                let fc_names = data.fcs.map((fc) => (
                    <MenuItem key={fc.id} value={fc.id}>{fc.name}</MenuItem>
                ));
                setFCs(data.fcs)
                setFCNames(fc_names);
            })
            .catch(error => {
                console.log(error);
            });

        return () => {
            setSelectedWarehouse(undefined);
        }
    }, []);

    const handleWarehouseSelection = (e) => {
        setSelectedWarehouse(e.target.value);
    }

    const handleItemSelection = (e) => {
        if (selectedItem) {
            let row = {
                ...selectedItem,
                row_id: count++,
                fc_id: '',
                packing_size: '',
                no_of_cases: '',
                weight: '',
                dimension: '',
                fc_name: ''
            }
            setPlanRows([...planRows, row]);
            setSelectedItem(null);
        }
    }

    const handleChange = (e, field) => {
        let rows = [...planRows];
        let row = rows.filter(row => row.row_id.toString() === e.target.name)[0];
        row[field] = field === 'dimension' || field === 'fc_id' ? e.target.value : parseFloat(e.target.value);
        if (field === 'fc_id') {
            row.fc_name = fcs.filter(fc => fc.id === e.target.value)[0].name;
        }
        setPlanRows(rows);
    }

    const filterRows = (e) => {
        let text = "";
        if (e) {
            e.preventDefault();
            text = e.target.value || "";
        } else {
            text = searchText;
        }
        setSearchText(text);
    }

    const reset = () => {
        props.showConfirmDialog("Confirm Reset Plan?", undefined, () => {
            resetAction();
            props.closeConfirmDialog();
        });
    }

    const resetAction = () => {
        setPlanRows([]);
        setSelectedWarehouse('');
        setSearchText('');
        setSelectedItem(null);
    };

    const allFieldsPopulated = () => {
        return planRows.filter(row => row.fc_id === '' || row.dimension === '' || row.weight <= 0 || row.no_of_cases === 0 || row.packing_size === 0).length === 0;
    }

    const createPlan = () => {
        if (planRows.length > 0 && allFieldsPopulated() && selectedWarehouse) {
            props.showConfirmDialog("Create Plan?", undefined, () => {
                setLoading(true);

                if (planRows.length > 0 && selectedWarehouse) {
                    let warehouse = warehouses.filter(w => w.id === selectedWarehouse)[0];

                    // Step 1 Export Plan
                    let plan = [['Warehouse', warehouse.name], ['Item', 'FC Name', 'Packing Size', 'No of Cartons', 'Weight', 'Dimensions']];
                    plan.push.apply(plan, planRows.map(row =>
                        [row.name, row.fc_name, row.packing_size, row.no_of_cases, row.weight, row.dimension]));
                    const wb = XLSX.utils.book_new();
                    const wsAll = XLSX.utils.aoa_to_sheet(plan);
                    XLSX.utils.book_append_sheet(wb, wsAll, 'Planning');
                    const fileName = `shipment_planning_internal_${moment().format(format_export_dash)}`;
                    XLSX.writeFile(wb, `${fileName}.xlsx`);

                    // Step 2 Export Amazon Shipment plan
                    let fc_ids = new Set(planRows.map(p => p.fc_id));

                    let headers = { user_id: props.user.id };
                    var zip = new JSZip();
                    // fc_ids.forEach(fc_id => {
                    //     let fc = fcs.filter(f => f.id === fc_id)[0];
                    //     let rows = planRows.filter(p => p.fc_id === fc_id);

                    //     // Insert into Shipment, on success Create and Download the CSV
                    //     let new_shipment = {
                    //         warehouse_id: warehouse.id,
                    //         fc_id: fc_id,
                    //         shipment_date: moment().utc().format(format),
                    //         invoice_no: '',
                    //         interstate: '1',
                    //         is_new: true,
                    //         items: []
                    //     };
                    //     new_shipment.items.push.apply(new_shipment.items, rows.map(row => ({
                    //         item_id: row.id,
                    //         case_size: row.packing_size,
                    //         no_of_cases: row.no_of_cases,
                    //         weight: row.weight,
                    //         dimension: row.dimension,
                    //         igst: row.igst,
                    //         cgst: row.cgst,
                    //         sgst: row.sgst,
                    //         status: "1",
                    //         rate: row.rate
                    //     })));

                    //     ShipmentService.createShipment(headers, new_shipment)
                    //     .then((response) => {
                    //         let plan = [
                    //             ['PlanName', `Plan-${fc.name}-${moment().format(format_datetime)}`],
                    //             ['ShipToCountry', 'IN'],
                    //             ['ShipToSubdivision', fc.name],
                    //             ['AddressName', warehouse.name],
                    //             ['AddressFieldOne', warehouse.address1],
                    //             ['AddressFieldTwo', warehouse.address2 || ''],
                    //             ['AddressCity', warehouse.city],
                    //             ['AddressCountryCode', 'IN'],
                    //             ['AddressStateOrRegion', warehouse.state],
                    //             ['AddressPostalCode', warehouse.pin],
                    //             ['AddressDistrict', warehouse.district || ''],
                    //             [],
                    //             ['MerchantSKU',	'UnitsPerCase',	'NumberOfCases', 'Quantity']
                    //         ];

                    //         rows.forEach(row => {
                    //             plan.push([row.sku, row.packing_size, row.no_of_cases, row.packing_size * row.no_of_cases])
                    //         });
                    //         let csv = PapaParse.unparse(plan, {
                    //             quotes: false,
                    //             delimiter: "\t"
                    //         });
                    //         var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                    //         zip.file(`shipment_planning_amazon_${fc.name}_${moment().format(format_export_dash)}.csv`, csvData)
                    //         // var csvURL = window.URL.createObjectURL(csvData);
                    //         // var tempLink = document.createElement('a');
                    //         // tempLink.href = csvURL;
                    //         // tempLink.setAttribute('download', `shipment_planning_amazon_${fc.name}_${moment().format(format_export_dash)}.csv`);
                    //         // tempLink.click();

                    //         // const wb = XLSX.utils.book_new();
                    //         // const wsAll = XLSX.utils.aoa_to_sheet(plan);
                    //         // XLSX.utils.book_append_sheet(wb, wsAll, 'Sheet1');
                    //         // XLSX.writeFile(wb, `shipment_planning_amazon_${fc.name}_${moment().format(format_export_dash)}.csv`);
                    //         // // props.showSnackBar(`Created Shipment and exported CSV for ${fc.name}`, 'success');
                    //     })
                    //     .catch(error => {
                    //         props.showSnackBar(`Something went wrong while creating shipment for ${fc.name}. Please try again later.` , 'error');
                    //     });
                    // });
                    Promise.all([...fc_ids].map(fc_id => {
                        let fc = fcs.filter(f => f.id === fc_id)[0];
                        let rows = planRows.filter(p => p.fc_id === fc_id);

                        // Insert into Shipment, on success Create and Download the CSV
                        let new_shipment = {
                            warehouse_id: warehouse.id,
                            fc_id: fc_id,
                            shipment_date: moment().utc().format(format),
                            invoice_no: '',
                            interstate: '1',
                            is_new: true,
                            items: []
                        };
                        new_shipment.items.push.apply(new_shipment.items, rows.map(row => ({
                            item_id: row.id,
                            case_size: row.packing_size,
                            no_of_cases: row.no_of_cases,
                            weight: row.weight,
                            dimension: row.dimension,
                            igst: row.igst,
                            cgst: row.cgst,
                            sgst: row.sgst,
                            status: "1",
                            rate: row.rate
                        })));

                        let plan = [
                            ['PlanName', `Plan-${fc.name}-${moment().format(format_datetime)}`],
                            ['ShipToCountry', 'IN'],
                            ['ShipToSubdivision', fc.name],
                            ['AddressName', warehouse.name],
                            ['AddressFieldOne', warehouse.address1],
                            ['AddressFieldTwo', warehouse.address2 || ''],
                            ['AddressCity', warehouse.city],
                            ['AddressCountryCode', 'IN'],
                            ['AddressStateOrRegion', warehouse.state],
                            ['AddressPostalCode', warehouse.pin],
                            ['AddressDistrict', warehouse.district || ''],
                            [],
                            ['MerchantSKU', 'UnitsPerCase', 'NumberOfCases', 'Quantity']
                        ];

                        rows.forEach(row => {
                            plan.push([row.sku, row.packing_size, row.no_of_cases, row.packing_size * row.no_of_cases])
                        });
                        let csv = PapaParse.unparse(plan, {
                            quotes: false,
                            delimiter: "\t"
                        });
                        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                        zip.file(`shipment_planning_amazon_${fc.name}_${moment().format(format_export_dash)}.csv`, csvData)

                        return ShipmentService.createShipment(headers, new_shipment)
                    }))
                        .then(() => {
                            return zip.generateAsync({ type: "blob" })
                        }).then((data) => {
                            var csvURL = window.URL.createObjectURL(data);
                            var tempLink = document.createElement('a');
                            tempLink.href = csvURL;
                            tempLink.setAttribute('download', `${fileName}.zip`);
                            tempLink.click();
                        })
                        .catch(error => console.error(error))

                }
                props.closeConfirmDialog();
                setLoading(false);
            });
        } else {
            let message = `Please select ${selectedWarehouse.length === 0 ? 'warehouse' : ''} ${selectedWarehouse.length === 0 && planRows.length === 0 ? 'and' : ''} ${planRows.length === 0 ? 'atleast one product' : ''}`;
            props.showSnackBar(message, 'error');
        }
    }

    const removeRow = (row_id) => {
        props.showConfirmDialog("Confirm Remove?", undefined, () => {
            setPlanRows(planRows.filter(row => row.row_id !== row_id));
            props.closeConfirmDialog();
        });
    }

    const copyRow = (row) => {

        let clonedRow = Object.assign({}, row);

        clonedRow.row_id = count++
        clonedRow.fc_id = '';
        // clonedRow.packing_size = '';
        clonedRow.no_of_cases = '';
        // clonedRow.weight = '';
        // clonedRow.dimension = '';
        clonedRow.fc_name = '';

        setPlanRows([...planRows, clonedRow]);
    }

    React.useEffect(() => {
        setDisplayRows([])
        let filteredRows = planRows;
        if (searchText.length > 0) {
            filteredRows = planRows.filter(row => row.fc_name.toLowerCase().includes(searchText.toLowerCase()) || row.name.toLowerCase().includes(searchText.toLowerCase()));
        }

        let r = filteredRows.map((rows, index) => (
            <TableRow key={index}>
                <TableCell>
                    {index + 1}
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                    <ListItemText
                        primary={<Typography type="subtitle1">{rows.name}</Typography>}
                        secondary={<Typography type="title" color={rows.sku ? 'inherit' : 'secondary'}><b>Merchant SKU:</b> {rows.sku || '-'}</Typography>} />
                </TableCell>
                <TableCell style={{ paddingTop: 0, verticalAlign: 'top' }}>
                    <FormControl variant="outlined" className={classes.formControlItem}>
                        <InputLabel
                            htmlFor="outlined-warehouse-simple"
                        >
                            Select FC
                        </InputLabel>
                        <Select
                            value={rows.fc_id}
                            onChange={(e) => handleChange(e, 'fc_id')}
                            name={rows.row_id.toString()}
                            input={
                                <OutlinedInput
                                    labelWidth={80}
                                    name="FC"
                                    id="outlined-warehouse-simple"
                                />
                            }
                        >
                            {fcNames}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell style={{ paddingTop: 0, verticalAlign: 'top' }}>
                    <TextField
                        label="Packing Size"
                        value={rows.packing_size}
                        onChange={(e) => handleChange(e, 'packing_size')}
                        type="number"
                        name={rows.row_id.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: 0
                        }}
                        margin="normal"
                        variant="outlined"
                        required
                        helperText=""
                        className={classes.formControlItem}
                    />
                </TableCell>
                <TableCell style={{ paddingTop: 0, verticalAlign: 'top' }}>
                    <TextField
                        label="No of Cases"
                        value={rows.no_of_cases.toString()}
                        onChange={(e) => handleChange(e, 'no_of_cases')}
                        type="number"
                        name={rows.row_id.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: 0
                        }}
                        margin="normal"
                        variant="outlined"
                        required
                        helperText=""
                        className={classes.formControlItem}
                    />
                </TableCell>
                <TableCell style={{ paddingTop: 0, verticalAlign: 'top' }}>
                    <TextField
                        label="Weight"
                        value={rows.weight}
                        onChange={(e) => handleChange(e, 'weight')}
                        type="number"
                        name={rows.row_id.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: 0
                        }}
                        margin="normal"
                        variant="outlined"
                        required
                        helperText="Weight of case"
                        className={classes.formControlItem}
                    />
                </TableCell>
                <TableCell style={{ paddingTop: 0, verticalAlign: 'top' }}>
                    <TextField
                        label="Dimensions"
                        value={rows.dimension}
                        onChange={(e) => handleChange(e, 'dimension')}
                        type="text"
                        name={rows.row_id.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            maxLength: 20
                        }}
                        margin="normal"
                        variant="outlined"
                        required
                        helperText="L x W x H"
                        className={classes.formControlItem}
                    />
                </TableCell>
                <TableCell style={{ paddingTop: 0, verticalAlign: 'middle' }}>
                    <Tooltip title="Remove">
                        <IconButton aria-label="Remove" onClick={(e) => removeRow(rows.row_id)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Duplicate Row">
                        <IconButton aria-label="copy" onClick={(e) => copyRow(rows)}>
                            <Icon>file_copy</Icon>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ));

        setDisplayRows(r);

    }, [planRows, searchText]);

    return (
        <div className="Home" style={{ marginTop: 15 }}>
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '50ms' : '0ms',
                }}
                unmountOnExit
            >
                <LinearProgress id="loader" style={{ height: 5, position: "fixed", top: 64, width: "98%" }} color="secondary" />
            </Fade>
            <Paper style={{ height: '86vh' }}>
                <Grid container spacing={0} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} lg={2}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth>
                            <InputLabel
                                htmlFor="outlined-warehouse-simple"
                            >
                                Warehouse
                            </InputLabel>
                            <Select
                                style={{ marginRight: 16 }}
                                value={selectedWarehouse}
                                onChange={handleWarehouseSelection}
                                input={
                                    <OutlinedInput
                                        labelWidth={80}
                                        name="Warehouse"
                                        id="outlined-warehouse-simple"
                                    />
                                }
                            >
                                {warehouseNames}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {/* getOptionDisabled={option => planRows.map(row => row.id).includes(option.id)} */}
                        <FormControl variant="outlined" className={classes.formControl} fullWidth>
                            <Autocomplete
                                id="item-selection"
                                disableClearable
                                disabled={!selectedWarehouse}
                                options={items}
                                getOptionLabel={option => `${option.name} ${option.fnsku ? `[${option.fnsku}]` : ''} ${option.sku ? `[${option.sku}]` : ''}`}
                                value={selectedItem}
                                onChange={(event, item) => {
                                    setSelectedItem(item);
                                }}
                                style={{ marginRight: 16 }}
                                renderInput={params => (
                                    <TextField {...params} label="Add Item" variant="outlined" fullWidth />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={1}>
                        <Button variant="contained" disabled={!selectedWarehouse} color="primary" onClick={handleItemSelection}>Add</Button>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <EnhancedTableToolbar searchText={searchText} onSearchTextChange={filterRows} />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider variant="middle" className={classes.divider} />
                    </Grid>
                    {/* <Hidden mdDown>
                        <Grid item lg={6}/>
                    </Hidden> */}

                    <Grid item xs={12} lg={12} className={classes.itemListContainer}>
                        <div className={classes.table}>
                            <Table stickyHeader padding="none">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sl.No</TableCell>
                                        <TableCell align="center">Item</TableCell>
                                        <TableCell align="center">FC Name</TableCell>
                                        <TableCell align="center">Packing Size</TableCell>
                                        <TableCell align="center">No Of Cartons</TableCell>
                                        <TableCell align="center">Weight</TableCell>
                                        <TableCell align="center">Dimensions</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayRows}
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item container xs={12} lg={12} justifyContent="flex-end">
                        <Grid item lg={8} />
                        <Grid item lg={1}>
                            <Button variant="contained" color="secondary" onClick={reset}>Reset</Button>
                        </Grid>
                        <Grid item lg={2}>
                            <Button disabled={loading || planRows.length === 0 || selectedWarehouse === ''} fullWidth variant="contained" color="primary" onClick={createPlan}>Create Plan</Button>
                        </Grid>
                        <Grid item lg={1} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}