import MomentUtils from '@date-io/moment';
import { AppBar, Avatar, Button, Dialog as DialogMUI, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, FormControlLabel, Grid, Hidden, Icon, IconButton, ImageListItemBar, InputLabel, List, MenuItem, OutlinedInput, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { deepOrange, deepPurple, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import classnames from 'classnames';
import moment from 'moment';
import React, { Component } from "react";
import AutoCompleteSelection from '../components/AutoCompleteSelection';
import EnhancedTableHead from '../components/EnhancedTableHead';
import SelectDropdown from '../components/SelectDropdown';
import { RowCountOptions } from "../Config";
import "../css/shipment.css";
import { ROLES } from '../Roles';
import { FCService } from "../Services/FCService";
import { ItemService } from "../Services/ItemService";
import { OrganisationService } from "../Services/OrganisationService";
import { ShipmentService } from "../Services/ShipmentService";
import { WarehouseService } from "../Services/WarehouseService";
import { convertShipmentStatusToString, format, format_export_new, format_invoice, format_without_time, getDimensions, getDimensionString, getTimeInIST, toInt } from '../Utils';
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
// import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ShipmentDetailExport from './ShipmentDetails'
import { printInvoice, printLabel, showPackingList } from './utils';
import ItemDetails from './create/item-details';
import ShipmentDetails from './shipment-details';
import ItemDisplayDetails from './display/item-details';
import AddCartons from './add-cartons';
import CartonDetails from './display/carton-details';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

const styles = theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },
    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabText: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    tablecell: {
        fontSize: '40pt',
        padding: '5px'
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        width: "80%",
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    table: {
        minWidth: 700
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    formControlLeft: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    heading: {
        fontWeight: 500,
        color: 'black'
    }
});

const statuses = [{ id: '0', name: 'OnHold' }, { id: '1', name: 'InProgress' }, { id: '2', name: 'Completed' }, { id: '3', name: 'Cancelled' }]
const interstate = [{ id: '1', name: 'Interstate' }, { id: '0', name: 'Local' }]

class Shipment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company_data: {
                pan: "",
                name: "",
                bill_to: "",
            },
            data: [],
            originalData: [],
            current_rows: [],
            numItemsPerPage: 10,
            currentPageNumber: 0,
            totalItems: 0,
            totalPages: 0,
            filter: '',
            new_shipment: { new: true, id: undefined, shipment_identifier: '', warehouse_id: "", fc_id: "", shipment_date: moment(), invoice_no: "", interstate: "1", vehicle_no: "", items: [], cartons: [] },
            show: false,
            showAddItem: false,
            showDetails: false,
            columns: [
                { id: 'index', numeric: false, disablePadding: true, label: '', sortable: false },
                { id: 'shipment_identifier', numeric: false, disablePadding: true, label: 'Shipment ID', sortable: true },
                { id: 'invoice_no', numeric: false, disablePadding: true, label: 'Invoice No', sortable: true },
                { id: 'warehouse_name', numeric: false, disablePadding: true, label: 'Warehouse', sortable: true },
                { id: 'fc_name', numeric: false, disablePadding: true, label: 'FC', sortable: true },
                { id: 'interstate', numeric: false, disablePadding: true, label: 'Interstate', sortable: true },
                { id: 'shipment_date', numeric: false, disablePadding: true, label: 'Shipment Date', sortable: true },
                { id: 'appointment', numeric: false, disablePadding: true, label: 'Appointment', sortable: false },
                { id: 'signed', numeric: false, disablePadding: true, label: 'Signed Doc', sortable: false },
                { id: 'status', numeric: false, disablePadding: true, label: 'Status', sortable: true },
                { id: 'actions', numeric: false, disablePadding: true, label: '', sortable: false },
            ],
            order: 'desc',
            orderBy: 'shipment_date',
            master_items: [],
            master_fc: [],
            master_warehouse: [],
            master_status: [],
            master_interstate: [],
            startDate: moment().subtract(1, "month"),
            endDate: moment().add(7, "days"),
            fc_names: [],
            project_names_master: [],
            selected_warehouse: "",
            selected_item_id: "",
            selected_status: "-1",
            selected_interstate: "",
            selected_fc: "",
            items_row: [],
            txn_type_row: [],
            showChangeStatus: false,
            selected_shipment: { status: "0" },
            error_fields: { shipment_identifier: false, warehouse_id: false, fc_id: false, shipment_date: false, invoice_no: false, vehicle_no: false },
            item_rows: [],
            reload: false,
            selected: [],
            showShipmentDetails: false,
            showAddCartons: false,
            showCartonDetails: false,
        }

        this.handleSelect = this.handleSelect.bind(this);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.createModifyShipment = this.createModifyShipment.bind(this);

        //Sort
        this.sortBy = this.sortBy.bind(this);
        this.saveAppointment = this.saveAppointment.bind(this)
        this.uploadSignedDocument = this.uploadSignedDocument.bind(this)
        this.uploadAppointmentDocument = this.uploadAppointmentDocument.bind(this)
        this.downloadShippedDocument = this.downloadShippedDocument.bind(this)
        this.removeItemImage = this.removeItemImage.bind(this)
        this.refresh = this.refresh.bind(this)
        this.fileInput = React.createRef();
    }

    componentWillMount() {
        this.props.setTitle('Shipments New');
    }

    componentDidMount() {
        this.props.isLoading(true);
        Promise.all([
            WarehouseService.getWarehouses(),
            FCService.getFCs(),
            ItemService.getActiveItems(),
            OrganisationService.getOrganisationDetails(),
        ])
            .then(datas => {
                let state = {}
                if (datas[0]) {
                    let warehouse_names = datas[0].warehouses.map((w) => (
                        <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
                    ));
                    warehouse_names.unshift(<MenuItem key={"-1"} value={"-1"}>All Warehouses</MenuItem>);
                    state = { ...state, master_warehouse: datas[0].warehouses, warehouse_names: warehouse_names }
                }
                if (datas[1]) {
                    let fc_names = datas[1].fcs.map((fc) => (
                        <MenuItem key={fc.id} value={fc.id}>{fc.name}</MenuItem>
                    ));
                    fc_names.unshift(<MenuItem key={"-1"} value={"-1"}>All FCs</MenuItem>);
                    state = { ...state, master_fc: datas[1].fcs, fc_names: fc_names };
                }
                if (datas[2]) {
                    state = { ...state, master_items: datas[2].items };
                }

                if (datas[3]) {
                    state = { ...state, company_data: datas[3].organisation };
                }

                const txn_type_rows = statuses.map((txn_type) => (
                    <MenuItem key={txn_type.id} value={txn_type.id}>{txn_type.name}</MenuItem>
                ));
                txn_type_rows.unshift(<MenuItem key={"-1"} value={"-1"} selected>All Status</MenuItem>);
                state = { ...state, master_status: txn_type_rows };

                const interstate_rows = interstate.map((option) => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ));
                interstate_rows.unshift(<MenuItem key={"-1"} value={"-1"}>All</MenuItem>);
                state = { ...state, master_interstate_rows: interstate_rows };

                this.setState({ ...state })
            })
            .then(() => this.props.isLoading(false))
            .catch(error => {
                this.props.isLoading(false);
                console.log(error);
            })
        this.fetchData(0, this.state.numItemsPerPage);
    }

    refresh() {
        this.setState({ reload: true })
        this.props.isLoading(true);
        ShipmentService.getShipmentById(this.state.shipment_details.id)
            .then(data => {
                let rows = this.shipmentItemDetailsRows(data);
                this.setState({ shipment_details: data, shipment_details_rows: rows });
            })
            .catch(error => console.log(error))
            .finally(() => this.props.isLoading(false))
    }

    sortBy = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy }, () => this.fetchData(0, this.state.numItemsPerPage, ''));
    }

    handleChangeRowsPerPage = event => {
        this.setState({ numItemsPerPage: event.target.value }, () => this.fetchData(0, this.state.numItemsPerPage, ''));
    }

    handleChangePage = (event, page) => {
        if (this.state.currentPageNumber > page) {
            this.fetchRow(this.state.currentPageNumber - 1);
        } else {
            this.fetchRow(this.state.currentPageNumber + 1);
        }
    };

    isSelected = (id) => {
        return this.state.selected.indexOf(id) !== -1;
    }

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    fetchData(start, numberItems, showLoading = true) {
        if (showLoading) {
            this.props.isLoading(true);
        }
        let sort = this.state.orderBy ? this.state.orderBy : '', direction = this.state.order ? this.state.order : '';
        let warehouse_id = this.state.selected_warehouse === "" ? null : this.state.selected_warehouse;
        let fc_id = this.state.selected_fc === "" ? null : this.state.selected_fc;
        let item_id = this.state.selected_item_id === "" ? null : this.state.selected_item_id;
        let status = this.state.selected_status === "-1" ? '0,1,2,3' : this.state.selected_status;
        let filter = this.state.filter === "" ? null : this.state.filter;
        let interstate = this.state.selected_interstate === "" ? null : this.state.selected_interstate;

        ShipmentService.getTotalCount(warehouse_id, fc_id, item_id, status, interstate, filter, moment.utc(this.state.startDate).format(format), moment.utc(this.state.endDate).format(format), 'v2')
            .then((data) => {
                this.setState({
                    totalItems: toInt(data.count),
                    totalPages: Math.ceil(data.count / this.state.numItemsPerPage) - 1
                });
                return ShipmentService.getShipmentsV2(start, numberItems, moment.utc(this.state.startDate).format(format), moment.utc(this.state.endDate).format(format), warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction)
            })
            .then((data) => {
                this.setState({ data: data.shipments, originalData: data.shipments });
                if (data.shipments) {
                    this.setState({
                        current_rows: data.shipments
                    });
                }
                if (showLoading) {
                    this.props.isLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                if (showLoading) {
                    this.props.isLoading(false);
                }
            });
    }

    handleChangeShipmentDate = (date) => {
        this.setState(prevState => ({
            new_shipment: {
                ...prevState.new_shipment,
                shipment_date: date
            }
        }));
    }

    handleChange = (e) => {
        let change = this.state.new_shipment;
        if (e.target.name === 'interstate') {
            change[e.target.name] = e.target.checked ? '1' : '0';
        } else {
            change[e.target.name] = e.target.value;
        }
        let error_fields = this.state.error_fields;
        error_fields[e.target.name] = e.target.value ? false : true;

        this.setState({
            new_shipment: change,
            error_fields: error_fields
        });
    }

    edit = (e, shipment) => {
        if (shipment) {
            let items = JSON.parse(JSON.stringify(shipment.items));
            let rows = this.updateShipmentItemRows(items);
            const cartons = shipment.cartons
                .map(c => ({
                    ...c,
                    ...getDimensions(c.dimension)
                }))
            this.setState({ new_shipment: { new: false, id: shipment.id, shipment_identifier: shipment.shipment_identifier, warehouse_id: shipment.warehouse_id, fc_id: shipment.fc_id, shipment_date: getTimeInIST(shipment.shipment_date), invoice_no: shipment.invoice_no, interstate: shipment.interstate, vehicle_no: shipment.vehicle_no, items: items, cartons }, item_rows: rows });
        }
        this.handleShow();
    }

    duplicateShipment = (s) => {
        let shipment = Object.assign({}, s);
        shipment.id = null;
        if (shipment) {
            let items = JSON.parse(JSON.stringify(shipment.items));
            let rows = this.updateShipmentItemRows(items);
            const cartons = shipment.cartons
                .map(c => ({
                    weight: c.weight,
                    ...getDimensions(c.dimension)
                }))
            this.setState({ new_shipment: { new: true, id: undefined, shipment_identifier: '', warehouse_id: shipment.warehouse_id, fc_id: shipment.fc_id, shipment_date: getTimeInIST(shipment.shipment_date), invoice_no: shipment.invoice_no, interstate: shipment.interstate, vehicle_no: shipment.vehicle_no, items: items, cartons }, item_rows: rows });
        }
        this.handleShow();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_shipment: { new: true, id: undefined, shipment_identifier: '', warehouse_id: "", fc_id: "", shipment_date: moment(), invoice_no: "", interstate: "1", vehicle_no: "", items: [], cartons: [] },
            error_fields: { shipment_identifier: false, project_id: false, user_id: false, item_id: false, txn_type: false, qty: false, comments: false, vehicle_no: false },
            item_rows: []
        });
    }

    onCloseAddCartons = () => {
        this.setState({ showAddCartons: false })
    }

    onCloseCartonDetails = () => {
        this.setState({ showCartonDetails: false })
    }

    handleShow() {
        this.setState({ show: true });
    }

    showDetails = (shipment) => {
        let shipment_details = JSON.parse(JSON.stringify(shipment));
        let rows = this.shipmentItemDetailsRows(shipment_details);
        this.setState({ shipment_details: shipment_details, shipment_details_rows: rows });
        this.handleShowDetails();
    }

    removeShipment = (e, shipment) => {
        this.props.showConfirmDialog("Confirm remove shipment?", undefined, () => {
            ShipmentService.removeShipment(shipment.id)
                .then(data => {
                    this.props.showSnackBar('Removed Shipment.', 'success');
                    this.fetchData(this.state.currentPageNumber * this.state.numItemsPerPage, this.state.numItemsPerPage, false);
                })
                .catch(error => {
                    this.props.showSnackBar('Could not remove shipment. Please try again or contact admin.', 'error');
                });
            this.props.closeConfirmDialog();
        });
    }

    handleShowDetails = () => {
        this.setState({ showDetails: true });
    }

    handleCloseDetails = () => {
        this.setState({
            showDetails: false,
            shipment_details: null,
            shipment_details_rows: []
        });
        if (this.state.reload) {
            this.fetchData(this.state.currentPageNumber * this.state.numItemsPerPage, this.state.numItemsPerPage);
            this.setState({ reload: false });
        }
    }

    shipmentItemDetailsRows = (shipment_details) => {
        let items = shipment_details.items;
        return items.filter(i => toInt(i.status) !== -1).map(item => (
            <ItemDisplayDetails key={item.item_id} item={item} interstate={shipment_details.interstate === 1} master_items={this.state.master_items} {...this.props} refresh={this.refresh} />
        ));
    }

    confirmSave = () => {
        let new_shipment = this.state.new_shipment;
        new_shipment.is_new = new_shipment.new;
        new_shipment.shipment_date = moment(new_shipment.shipment_date).utc().format(format);
        console.log(new_shipment.cartons)
        new_shipment.cartons = new_shipment.cartons.map(carton => ({
            id: carton.id,
            weight: carton.weight,
            dimension: getDimensionString(carton.len, carton.breadth, carton.height),
        }))
        this.props.isLoading(true);
        let headers = { user_id: this.props.user.id };
        ShipmentService.createShipmentV2(headers, new_shipment)
            .then((response) => {
                this.props.closeConfirmDialog();
                this.props.isLoading(false);
                this.handleClose();
                this.fetchData(this.state.currentPageNumber * this.state.numItemsPerPage, this.state.numItemsPerPage);
                this.props.showSnackBar(`Shipment created.`, 'success');
            })
            .catch(error => {
                if (error.error_shipment_id) {
                    this.props.showSnackBar(error.errorMessage, 'error');
                } else if (error.error_invoice_no) {
                    this.props.showSnackBar(error.errorMessage, 'error');
                } else {
                    this.props.showSnackBar(`Something went wrong. Please try again later.`, 'error');
                }
                this.props.closeConfirmDialog();
                this.props.isLoading(false);
            });
    }

    createModifyShipment(e) {
        e.preventDefault();
        let new_shipment = this.state.new_shipment;
        let error_fields = this.state.error_fields;
        if (new_shipment.shipment_identifier && new_shipment.warehouse_id && new_shipment.fc_id
            && new_shipment.invoice_no && new_shipment.items.length > 0
            && new_shipment.items.filter(i => (i.units || 0) > 0).length === new_shipment.items.length
            && new_shipment.cartons.length > 0
        ) {
            this.setState({ new_shipment: new_shipment }, () => this.props.showConfirmDialog("Create Shipment?", "Confirm", this.confirmSave));
            return;
        }

        if (!new_shipment.shipment_identifier) {
            error_fields.shipment_identifier = true;
        }
        if (!new_shipment.warehouse_id) {
            error_fields.warehouse_id = true;
        }
        if (!new_shipment.fc_id) {
            error_fields.fc_id = true;
        }

        if (!new_shipment.invoice_no) {
            error_fields.invoice_no = true;
        }
        // if(!(moment(new_shipment.shipment_date) >= moment().startOf('day'))){
        //     error_fields.shipment_date = true;
        // }
        if (new_shipment.items.length === 0 || new_shipment.items.filter(i => (i.units || 0) > 0).length !== new_shipment.items.length) {
            this.props.showSnackBar('Please add items, their quantities and proceed', 'error');
        }
        if (new_shipment.cartons.length === 0) {
            this.props.showSnackBar('Please add cartons', 'error');
        }
        this.setState({ error_fields: error_fields });
    }

    handleSelect(eventKey, e) {
        if (eventKey === 'prev') {
            if ((this.state.currentPageNumber) > 0) {
                this.fetchRow(this.state.currentPageNumber - 1);
            }
        } else if (eventKey === 'next') {
            if ((this.state.currentPageNumber) < this.state.totalPages) {
                this.fetchRow(this.state.currentPageNumber + 1);
            }
        }
    }

    fetchRow(newCurrentPage) {
        this.fetchData(newCurrentPage * this.state.numItemsPerPage, this.state.numItemsPerPage);
        this.setState({ currentPageNumber: newCurrentPage });
    }

    handleChangeSearchText = (e) => {
        this.setState({ filter: e.target.value }, () => this.fetchData(0, this.state.numItemsPerPage, true));
    }

    handleWarehouseSelection = (e) => {
        this.setState({ selected_warehouse: e.target.value }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleFCSelection = (e) => {
        this.setState({ selected_fc: e.target.value }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleStatusSelection = (e) => {
        this.setState({ selected_status: e.target.value }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleInterstateSelection = (e) => {
        this.setState({ selected_interstate: e.target.value }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date,
        }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    changeStatus = (event, shipment) => {
        if (this.props.user.roles.includes(ROLES.ADMIN)) {
            if (shipment.status !== '2' && shipment.status !== '3') {
                this.setState({ selected_shipment: shipment }, () => this.handleShowChangeStatus());
            } else {
                this.props.showSnackBar('Cannot change state of an Completed or Cancelled shipment', 'warning');
            }
        }
    }

    handleShowChangeStatus = () => {
        this.setState({ showChangeStatus: true });
    }

    handleCloseChangeStatus = () => {
        this.setState({ showChangeStatus: false, selected_shipment: { status: "0" } });
    }

    changeStatusForShipment = (e) => {
        this.props.showConfirmDialog("Confirm change status?", undefined, () => {
            let headers = { user_id: this.props.user.id };
            ShipmentService.changeShipmentStatus(headers, { id: this.state.selected_shipment.id, status: e.target.value })
                .then((data) => {
                    this.props.showSnackBar(`Changed status of Shipment`, 'success');
                    this.fetchData(this.state.currentPageNumber * this.state.numItemsPerPage, this.state.numItemsPerPage, false);
                    this.props.closeConfirmDialog();
                    this.handleCloseChangeStatus();
                })
                .catch(error => {
                    console.log(error);
                    this.props.showSnackBar(`Failed to change status. Please try again.`, 'error');
                });
        });
    }

    removeItem = (e, item) => {
        if (toInt(item.no_of_cases) !== 0) {
            this.props.showConfirmDialog("Confirm Remove?", undefined, () => {
                this.confirmRemove(item, this.props.closeConfirmDialog);
            });
        } else {
            this.confirmRemove(item, undefined);
        }
    }

    confirmRemove = (item, callback) => {
        let items = this.state.new_shipment.items;

        if (this.state.new_shipment.new) {
            items = items.filter((i) => i.item_id !== item.item_id);
        } else {
            let i = items.filter((i) => i.item_id === item.item_id)[0];
            i.status = "-1";
        }
        this.setState((prevState) => ({
            new_shipment: {
                ...prevState.new_shipment,
                items: items
            },
            item_rows: this.updateShipmentItemRows(items)
        }), () => callback ? callback() : null);
    }

    handleItemChange = (e, field) => {
        let i = this.state.new_shipment.items;
        let item = i.filter((i) => i.item_id === e.target.name);
        if (item.length === 1) {
            item[0][field] = e.target.value;
        }
        this.setState((prevState) => ({
            new_shipment: {
                ...prevState.new_shipment,
                items: i
            }
        }));
    }

    updateShipmentItemRows = (items) => {
        return items.filter(i => toInt(i.status) !== -1).map(item => (
            <ItemDetails key={item.item_id} item={item} master_items={this.state.master_items} handleItemChange={this.handleItemChange} removeItem={this.removeItem} classes={this.props.classes} />
        ));
    }

    handleAddItemClick = () => {
        if (this.state.master_items.filter((item) => this.state.new_shipment.items.filter((i) => i.item_id === item.id && toInt(i.status) === 1).length === 0).length > 0) {
            this.setState({ showAddItem: true });
        } else {
            this.props.showSnackBar(`No more Items left to add.`, 'info');
        }
    }

    handleShowAddItemShipment = () => {
        this.setState({ showAddItem: true });
    }

    handleCloseAddItemShipment = () => {
        this.setState({ showAddItem: false });
    }

    handleAddItemToShipment = (e) => {
        let item = this.state.master_items.filter(i => i.id === e.id)[0];
        let error_fields = this.state.error_fields;
        if (item) {
            let i = {
                item_id: item.id,
                item_name: item.name,
                units: "0",
                status: "1",
                rate: item.rate,
                igst: item.igst,
                cgst: item.cgst,
                sgst: item.sgst
            }
            let items = this.state.new_shipment.items;
            items = items.filter(i => i.item_id !== item.id);
            items.push(i);
            error_fields.items = items.length === 0 ? true : false;
            this.setState((prevState) => ({
                new_shipment: {
                    ...prevState.new_shipment,
                    items: items
                },
                error_fields: error_fields,
                item_rows: this.updateShipmentItemRows(items)
            }), () => this.handleCloseAddItemShipment());
        }
    }

    saveAppointment(date) {
        this.props.isLoading(true);
        this.setState(old => ({ shipment_details: { ...old.shipment_details, appointment: date } }))
        ShipmentService.updateAppointmentDate({ appointment: moment(date).format(format_without_time), shipment_identifier: this.state.shipment_details.shipment_identifier, id: this.state.shipment_details.id })
            .then((data) => {
                this.props.isLoading(false);
                this.setState({ reload: true })
                if (data.success) {
                    this.props.showSnackBar("Updated", 'success');
                }
            })
            .catch((error) => {
                this.props.isLoading(false);
                this.props.showSnackBar("Something went wrong. Please try again", 'error');
                console.log(error);
            });
    }

    uploadAppointmentDocument(event) {
        let files = event.target.files;
        if (files.length === 1) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let name = files[0].name;
            reader.onload = (e) => {
                this.props.isLoading(true);
                ShipmentService.uploadAppointmentDoc({ name: name, file: e.target.result, shipment_identifier: this.state.shipment_details.shipment_identifier, id: this.state.shipment_details.id })
                    .then((data) => {
                        this.props.isLoading(false);
                        if (data.success) {
                            this.props.showSnackBar("Uploaded", 'success');
                            this.setState(old => ({ shipment_details: { ...old.shipment_details, appointment_doc: data.download_url }, reload: true }))
                        }
                    })
                    .catch((error) => {
                        this.props.isLoading(false);
                        this.props.showSnackBar("File import failed. Please try again. Check Extension and file name.", 'error');
                        console.log(error);
                    });
            }
        }
    }

    uploadSignedDocument(event) {
        let files = event.target.files;
        if (files.length === 1) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let name = files[0].name;
            reader.onload = (e) => {
                this.props.isLoading(true);
                ShipmentService.uploadSignedDoc({ name: name, file: e.target.result, shipment_identifier: this.state.shipment_details.shipment_identifier, id: this.state.shipment_details.id })
                    .then((data) => {
                        this.props.isLoading(false);
                        if (data.success) {
                            this.props.showSnackBar("Uploaded", 'success');
                            this.setState(old => ({ shipment_details: { ...old.shipment_details, signed_doc: data.download_url }, reload: true }))
                        }
                    })
                    .catch((error) => {
                        this.props.isLoading(false);
                        this.props.showSnackBar("File import failed. Please try again. Check Extension and file name.", 'error');
                        console.log(error);
                    });
            }
        }
    }

    removeItemImage(id) {
        this.props.showConfirmDialog("Confirm remove?", undefined, () => {
            this.props.closeConfirmDialog();
            this.props.isLoading(true);
            ShipmentService.removeShipmentImage(id)
                .then((data) => {
                    this.props.isLoading(false);
                    if (data.success) {
                        this.props.showSnackBar("Removed", 'success');
                        this.refresh()
                    }
                })
                .catch((error) => {
                    this.props.isLoading(false);
                    this.props.showSnackBar("File import failed. Please try again. Check Extension and file name.", 'error');
                    console.log(error);
                });
        });
    }

    downloadShippedDocument() {
        const shipment = this.state.shipment_details;
        let filename = `Shipment Weight_${shipment.shipment_identifier || ''}_${shipment.fc_name}_${moment().format("DD.MM.YY")}.pdf`;
        ShipmentService.getShipmentDoc(this.state.shipment_details.id)
            .then(response => {
                return response.blob()
            })
            .then((blob) => {
                this.props.isLoading(false);
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // Shipment Weight_FBA15DMN8LZY_DEL2_17.03.21.pdf
                link.setAttribute('download', filename);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                this.props.isLoading(false);
                console.log(error);
            });
    }

    exportReport = async () => {
        let sort = this.state.orderBy ? this.state.orderBy : '', direction = this.state.order ? this.state.order : '';
        let warehouse_id = this.state.selected_warehouse === "" ? null : this.state.selected_warehouse;
        let fc_id = this.state.selected_fc === "" ? null : this.state.selected_fc;
        let item_id = this.state.selected_item_id === "" ? null : this.state.selected_item_id;
        let status = this.state.selected_status === "-1" ? '0,1,2,3' : this.state.selected_status;
        let filter = this.state.filter === "" ? null : this.state.filter;
        let interstate = this.state.selected_interstate === "" ? null : this.state.selected_interstate;
        if (!warehouse_id) {
            this.props.showSnackBar('Please select warehouse and continue', 'error');
        } else {
            this.props.isLoading(true);
            let fileName = `Shipments-${moment.utc(this.state.startDate).format(format_export_new)}-${moment.utc(this.state.endDate).format(format_export_new)}.xlsx`;
            ShipmentService.getExportV2(0, this.state.totalItems, moment.utc(this.state.startDate).format(format), moment.utc(this.state.endDate).format(format), warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction)
                .then(response => {
                    return response.blob();
                })
                .then((blob) => {
                    this.props.isLoading(false);
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    // 3. Append to html page
                    document.body.appendChild(link);
                    // 4. Force download
                    link.click();
                    // 5. Clean up and remove the link
                    link.parentNode.removeChild(link);
                })
                .catch(error => {
                    this.props.isLoading(false);
                    console.log(error);
                });
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.current_rows.map((n) => n.id);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    render() {
        const { classes } = this.props;
        const { selected, current_rows, showShipmentDetails, company_data, master_items, master_fc, master_warehouse } = this.state;
        return (
            <div className="Home">
                <FormControl size="small" variant="outlined" className={classnames(classes.formControlLeft, classes.formControl)} style={{ float: "left" }}>
                    <InputLabel
                        ref={ref => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-warehouse-simple"
                    >
                        Warehouse
                    </InputLabel>
                    <Select
                        value={this.state.selected_warehouse}
                        onChange={this.handleWarehouseSelection}
                        input={
                            <OutlinedInput
                                labelWidth={80}
                                name="Warehouse"
                                id="outlined-warehouse-simple"
                            />
                        }
                    >
                        {this.state.warehouse_names}
                    </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" className={classes.formControl} style={{ float: "left" }}>
                    <InputLabel
                        ref={ref => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-fc-simple"
                    >
                        FC
                    </InputLabel>
                    <Select
                        value={this.state.selected_fc}
                        onChange={this.handleFCSelection}
                        input={
                            <OutlinedInput
                                labelWidth={20}
                                name="FC"
                                id="outlined-fc-simple"
                            />
                        }
                    >
                        {this.state.fc_names}
                    </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" className={classes.formControl} style={{ float: "left" }}>
                    <InputLabel
                        ref={ref => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-status-simple"
                    >
                        Status
                    </InputLabel>
                    <Select
                        value={this.state.selected_status}
                        onChange={this.handleStatusSelection}
                        input={
                            <OutlinedInput
                                labelWidth={50}
                                name="Status"
                                id="outlined-txn_type-simple"
                            />
                        }
                    >
                        {this.state.master_status}
                    </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" className={classes.formControl} style={{ float: "left" }}>
                    <InputLabel
                        ref={ref => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-interstate-simple"
                    >
                        Interstate
                    </InputLabel>
                    <Select
                        value={this.state.selected_interstate}
                        onChange={this.handleInterstateSelection}
                        input={
                            <OutlinedInput
                                labelWidth={70}
                                name="Status"
                                id="outlined-interstate-simple"
                            />
                        }
                    >
                        {this.state.master_interstate_rows}
                    </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        margin="normal"
                        label="From"
                        value={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                        format='DD MMM YYYY'
                        maxDate={this.state.endDate}
                        variant="dialog"
                        size="small"
                        inputVariant="outlined"
                        className={classes.formControl}
                        style={{ float: "left", height: "100%", marginTop: "24px" }}
                    />
                    <DatePicker
                        margin="normal"
                        label="To"
                        size="small"
                        value={this.state.endDate}
                        onChange={this.handleChangeEndDate}
                        format='DD MMM YYYY'
                        variant="dialog"
                        inputVariant="outlined"
                        minDate={this.state.startDate}
                        className={classes.formControl}
                        style={{ float: "left", height: "100%", marginTop: "24px" }}
                    />
                </MuiPickersUtilsProvider>
                <div className="container">
                    <Paper className={this.props.classes.root} style={{ width: "100%", marginTop: "50px", paddingLeft: "5px", paddingRight: "5px", marginBottom: 75 }}>
                        <EnhancedTableToolbar
                            searchText={this.state.filter}
                            onSearchTextChange={this.handleChangeSearchText}
                            searchPlaceholder="Shipment ID, Invoice No Or Vehicle No"
                            exportReport={this.exportReport}
                            numSelected={selected.length}
                            SelectionComponent={() => (
                                <div style={{ flex: '1 5 100%' }}>
                                    <Typography color="inherit" variant="subtitle1" component="span">
                                        Shipment Details
                                    </Typography>
                                    <Tooltip title="Download">
                                        <IconButton aria-label="delete" onClick={() => this.setState({ showShipmentDetails: true })}>
                                            <Icon>save_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        />
                        <TableContainer>
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.sortBy}
                                    rows={this.state.columns}
                                    numSelected={selected.length}
                                    checkboxSelection
                                    onSelectAllClick={this.handleSelectAllClick}
                                    rowCount={current_rows.length} />
                                <TableBody>
                                    {current_rows.map((shipment) => (
                                        <ShipmentDetails
                                            handleClick={this.handleClick}
                                            isSelected={this.isSelected}
                                            classes={this.props.classes}
                                            key={shipment.id}
                                            shipment={shipment}
                                            changeStatus={this.changeStatus}
                                            printLabel={() => printLabel(shipment)}
                                            printInvoice={() => printInvoice(shipment, master_items, company_data, master_warehouse, master_fc)}
                                            edit={this.edit}
                                            showPackingList={() => showPackingList(shipment, master_items)}
                                            duplicateShipment={() => this.duplicateShipment(shipment)}
                                            showDetails={() => this.showDetails(shipment)}
                                            removeShipment={this.removeShipment} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={RowCountOptions}
                            component="div"
                            count={this.state.totalItems}
                            rowsPerPage={this.state.numItemsPerPage}
                            page={this.state.currentPageNumber}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
                <DialogMUI
                    fullScreen
                    TransitionComponent={Transition}
                    open={this.state.show}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Shipment
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <DialogTitle id="alert-dialog-title">Create Transaction</DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" component={'span'}>
                            <TextField
                                id="shipment_identifier"
                                label="Shipment ID"
                                placeholder="Shipment ID"
                                name="shipment_identifier"
                                defaultValue={this.state.new_shipment.shipment_identifier}
                                className={this.props.classes.textField}
                                margin="normal"
                                variant="outlined"
                                onChange={this.handleChange}
                                required
                                error={this.state.error_fields.shipment_identifier}
                                helperText="Enter Shipment ID"
                                type="text"
                                style={{ marginTop: '8px' }}
                            />
                            <TextField
                                id="invoice_no"
                                label="Invoice No"
                                placeholder="Invoice No"
                                name="invoice_no"
                                defaultValue={this.state.new_shipment.invoice_no}
                                className={this.props.classes.textField}
                                margin="normal"
                                variant="outlined"
                                onChange={this.handleChange}
                                required
                                error={this.state.error_fields.invoice_no}
                                helperText="Enter Invoice No"
                                type="text"
                                style={{ marginTop: '8px' }}
                            />
                            <SelectDropdown required error={this.state.error_fields.warehouse_id} disabled={this.state.master_warehouse.length === 0} label="Warehouse" hint="Select Warehouse" name="warehouse_id" options={this.state.master_warehouse} value={this.state.new_shipment.warehouse_id} onChange={this.handleChange} />
                            <SelectDropdown required error={this.state.error_fields.fc_id} label="FC" hint="Select FC" name="fc_id" options={this.state.master_fc} value={this.state.new_shipment.fc_id} onChange={this.handleChange} />
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    margin="normal"
                                    label="Shipment Date"
                                    value={this.state.new_shipment.shipment_date}
                                    onChange={this.handleChangeShipmentDate}
                                    format='DD MMM YYYY'
                                    minDate={this.props.user.roles.includes(ROLES.ADMIN) ? undefined : moment()}
                                    variant="dialog"
                                    inputVariant="outlined"
                                    style={{ marginTop: "8px" }}
                                    helperText={`Select Shipment Date ${this.props.user.roles.includes(ROLES.ADMIN) && " - No restriction for admin users"}`}
                                />
                            </MuiPickersUtilsProvider>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.new_shipment.interstate === '1'}
                                        onChange={this.handleChange}
                                        name='interstate'
                                        value={this.state.new_shipment.interstate}
                                    />
                                }
                                style={{ marginTop: 10, marginLeft: 8, marginRight: 8 }}
                                label="Interstate"
                            />
                            {
                                this.state.new_shipment.interstate === '0' &&
                                <TextField
                                    id="vehicle_no"
                                    label="Vehicle No"
                                    placeholder="Vehicle No"
                                    name="vehicle_no"
                                    defaultValue={this.state.new_shipment.vehicle_no}
                                    className={this.props.classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    helperText="Enter Vehicle No"
                                    type="text"
                                    style={{ marginTop: '8px' }}
                                />
                            }
                            <div style={{ textAlign: 'right' }}>
                                <Typography variant='caption' style={{ marginRight: 16 }}>Total Cartons: {this.state.new_shipment.cartons ? this.state.new_shipment.cartons.length : 0}</Typography>
                                <Button size='small' color='primary' variant='text' onClick={() => this.setState({ showAddCartons: true })}>Add Cartons</Button>
                            </div>
                            <Divider />
                            <List className={this.props.classes.list}>
                                {this.state.item_rows}
                            </List>
                        </DialogContentText>
                        <Fab onClick={this.handleAddItemClick} variant="extended" color="secondary" aria-label="Add Item" className={classes.fab} style={{ position: 'fixed', top: '80%', left: '88%' }}>
                            <AddIcon />
                            Add Item
                        </Fab>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={this.createModifyShipment} color="primary" autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </DialogMUI>
                <AddCartons
                    cartons={this.state.new_shipment.cartons}
                    onClose={this.onCloseAddCartons}
                    open={this.state.showAddCartons}
                    onSave={(cartons) => {
                        this.setState(prev => ({ new_shipment: { ...prev.new_shipment, cartons }, showAddCartons: false }))
                    }}
                    loading={false}
                />
                {this.state.shipment_details &&
                    <DialogMUI
                        fullWidth
                        maxWidth={'lg'}
                        TransitionComponent={Transition}
                        open={this.state.showDetails}
                        onClose={this.handleCloseDetails}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll='paper'>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color="inherit" onClick={this.handleCloseDetails} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    Shipment Details
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" component={'span'}>
                                <Grid container spacing={1} style={{ flexGrow: 1, marginLeft: 10, marginBottom: 15 }} justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle1" className={classes.heading}>Shipment ID</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {this.state.shipment_details.shipment_identifier}
                                    </Grid>
                                    <Grid item xs={3} />
                                    <Grid item xs={3} >
                                        {this.state.shipment_details.interstate === 1 ? <Avatar className={classes.orangeAvatar}>INT</Avatar> : <Avatar className={classes.purpleAvatar}>LOC</Avatar>}
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="subtitle1" className={classes.heading}>Invoice No</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        {this.state.shipment_details.invoice_no}
                                    </Grid>
                                    <Hidden mdDown>
                                        <Grid item xs={6} />
                                    </Hidden>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="subtitle1" className={classes.heading}>Shipment Date</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        {getTimeInIST(this.state.shipment_details.shipment_date).format(format_invoice)}
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                margin="dense"
                                                label="Appointment Date"
                                                value={this.state.shipment_details ? (this.state.shipment_details.appointment || null) : null}
                                                onChange={(date) => this.saveAppointment(date)}
                                                format='DD MMM YYYY'
                                                minDate={this.props.user.roles.includes(ROLES.ADMIN) ? undefined : moment()}
                                                variant="dialog"
                                                inputVariant="outlined"
                                                helperText={this.props.user.roles.includes(ROLES.ADMIN) && `No restriction for admin`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton color="inherit" style={{ padding: 0 }}>
                                                            <CalendarIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Hidden mdDown>
                                        <Grid item md={3} />
                                    </Hidden>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="subtitle1" className={classes.heading}>Warehouse</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        {this.state.shipment_details.warehouse_name}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" className={classes.heading} style={{ marginRight: 16 }}>{this.state.shipment_details.appointment_doc ? `Replace` : `Add`} Appointment Doc</Typography>
                                        <Tooltip title="Click to upload document">
                                            <label htmlFor="appointment-doc-button-file">
                                                <UploadIcon />
                                                <input onChange={this.uploadAppointmentDocument} id="appointment-doc-button-file" type="file" name="appointment_doc" accept=".pdf" multiple={false} ref={this.fileInput} style={{ display: 'none' }} />
                                            </label>
                                        </Tooltip>
                                        {this.state.shipment_details.appointment_doc && (
                                            <Tooltip title="Click to download document">
                                                <IconButton style={{ padding: 0, marginLeft: 12, marginBottom: 16 }} color="inherit" onClick={() => window.open(this.state.shipment_details.appointment_doc)} aria-label="Close">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <div>
                                            <Typography variant="subtitle1" component="span" className={classes.heading} style={{ marginLeft: 8, marginRight: 16 }}>No of Cartons:</Typography>{this.state.shipment_details.cartons.length}
                                        </div>
                                        <div>
                                            <Typography variant="subtitle1" component="span" className={classes.heading} style={{ marginLeft: 8, marginRight: 16 }}>No of Units:</Typography>{this.state.shipment_details.items.reduce((sum, next) => sum + next.units, 0)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="subtitle1" className={classes.heading}>Fulfillment Center</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        {this.state.shipment_details.fc_name}
                                    </Grid>
                                    <Grid item xs={12} md={3} />
                                    <Grid item xs={6} md={3}>
                                        <Button variant='contained' color='primary' onClick={() => this.setState({ showCartonDetails: true })}>View Carton Details</Button>
                                    </Grid>
                                    {this.state.shipment_details.vehicle_no &&
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" className={classes.heading}>Vehicle No</Typography>
                                        </Grid>
                                    }
                                    {this.state.shipment_details.vehicle_no &&
                                        <Grid item xs={3}>
                                            {this.state.shipment_details.vehicle_no}
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle1" component="span" className={classes.heading} style={{ marginRight: 16 }}>Download Shipped Document</Typography>
                                        <IconButton color="inherit" onClick={this.downloadShippedDocument} aria-label="Close">
                                            <DownloadIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle1" className={classes.heading} style={{ marginRight: 16 }}>{this.state.shipment_details.signed_doc ? `Replace` : `Add`} Signed & Scanned Doc</Typography>
                                        <Tooltip title="Click to upload document">
                                            <label htmlFor="signed-doc-button-file">
                                                <UploadIcon />
                                                <input onChange={this.uploadSignedDocument} id="signed-doc-button-file" type="file" name="appointment_doc" accept=".pdf" multiple={false} ref={this.fileInput} style={{ display: 'none' }} />
                                            </label>
                                        </Tooltip>
                                        {this.state.shipment_details.signed_doc && (
                                            <Tooltip title="Click to download document">
                                                <IconButton style={{ padding: 0, marginLeft: 12, marginBottom: 16 }} color="inherit" onClick={() => window.open(this.state.shipment_details.signed_doc)} aria-label="Close">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell></CustomTableCell>
                                            <CustomTableCell>Item</CustomTableCell>
                                            <CustomTableCell align="left" padding='none'>FNSKU</CustomTableCell>
                                            <CustomTableCell align="right" padding='none'>HSN Code</CustomTableCell>
                                            <CustomTableCell align="right" padding='none'>Rate</CustomTableCell>
                                            <CustomTableCell align="right" padding='none' style={{ paddingRight: 8 }}>Units</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.shipment_details_rows}
                                    </TableBody>
                                </Table>
                                {
                                    this.state.shipment_details.items && (
                                        <ImageList cols={4} rowHeight={180} className={classes.imageList}>
                                            {this.state.shipment_details.items.map(row => row.images).reduce((sum, next) => [...sum, ...next], []).map((item) => (
                                                <ImageListItem key={item.id}>
                                                    <img src={item.link} alt={item.link} height="100" />
                                                    <ImageListItemBar
                                                        title={`Weight: ${item.weight}`}
                                                        actionIcon={
                                                            <Tooltip title="Remove image">
                                                                <IconButton onClick={() => this.removeItemImage(item.id)} className={classes.icon} style={{ color: grey[300] }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    )
                                }
                            </DialogContentText>
                            <CartonDetails
                                cartons={this.state.shipment_details.cartons}
                                onClose={this.onCloseCartonDetails}
                                open={this.state.showCartonDetails}
                                loading={false}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDetails} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </DialogMUI>
                }
                <DialogMUI
                    open={this.state.showChangeStatus}
                    onClose={this.handleCloseChangeStatus}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'>
                    <DialogTitle id="alert-dialog-title">Change Status</DialogTitle>
                    <DialogContent style={{ minWidth: "300px" }}>
                        <Select
                            value={this.state.selected_shipment.status}
                            onChange={(e) => this.changeStatusForShipment(e)}
                            input={
                                <OutlinedInput
                                    labelWidth={200}
                                    name="Status"
                                    id="outlined-status-simple"
                                />
                            }
                            style={{ width: '100%' }}
                        >
                            <MenuItem value={"0"}>{convertShipmentStatusToString("0")}</MenuItem>
                            <MenuItem value={"1"}>{convertShipmentStatusToString("1")}</MenuItem>
                            <MenuItem value={"2"}>{convertShipmentStatusToString("2")}</MenuItem>
                            <MenuItem value={"3"}>{convertShipmentStatusToString("3")}</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseChangeStatus} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogMUI>
                <DialogMUI
                    open={this.state.showAddItem}
                    onClose={this.handleCloseAddItemShipment}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll='paper'>
                    <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 5 }}>Select Item</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl} style={{ minWidth: "300px", minHeight: 150 }}>
                            <AutoCompleteSelection
                                menuIsOpen={true}
                                placeHolder="Select Item"
                                onChange={this.handleAddItemToShipment}
                                name="items"
                                value={[]}
                                error={this.state.error_fields.items}
                                options={this.state.master_items.filter((item) => this.state.new_shipment.items.filter((i) => i.item_id === item.id && toInt(i.status) === 1).length === 0).map(item => ({
                                    id: item.id,
                                    name: item.name,
                                    value: item.id,
                                    label: `${item.name} [${item.fnsku}]`,
                                    status: item.status
                                }))} />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseAddItemShipment} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </DialogMUI>
                {this.state.master_warehouse.length !== 0 && this.state.master_fc.length !== 0 && this.state.master_items.length !== 0 &&
                    <Fab variant="extended" size="small" onClick={this.edit} color="primary" aria-label="Add" className={this.props.classes.fab} style={{ position: 'fixed', top: '90%', left: '85%' }}>
                        <AddIcon />
                        <span className={this.props.classes.fabText}>
                            Create Shipment
                        </span>
                    </Fab>
                }
                <ShipmentDetailExport
                    open={showShipmentDetails}
                    onClose={() => this.setState({ showShipmentDetails: false })}
                    data={current_rows.filter(row => selected.includes(row.id))}
                    showSnackBar={this.props.showSnackBar}
                />
            </div>
        );
    }

}

export default withStyles(styles)(Shipment);