import React from "react";
import { Tooltip, Popover } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import { toWords } from 'number-to-words';
import { Chip } from '@material-ui/core';

export function tooltip(text) {
  return (
    <Tooltip id="tooltip">
      <strong>{text}</strong>
    </Tooltip>
  );
}

export function popover(text) {
  return (
    <Popover id="popover-top">
      <strong>{text}</strong>
    </Popover>
  );
}

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
export const format_export = "DD/MM/YY";
export const format_export_new = "DDMMYY";
export const format_export_dash = "DD-MM-YYYY";
export const format_datetime = "DDMMYYYYHHmm";
export const format_export_planner = "DDMMYYYY_HHmm";
export const format_invoice = "Do MMM YYYY";

export function getTimeInIST(dateString) {
  const timeInUTC = moment.utc(dateString, format);
  return timeInUTC.clone().tz("Asia/Kolkata");
}

export function currentTimeInIST() {
  return moment
    .utc()
    .clone()
    .tz("Asia/Kolkata");
}

export function toInt(n) {
  return parseInt(n, 10);
}

export function convertShipmentStatusToString(status) {
  switch (status) {
    case "0":
      return "OnHold";
    case "1":
      return "In-Progress";
    case "2":
      return "Completed";
    case "3":
      return "Cancelled";
    default:
      return "None";
  }
}

export function getReturnType(type) {
  switch (type) {
    case "R":
      return "Return";
    case "T":
      return "Transfer";
    default:
      return "None";
  }
}

export function getShipmentStatusBadge(status) {
  let color = "#FFFFFF";
  switch (status) {
    case "0":
      color = "default";
      break;
    case "1":
      color = "primary";
      break;
    case "2":
      color = "secondary";
      break;
    case "3":
      color = "default";
      break;
    default:
      color = "default";
      break;
  }

  return <Chip size="small" label={convertShipmentStatusToString(status)} color={color} />;
}

export function numberToText(number) {
  return toWords(number).replace(/-/g, ' ');
}

export function formatNumber(number) {
  return parseFloat(number).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'INR' });
}

export function titleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export function precision(number) {
  return parseFloat(number.toFixed(2));
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
  return Promise.resolve()
}

export const copyToClipBoard = (message) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(message);
    return;
  }
  return navigator.clipboard.writeText(message);
};

export const getDimensionString = (l = 0, b = 0, h = 0) => {
  return `${l}x${b}x${h}`
}

export const getDimensions = (dimension) => {
  const dim = dimension.split('x')
  return { len: dim[0], breadth: dim[1], height: dim[2] }
}