export const Constants = {
    STORE: "STORE",
    CUSTOMER: "CUSTOMER"
};

export const STATES = [
    { label: "Andaman and Nicobar Islands", code: "AN", state_code: "35" },
    { label: "Andhra Pradesh", code: "AP", state_code: "37" },
    { label: "Arunachal Pradesh", code: "AR", state_code: "12" },
    { label: "Assam", code: "AS", state_code: "18" },
    { label: "Bihar", code: "BR", state_code: "10" },
    { label: "Chandigar", code: "CH", state_code: "4" },
    { label: "Chhattisgarh", code: "CG", state_code: "22" },
    { label: "Dadra and Nagar Haveli", code: "DN", state_code: "26" },
    { label: "Daman and Diu", code: "DD", state_code: "25" },
    { label: "Delhi", code: "DL", state_code: "7" },
    { label: "Goa", code: "GA", state_code: "30" },
    { label: "Gujarat", code: "GJ", state_code: "24" },
    { label: "Haryana", code: "HR", state_code: "6" },
    { label: "Himachal Pradesh", code: "HP", state_code: "2" },
    { label: "Jammu and Kashmir", code: "JK", state_code: "1" },
    { label: "Jharkhand", code: "JH", state_code: "20" },
    { label: "Karnataka", code: "KA", state_code: "29" },
    { label: "Kerala", code: "KL", state_code: "32" },
    { label: "Ladakh", code: "LA", state_code: "38" },
    { label: "Lakshadweep", code: "LD", state_code: "31" },
    { label: "Madhya Pradesh", code: "MP", state_code: "23" },
    { label: "Maharashtra", code: "MH", state_code: "27" },
    { label: "Manipur", code: "MN", state_code: "14" },
    { label: "Meghalaya", code: "ML", state_code: "17" },
    { label: "Mizoram", code: "MZ", state_code: "15" },
    { label: "Nagaland", code: "NL", state_code: "13" },
    { label: "Odisha", code: "OR", state_code: "21" },
    { label: "Other Territory", code: "OT", state_code: "97" },
    { label: "Puducherry", code: "PY", state_code: "34" },
    { label: "Punjab", code: "PB", state_code: "3" },
    { label: "Rajasthan", code: "RJ", state_code: "8" },
    { label: "Sikkim", code: "SK", state_code: "11" },
    { label: "Tamil Nadu", code: "TN", state_code: "33" },
    { label: "Telangana", code: "TS", state_code: "36" },
    { label: "Tripura", code: "TR", state_code: "16" },
    { label: "Uttar Pradesh", code: "UP", state_code: "9" },
    { label: "Uttarakhand", code: "UA", state_code: "5" },
    { label: "West Bengal", code: "WB", state_code: "19" },
    { label: "Foreign Country", code: "FC", state_code: "96" },
]