import Config from '../Config';
import { RestService } from './RestService';

export const ImportService = {
    import: async function(body){
        return RestService.post(`${Config.upload_url}`, {}, body)
        .then(response => response.json())
    },
    importFBA: async function(body){
        return RestService.post(`${Config.upload_fba_url}`, {}, body)
        .then(response => response.json())
    },
    importSmytten: async function(body){
        return RestService.post(`${Config.upload_smytten_url}`, {}, body)
        .then(response => response.json())
    }
};