import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, Tooltip, TableSortLabel, Typography, Checkbox } from '@material-ui/core';

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { rows, order, orderBy, onSelectAllClick, checkboxSelection, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {checkboxSelection ? (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all' }}
              />
            </TableCell>
          ) : null}
          {rows.map(
            row => (
              <TableCell
                style={{ fontSize: "16px" }}
                key={row.id}
                align={row.align ? row.align : (row.numeric ? 'right' : 'center')}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                variant='head'>
                {row.sortable ?
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300} >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)} >
                      <Typography variant="subtitle2">{row.label}</Typography>
                    </TableSortLabel>
                  </Tooltip>
                  : <Typography variant="subtitle2">{row.label}</Typography>
                }
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  rowCount: PropTypes.number,
  checkboxSelection: PropTypes.bool
};

export default EnhancedTableHead;