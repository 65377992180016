import Config from '../Config';
import { RestService } from './RestService';

export const ItemService = {
    getItemList: async function(project_id){
        return RestService.get(`${Config.get_items}${project_id ? `project_id=${project_id}` : ''}`, {})
        .then(response => response.json());
    },
    getActiveItems: async function(project_id){
        return RestService.get(`${Config.get_items}${project_id ? `project_id=${project_id}` : ''}&statuses=1`, {})
        .then(response => response.json());
    },
    create: async function(headers, body){
        return RestService.post(`${Config.create_item}`, headers, body);
        // .then(response => response.json());
    },
    remove: async function(headers, id){
        return RestService.post(`${Config.remove_item}id=${id}`, headers, {});
        // .then(response => response.json());
    },
    disable: async function(headers, id, activate = 0){
        return RestService.post(`${Config.disable_item}id=${id}&activate=${activate}`, headers, {});
        // .then(response => response.json());
    }
};