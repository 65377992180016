import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, DialogContent, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    // fontSize: 11
  },
  content: {
    textAlign: 'center',
    marginBottom: 20,
    height: 75
  },
  '@global': {
    '.MuiDialog-paperWidthXs': {
      maxWidth: 300
    }
  }
}));

export default function ProgressDialog(props) {
  const { open, message } = props;

  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      maxWidth="xs"
      className={classes.root}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          
        }
      }}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle id="simple-dialog-title">
        <Typography variant="subtitle2" className={classes.title} component="div">{message ? message : 'Please Wait...'}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <CircularProgress />
        {/* <img style={{animation: `spin 1s linear infinite`}} width={100} height={100} src={process.env.PUBLIC_URL + '/images/bottle.png'} alt="img"/> */}
      </DialogContent>
    </Dialog>
  );
}

ProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
};
