import React, { Component } from "react";
import { ShipmentService } from "../../Services/ShipmentService";
import { ItemService } from "../../Services/ItemService";
import { toInt, getTimeInIST, format, format_display, formatNumber, format_invoice } from '../../Utils';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import { withStyles } from '@material-ui/core/styles';
import { RowCountOptions } from "../../Config";
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';

import { deepOrange, deepPurple } from '@material-ui/core/colors';

import {
    Paper,
    TableBody,
    TablePagination,
    Table,
    TableCell,
    TableRow,
    Button,
    Dialog as DialogMUI,
    DialogActions,
    DialogContent,
    FormControl,
    Typography,
    Grid,
    Divider,
    TableHead,
    ListItemText,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    DialogContentText,
    Avatar,
    TextField,
    Chip,
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { isEmpty } from "lodash";

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
));


const styles = theme => ({
    appBar: {
        position: 'relative',
        marginBottom: 10
    },
    fab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    tablecell: {
        fontSize: '40pt',
        padding: '5px'
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        width: "80%",
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: '20px',
    },
    table: {
        minWidth: 700
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    formControlAuto: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 300,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),
        minWidth: 200,
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    },
    formControlLeft: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(4),
        },
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    heading: {
        fontWeight: 500,
        color: 'black'
    }
});

class ItemReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            originalData: [],
            current_rows: [],
            numItemsPerPage: 10,
            currentPageNumber: 0,
            totalItems: 0,
            totalPages: 0,
            filter: '',
            show: false,
            showDetails: false,
            columns: [
                { id: 'shipment_identifier', numeric: false, disablePadding: true, label: 'Shipment ID', sortable: true },
                { id: 'invoice_no', numeric: false, disablePadding: true, label: 'Invoice No', sortable: true },
                { id: 'warehouse_name', numeric: false, disablePadding: true, label: 'Warehouse', sortable: true },
                { id: 'fc_name', numeric: false, disablePadding: true, label: 'FC', sortable: true },
                { id: 'interstate', numeric: false, disablePadding: true, label: 'Interstate', sortable: true },
                { id: 'shipment_date', numeric: false, disablePadding: true, label: 'Shipment Date', sortable: true },
                { id: 'case_size', numeric: false, disablePadding: true, label: 'Case Size', sortable: false },
                { id: 'no_of_cases', numeric: false, disablePadding: true, label: 'Total Cases', sortable: false },
                { id: 'total_units', numeric: false, disablePadding: true, label: 'Total Units', sortable: false },
                { id: 'version', numeric: false, disablePadding: true, label: '', sortable: false },
                { id: 'actions', numeric: false, disablePadding: true, label: '', sortable: false },
            ],
            order: 'desc',
            orderBy: 'shipment_date',
            master_items: [],
            startDate: moment().subtract(1, "month"),
            endDate: moment().add(7, "days"),
            selected_item_id: "",
            selected_item_name: "Select Item",
        }
    }

    fetchItemFilter = () => {
        ItemService.getItemList()
            .then((data) => {
                this.setState({ master_items: data.items });
                this.props.isLoading(false);
            })
            .catch(error => {
                console.log(error);
                this.props.isLoading(false);
            });
    }

    componentDidMount() {
        this.props.setTitle('Item Report');
        this.props.isLoading(true);
        this.fetchItemFilter();
        // this.fetchData(0, this.state.numItemsPerPage);
    }

    sortBy = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy }, () => this.fetchData(0, this.state.numItemsPerPage, ''));
    }

    handleChangeRowsPerPage = event => {
        this.setState({ numItemsPerPage: event.target.value }, () => this.fetchData(0, this.state.numItemsPerPage, ''));
    }

    handleChangePage = (event, page) => {
        if (this.state.currentPageNumber > page) {
            this.fetchRow(this.state.currentPageNumber - 1);
        } else {
            this.fetchRow(this.state.currentPageNumber + 1);
        }
    };

    fetchData = (start, numberItems, showLoading = true) => {
        if (showLoading) {
            this.props.isLoading(true);
        }
        let rows = [];
        let sort = this.state.orderBy ? this.state.orderBy : '', direction = this.state.order ? this.state.order : '';
        let item_id = !this.state.selected_item ? null : this.state.selected_item.id;
        let filter = this.state.filter === "" ? null : this.state.filter;

        ShipmentService.getTotalCount(null, null, item_id, '0,1,2', null, filter, moment.utc(this.state.startDate).format(format), moment.utc(this.state.endDate).format(format))
            .then((data) => {
                this.setState({
                    totalItems: toInt(data.count),
                    totalPages: Math.ceil(data.count / this.state.numItemsPerPage) - 1
                });
                if (toInt(data.count) > 0) {
                    ShipmentService.getShipmentsReport(start, numberItems, moment.utc(this.state.startDate).format(format), moment.utc(this.state.endDate).format(format), null, null, null, '0,1,2', item_id, filter, sort, direction)
                        .then((data) => {
                            console.log(data);
                            this.setState({ data: data.shipments, originalData: data.shipments });
                            if (data.shipments) {
                                data.shipments.forEach((shipment) => {
                                    let item = shipment.items.filter(s => s.item_id === item_id)[0];
                                    rows.push(<ItemShipmentDetails master_items={this.state.master_items} interstate={shipment.interstate === 1} shipment={shipment} classes={this.props.classes} key={shipment.id} item={item} showDetails={this.showDetails} />);
                                });
                                this.setState({
                                    current_rows: rows
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);

                        }).finally(() => {
                            if (showLoading) {
                                this.props.isLoading(false);
                            }
                        });
                } else {
                    if (showLoading) {
                        this.props.isLoading(false);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    showDetails = (e, shipment) => {
        let shipment_details = JSON.parse(JSON.stringify(shipment));
        let rows = this.shipmentItemDetailsRows(shipment_details);
        this.setState({ shipment_details: shipment_details, shipment_details_rows: rows });
        this.handleShowDetails();
    }

    handleShowDetails = () => {
        this.setState({ showDetails: true });
    }

    handleCloseDetails = () => {
        this.setState({
            showDetails: false,
            shipment_details: null,
            shipment_details_rows: []
        });
    }

    shipmentItemDetailsRows = (shipment_details) => {
        let items = shipment_details.items;
        return items.filter(i => toInt(i.status) !== -1).map(item => (
            <ItemDisplayDetails key={item.item_id} item={item} version={shipment_details.version} interstate={shipment_details.interstate === 1} master_items={this.state.master_items} classes={this.props.classes} />
        ));
    }

    handleSelect = (eventKey, e) => {
        if (eventKey === 'prev') {
            if ((this.state.currentPageNumber) > 0) {
                this.fetchRow(this.state.currentPageNumber - 1);
            }
        } else if (eventKey === 'next') {
            if ((this.state.currentPageNumber) < this.state.totalPages) {
                this.fetchRow(this.state.currentPageNumber + 1);
            }
        }
    }

    fetchRow = (newCurrentPage) => {
        this.fetchData(newCurrentPage * this.state.numItemsPerPage, this.state.numItemsPerPage);
        this.setState({ currentPageNumber: newCurrentPage });
    }

    handleAddItemToShipment = (item) => {
        // let item = this.state.master_items.filter(i => i.id === e.id)[0];
        this.setState({ selected_item: item }, () => {
            this.fetchData(0, this.state.numItemsPerPage);
        });
    }

    handleChangeStartDate = (date) => {
        console.log(date);
        this.setState({
            startDate: date,
        }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date,
        }, () => this.fetchData(0, this.state.numItemsPerPage));
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home">
                <FormControl variant="outlined" className={classes.formControlAuto} style={{ float: "left", marginLeft: 16 }}>
                    <Autocomplete
                        id="item-selection"
                        disableClearable
                        size='small'
                        options={this.state.master_items}
                        getOptionLabel={option => `${option.name} ${option.fnsku ? `[${option.fnsku}]` : ''} ${option.sku ? `[${option.sku}]` : ''}`}
                        value={this.state.selected_item}
                        onChange={(event, item) => {
                            this.handleAddItemToShipment(item)
                        }}
                        renderInput={params => (
                            <TextField {...params} label="Select Item" variant="outlined" fullWidth />
                        )}
                    />
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        margin="dense"
                        label="From"
                        value={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                        format='DD MMM YYYY'
                        maxDate={this.state.endDate}
                        variant="dialog"
                        inputVariant="outlined"
                        className={classes.formControl}
                        style={{ float: "left", height: "100%", marginTop: "24px" }}
                    />
                    <DatePicker
                        margin="dense"
                        label="To"
                        value={this.state.endDate}
                        onChange={this.handleChangeEndDate}
                        format='DD MMM YYYY'
                        variant="dialog"
                        inputVariant="outlined"
                        minDate={this.state.startDate}
                        className={classes.formControl}
                        style={{ float: "left", height: "100%", marginTop: "24px" }}
                    />
                </MuiPickersUtilsProvider>
                <div className="container">
                    <Paper className={this.props.classes.root} style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px", marginBottom: 75 }}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onRequestSort={this.sortBy}
                                rows={this.state.columns} />
                            {!this.props.loading &&
                                <TableBody>
                                    {this.state.current_rows}
                                </TableBody>
                            }
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={RowCountOptions}
                            component="div"
                            count={this.state.totalItems}
                            rowsPerPage={this.state.numItemsPerPage}
                            page={this.state.currentPageNumber}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                    {this.state.shipment_details &&
                        <DialogMUI
                            fullWidth
                            maxWidth={'lg'}
                            TransitionComponent={Transition}
                            open={this.state.showDetails}
                            onClose={this.handleCloseDetails}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            scroll='paper'>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton color="inherit" onClick={this.handleCloseDetails} aria-label="Close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" color="inherit" className={classes.flex}>
                                        Shipment Details <Chip label="New Flow" size="small" color="secondary" style={{ marginLeft: 16 }} />
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" component={'span'}>
                                    <Grid container spacing={1} style={{ flexGrow: 1, marginLeft: 10, marginBottom: 15 }} justifyContent="flex-start" alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" className={classes.heading}>Shipment ID</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {this.state.shipment_details.shipment_identifier}
                                        </Grid>
                                        <Grid item xs={3} />
                                        <Grid item xs={3} >
                                            {this.state.shipment_details.interstate === 1 ? <Avatar className={classes.orangeAvatar}>INT</Avatar> : <Avatar className={classes.purpleAvatar}>LOC</Avatar>}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" className={classes.heading}>Invoice No</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {this.state.shipment_details.invoice_no}
                                        </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" className={classes.heading}>Shipment Date</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {getTimeInIST(this.state.shipment_details.shipment_date).format(format_invoice)}
                                        </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" className={classes.heading}>Warehouse</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {this.state.shipment_details.warehouse_name}
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            {!isEmpty(this.state.shipment_details.cartons) ? (
                                                <>
                                                    <div>
                                                        <Typography variant="subtitle1" component="span" className={classes.heading} style={{ marginLeft: 8, marginRight: 16 }}>No of Cartons:</Typography>{this.state.shipment_details.cartons.length}
                                                    </div>
                                                    <div>
                                                        <Typography variant="subtitle1" component="span" className={classes.heading} style={{ marginLeft: 8, marginRight: 16 }}>No of Units:</Typography>{this.state.shipment_details.items.reduce((sum, next) => sum + next.units, 0)}
                                                    </div>
                                                </>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" className={classes.heading}>Fulfillment Center</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {this.state.shipment_details.fc_name}
                                        </Grid>
                                        <Grid item xs={6} />
                                        {this.state.shipment_details.vehicle_no &&
                                            <Grid item xs={3}>
                                                <Typography variant="subtitle1" className={classes.heading}>Vehicle No</Typography>
                                            </Grid>
                                        }
                                        {this.state.shipment_details.vehicle_no &&
                                            <Grid item xs={3}>
                                                {this.state.shipment_details.vehicle_no}
                                            </Grid>
                                        }
                                    </Grid>
                                    <Divider />
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell>Item</CustomTableCell>
                                                <CustomTableCell align="left" padding='none'>FNSKU</CustomTableCell>
                                                <CustomTableCell align="right" padding='none'>HSN Code</CustomTableCell>
                                                <CustomTableCell align="right" padding='none'>Rate</CustomTableCell>
                                                {this.state.shipment_details.version === 'v1' ? (
                                                    <>
                                                        <CustomTableCell align="right" padding='none'>Case size</CustomTableCell>
                                                        <CustomTableCell align="right" padding='none'>No of Cases</CustomTableCell>
                                                        <CustomTableCell align="right" style={{ paddingLeft: 5 }}>Weight of each Case</CustomTableCell>
                                                        <CustomTableCell align="right" style={{ paddingLeft: 5 }}>Dimension</CustomTableCell>
                                                    </>) : (
                                                    <>
                                                        <CustomTableCell align="right" padding='none' style={{ paddingRight: 8 }}>Units</CustomTableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.shipment_details_rows}
                                        </TableBody>
                                    </Table>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDetails} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogMUI>
                    }
                </div>
            </div>
        );
    }

}

class ItemDisplayDetails extends Component {

    render() {
        const { version, item, interstate, classes } = this.props
        const item_details = this.props.master_items.filter(i => i.id === item.item_id)[0];
        return (
            <TableRow key={item.item_id} className={classes.row}>
                <CustomTableCell style={{ paddingRight: 5 }}>
                    <ListItemText
                        primary={item.item_name}
                        secondary={interstate ? `IGST : ${item.igst}` : `CGST : ${item.cgst} SGST : ${item.sgst}`}
                        style={{ width: 140, paddingRight: 5 }} />
                </CustomTableCell>
                <CustomTableCell align="left" padding='none'>{item_details.fnsku}</CustomTableCell>
                <CustomTableCell align="right" padding='none'>{item_details.hsn_code}</CustomTableCell>
                <CustomTableCell align="right" padding='none'>{formatNumber(item.rate)}</CustomTableCell>
                {version === 'v1' ? (
                    <>
                        <CustomTableCell align="right" padding='none'>{item.case_size}</CustomTableCell>
                        <CustomTableCell align="right" padding='none'>{item.no_of_cases}</CustomTableCell>
                        <CustomTableCell align="right" style={{ paddingLeft: 5 }}>{item.weight}</CustomTableCell>
                        <CustomTableCell align="right" style={{ paddingLeft: 5 }}>{item.dimension}</CustomTableCell>
                    </>
                ) : (
                    <>
                        <CustomTableCell align="right" padding='none' style={{ paddingRight: 8 }}>{item.units}</CustomTableCell>
                    </>
                )}
            </TableRow>
        );
    }
}

class ItemShipmentDetails extends Component {

    render() {
        const item = this.props.item;
        const shipment = this.props.shipment;
        const classes = this.props.classes;
        return (
            <TableRow key={item.item_id} className={classes.row}>
                <CustomTableCell align="left" style={{ paddingRight: 5 }}>{shipment.shipment_identifier}</CustomTableCell>
                <CustomTableCell style={{ paddingRight: 5 }}>{shipment.invoice_no}</CustomTableCell>
                <CustomTableCell align="center" padding='none'>{shipment.warehouse_name}</CustomTableCell>
                <CustomTableCell align="center" padding='none'>{shipment.fc_name}</CustomTableCell>
                <CustomTableCell align="center" padding='none'>
                    <Typography variant="body1" color="textSecondary">{toInt(shipment.interstate) === 1 ? "Interstate" : "Local"}</Typography>
                    {shipment.vehicle_no && <Typography variant="subtitle2" color="textSecondary">{shipment.vehicle_no}</Typography>}
                </CustomTableCell>
                <CustomTableCell align="center" padding='none'>{getTimeInIST(shipment.shipment_date).format(format_display)}</CustomTableCell>
                {/* <CustomTableCell align="right" padding='none'>{formatNumber(item.rate)}</CustomTableCell> */}
                <CustomTableCell align="center" padding='none'>{item.case_size}</CustomTableCell>
                <CustomTableCell align="center" padding='none'>{item.no_of_cases}</CustomTableCell>
                <CustomTableCell align="right" style={{ paddingLeft: 5 }}>{toInt(item.no_of_cases) * toInt(item.case_size)}</CustomTableCell>
                <CustomTableCell align="right" style={{ paddingLeft: 5 }}>
                    {shipment.version === 'v2' ? (
                        <Chip color="primary" size="small" label="New Flow" />
                    ) : null}
                </CustomTableCell>
                <CustomTableCell align="right" style={{ paddingLeft: 5 }}>
                    <Button variant="outlined" style={{ marginRight: 10 }} onClick={(e) => this.props.showDetails(e, shipment)}>
                        View Details
                    </Button>
                </CustomTableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(ItemReport);