import React, { Component } from "react";
import { Glyphicon, OverlayTrigger, Form } from 'react-bootstrap';
import { tooltip } from '../Utils';
import { FCService } from "../Services/FCService";
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import {
    Paper,
    Typography,
    Button,
    TextField,
    Modal,
    Divider,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import SelectDropdown from "../components/SelectDropdown";
import { STATES } from "../Constants";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflow: 'scroll',
        height: '80%',
        width: '50%'
    };
}

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        overflowX: 'auto',
        display: 'block'
    },
});

class FulfillmentCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            originalData: [],
            rows: [],
            show: false,
            new_fc: { name: "", address1: "", address2: "", address3: "", address4: "", gstin: "" }
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.edit = this.edit.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    confirmSave = () => {
        this.handleClose();
        this.props.closeConfirmDialog();
        this.createFCAPI(this.state.new_fc);
    }

    createModifyFC = (e) => {
        e.preventDefault();
        if (this.state.new_fc.name && this.state.new_fc.address1 && this.state.new_fc.address2 && this.state.new_fc.gstin) {
            this.props.showConfirmDialog("Confirm?", undefined, this.confirmSave);
        } else {
            this.props.showSnackBar('Please fill all the details and continue', 'error');
        }
    }

    createFCAPI(new_fc) {
        let body = new_fc;
        this.props.isLoading(true);
        let headers = { user_id: this.props.user.id };
        FCService.create(headers, body)
            .then((response) => {
                if (response.status === 409) {
                    this.props.showSnackBar(`${new_fc.name} already exists.`, 'error');
                } else {
                    this.setState({
                        new_fc: { name: "", address1: "", address2: "", address3: "", address4: "", gstin: "" }
                    });
                    this.fetchData();
                    if (this.state.show) this.handleClose();
                    this.props.isLoading(false);
                    this.props.showSnackBar(`${new_fc.id ? 'Saved' : 'Created'} FC: ${new_fc.name}`, 'success');
                }
            })
            .catch(() => {
                this.props.isLoading(false);
                this.props.showConfirmDialog(`Failed to create fc : ${new_fc.name}. Please try again.`, undefined, undefined);
            });

    }

    edit(e, fc) {
        e.preventDefault();
        if (fc) {
            this.setState({ new_fc: { id: fc.id, name: fc.name, address1: fc.address1, address2: fc.address2, address3: fc.address3, address4: fc.address4, gstin: fc.gstin } }, function () {
                this.handleShow();
            });
        } else {
            this.setState({ show: true });
        }
    }

    componentDidMount() {
        this.props.setTitle("Fulfillment Centers");
        this.fetchData();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_fc: { name: "", address1: "", address2: "", address3: "", address4: "", gstin: "" }
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange = (e) => {
        let change = this.state.new_fc;
        change[e.target.name] = e.target.value;
        e.preventDefault();
        this.setState(change);
    }


    fetchData() {
        this.props.isLoading(true);
        FCService.getFCs()
            .then((data) => {
                this.setState({ data: data.fcs, originalData: data.fcs });
                this.props.setTitle(`Fulfillment Centers (${data.fcs.length})`);
                this.filterCriteria();
                this.props.isLoading(false);
            })
            .catch(error => {
                console.error(error);
                this.props.isLoading(false);
            });
    }

    updateRows(data) {
        let rows = [];
        data.forEach((fc) => {
            rows.push(<FCDetails key={fc.id} fc={fc} remove={this.remove} edit={this.edit} />);
        });
        this.setState({ rows: rows });
    }

    filterCriteria(e) {
        this.props.isLoading(true);
        if (e)
            e.preventDefault();
        const searchText = this.refs.inputText.value;
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
        if (this.refs.inputText.value.length === 0) {
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching FC'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    remove = (e, id) => {
        e.preventDefault();
        if (id) {
            this.props.showConfirmDialog("This will not effect any of the existing Shipments.", "Confirm removal of FC?", () => {
                let headers = { user_id: this.props.user.id };
                FCService.remove(headers, id)
                    .then(() => {
                        this.fetchData();
                        this.props.closeConfirmDialog();
                        this.props.showSnackBar(`Removed FC`, 'success');
                    })
                    .catch((error) => {
                        this.props.showSnackBar(`Failed to remove FC. Please try again.`, 'error');
                    });
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home">
                {/* <h3 style={{marginBottom: '5px'}}>Fulfillment Centers <Badge>{this.state.data.length}</Badge></h3> */}
                <div style={{ marginTop: 40, marginBottom: 80 }}>
                    <Paper style={{ width: "100%", paddingTop: '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: '10px' }}>
                        <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{ width: "100%", marginBottom: "10px", padding: "5px", borderColor: "red" }} autoComplete="off"></input>
                        </form>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Address1</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Address2</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Address3</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">State</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">GST</Typography></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.show}
                    onClose={this.handleClose}>
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            {this.state.new_fc.id ? 'Modify FC' : 'Create FC'}
                        </Typography>
                        <Divider />
                        {this.fcForm(this.createModifyFC, "Save")}
                    </div>
                </Modal>
                <OverlayTrigger placement="top" overlay={tooltip("Add FC")} >
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </OverlayTrigger>
            </div>
        );
    }

    fcForm = (submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="FC Name"
                placeholder="FC Name"
                name="name"
                defaultValue={this.state.new_fc.name}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
            />
            <TextField
                id="address1"
                label="Address1"
                defaultValue={this.state.new_fc.address1}
                placeholder="Address1"
                name="address1"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                required
                multiline
                rows="2"
                rowsMax="2"
            />
            <TextField
                id="address2"
                label="Address2"
                defaultValue={this.state.new_fc.address2}
                placeholder="Address2"
                name="address2"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                multiline
                rows="2"
                rowsMax="2"
                required
            />
            <TextField
                id="address3"
                label="Address3"
                defaultValue={this.state.new_fc.address3}
                placeholder="Address3"
                name="address3"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                multiline
                rows="2"
                rowsMax="2"
                fullWidth
            />
            <SelectDropdown
                required
                //error={this.state.error_fields.fc_id} 
                fullWidth={true}
                label="State"
                hint="State"
                name="address4"
                options={STATES.map(s => ({ ...s, name: `${s.label} [GST Code: ${s.state_code}]`, id: s.label }))}
                value={this.state.new_fc.address4}
                onChange={this.handleChange} />
            {/* <TextField
                id="address4"
                label="State"
                defaultValue={this.state.new_fc.address4}
                placeholder="State"
                name="address4"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                multiline
                rows="2"
                rowsMax="2"
                fullWidth
                /> */}
            <TextField
                id="gstin"
                label="GSTIN"
                defaultValue={this.state.new_fc.gstin}
                placeholder="GSTIN"
                name="gstin"
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                required
            />
            <br />
            <div style={{ marginTop: "10px", marginLeft: "8px" }}>
                <Button variant="outlined" size="medium" color="primary" type="submit" onClick={submitFunction} style={{ marginRight: "10px" }}>{buttonText}</Button>
                {!this.state.show ? (<Button type="submit" variant="contained" size="medium" color="primary" onClick={this.export} style={{ marginRight: "10px" }}>Export</Button>) : ""}
                {this.state.show ? (<Button variant="outlined" size="medium" color="primary" onClick={this.handleClose}>Close</Button>) : ""}
            </div>
        </Form>
    )

}

class FCDetails extends Component {

    render() {
        const fc = this.props.fc;
        return (
            <TableRow key={fc.id}>
                <TableCell component="th" scope="row">
                    {fc.name}
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: 'pre-wrap' }}>{fc.address1 || '-'}</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'pre-wrap' }}>{fc.address2 || '-'}</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'pre-wrap' }}>{fc.address3 || '-'}</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'pre-wrap' }}>{fc.address4 || '-'}</TableCell>
                <TableCell align="center">{fc.gstin}</TableCell>
                <TableCell align="right" style={{ paddingLeft: 8, paddingRight: 8, width: 62 }}>
                    <OverlayTrigger placement="bottom" overlay={tooltip("Remove FC")}>
                        <Glyphicon style={{ marginRight: "10px" }} glyph="trash" onClick={(e) => this.props.remove(e, fc.id)} />
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltip("Edit FC")} >
                        <Glyphicon style={{ marginRight: "10px" }} glyph="pencil" onClick={(e) => this.props.edit(e, fc)} />
                    </OverlayTrigger>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(FulfillmentCenter);