import React from "react";
import { Route, Switch } from "react-router-dom";
// import Home from "./HomePage/home";
import Login from "./LoginPage/Login";
import Item from "./ItemPage/Item";
import FulfillmentCenter from "./FulfillmentCenterPage/FulfillmentCenter";
import WareHouse from "./WarehousePage/Warehouse";
import Employee from "./EmployeePage/Employee";
import Shipment from "./ShipmentPage/Shipment";
import ShipmentV2 from "./ShipmentPageV2";
import InvoiceTemplate from './Tools/InvoiceTemplate';
import AmazonShippingLabelSplitter from './Tools/AmazonShippingLabelSplitter';
import NotFound from "./NotFound/NotFound";

//Reports 
import ItemReport from './Reports/ItemReportPage/ItemReport';
// import Schedule from './Reports/SchedulePage/Schedule';

// Components
import AppliedRoute from "./components/AppliedRoute";
import ShipmentPlanner from "./ShipmentPlannerPage/ShipmentPlanner";
import ShipmentPlannerV2 from "./ShipmentPlannerPageV2";
import Returns from "./ReturnsPage/Returns";
import ReturnTemplate from "./Tools/ReturnTemplate";

// import StoreTransaction from "./StoreTransactionPage/StoreTransaction";
// import InventoryTransactions from "./Inventory/InventoryTransactions";
// import InventoryStockSummary from "./Inventory/InventoryStockSummary";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path={`/login`} exact component={Login} props={childProps} />

    <AppliedRoute path={`/`} exact component={ShipmentV2} props={childProps} />
    <AppliedRoute path={`/shipments/legacy`} exact component={Shipment} props={childProps} />
    <AppliedRoute path={`/returns`} exact component={Returns} props={childProps} />
    {/* <AppliedRoute path={`/transaction/jobstatus`} exact component={Import} props={childProps} /> */}
    {/* <AppliedRoute path={`/transaction/store`} exact component={Export} props={childProps} /> */}
    {/* <AppliedRoute path={`/shipments`} exact component={Shipment} props={childProps} /> */}
    <AppliedRoute path={`/items`} exact component={Item} props={childProps} />
    <AppliedRoute path={`/employees`} exact component={Employee} props={childProps} />
    <AppliedRoute path={`/planner/legacy`} exact component={ShipmentPlanner} props={childProps} />
    <AppliedRoute path={`/planner`} exact component={ShipmentPlannerV2} props={childProps} />

    {/* Reports */}
    {/* {/* <AppliedRoute path={`/report/schedules`} exact component={Schedule} props={childProps} /> */}
    <AppliedRoute path={`/report/itemreport`} exact component={ItemReport} props={childProps} />
    <AppliedRoute path={`/master/fc`} exact component={FulfillmentCenter} props={childProps} />
    <AppliedRoute path={`/master/warehouse`} exact component={WareHouse} props={childProps} />

    <AppliedRoute path={`/tools/shipping-label-splitter`} exact component={AmazonShippingLabelSplitter} props={childProps} />
    <AppliedRoute path={`/tools/invoice-template`} exact component={InvoiceTemplate} props={childProps} />
    <AppliedRoute path={`/tools/return-template`} exact component={ReturnTemplate} props={childProps} />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;