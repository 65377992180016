import React, { Component } from "react";
import {Glyphicon, OverlayTrigger, Form} from 'react-bootstrap';
import {tooltip} from '../Utils';
import { WarehouseService } from "../Services/WarehouseService";
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import SelectDropdown from "../components/SelectDropdown";
import { STATES } from "../Constants";

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    table: {
        overflowX: 'auto',
        display: 'block'
    },
    formControl: {
        marginTop: theme.spacing(1),
    }
  });

class WareHouse extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        show: false,
        new_warehouse: { name: "", address1: "", address2: "", city: "", state: "", pin: "", district: "", gst: ""}
      }

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.edit = this.edit.bind(this);
      this.updateRows = this.updateRows.bind(this);
    }

    confirmSave = () => {
        this.handleClose();
        this.props.closeConfirmDialog();
        this.createWarehouseAPI(this.state.new_warehouse);
    }

    createModifyWarehouse = (e) => {
        e.preventDefault();
        if(this.state.new_warehouse.name && this.state.new_warehouse.address1 && this.state.new_warehouse.city 
            && this.state.new_warehouse.state && this.state.new_warehouse.pin && this.state.new_warehouse.gst){
            this.props.showConfirmDialog("Confirm?", undefined, this.confirmSave);
        } else {
            this.props.showSnackBar('Please fill all the details and continue', 'error');
        }
    }

    createWarehouseAPI(new_warehouse){
        let body = new_warehouse;
        this.props.isLoading(true);
        let headers = {user_id : this.props.user.id };
        WarehouseService.create(headers, body)
        .then((response) => {
            if(response.status === 409){
                this.props.showSnackBar(`${new_warehouse.name} already exists.`, 'error');
            } else {
                this.setState({
                    new_warehouse: { name: "", address1: "", address2: "", city: "", state: "", pin: "", district: "", gstin: ""}
                });
                this.fetchData();
                if(this.state.show) this.handleClose();
                this.props.showSnackBar(`${new_warehouse.id ? 'Saved' : 'Created'} Warehouse: ${new_warehouse.name}`, 'success');
            }
            this.props.isLoading(false);
        })
        .catch(() => {
            this.props.isLoading(false);
            this.props.showConfirmDialog(`Failed to create warehouse : ${new_warehouse.name}. Please try again.`, undefined, undefined);
        });
        
    }

    edit(e, warehouse){
        e.preventDefault();
        if(warehouse){
            this.setState({new_warehouse: {id: warehouse.id, name: warehouse.name, address1: warehouse.address1, address2: warehouse.address2, city: warehouse.city, state: warehouse.state, pin: warehouse.pin, district: warehouse.district, gst: warehouse.gst}}, function () {
                this.handleShow();
            });
        } else {
            this.setState({show: true});
        }
    }

    componentDidMount(){
        this.fetchData();
        this.props.setTitle("Warehouses");
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_warehouse: { name: "", address1: "", address2: "", city: "", state: "", pin: "", district: "", gst: ""}
        });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange = (e) => {
        let change = this.state.new_warehouse;
        change[e.target.name] = e.target.value;
        e.preventDefault();
        this.setState(change);        
    }


    fetchData(){
        this.props.isLoading(true);
        WarehouseService.getWarehouses()
        .then((data) => {
            this.setState({data: data.warehouses,  originalData: data.warehouses});
            this.props.setTitle(`Warehouses (${data.warehouses.length})`);
            this.filterCriteria();
            this.props.isLoading(false);
        })
        .catch(error => {
            console.error(error);
            this.props.isLoading(false);
        });
    }

    updateRows(data){
        let rows = [];
        data.forEach((warehouse) => {
            rows.push(<WarehouseDetails key={warehouse.id} warehouse={warehouse} remove={this.remove} edit={this.edit}/>);
          });
        this.setState({rows: rows});
    }

    filterCriteria(e){
        this.props.isLoading(true);
        if(e)
            e.preventDefault();
        const searchText = this.refs.inputText.value;
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()) || data.city.toLowerCase().includes(searchText.toLowerCase()) || data.state.toLowerCase().includes(searchText.toLowerCase()) || data.pin.toLowerCase().includes(searchText.toLowerCase()));
        if(this.refs.inputText.value.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Warehouse'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    remove = (e, id) => {
        e.preventDefault();
        if(id) {
            this.props.showConfirmDialog("This will not effect any of the existing Shipments.", "Confirm removal of Warehouse?", () => {
                let headers = {user_id : this.props.user.id };
                WarehouseService.remove(headers, id)
                .then(() =>{
                    this.fetchData();
                    this.props.closeConfirmDialog();
                    this.props.showSnackBar(`Removed Warehouse`, 'success');
                })
                .catch((error) => {
                    this.props.showSnackBar(`Failed to remove Warehouse. Please try again.`, 'error');
                });
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="Home">
                {/* <h3 style={{marginBottom: '5px'}}>Warehouses <Badge>{this.state.data.length}</Badge></h3> */}
                <div style={{marginTop: 40}}>
                    <Paper style={{width: "100%", paddingTop : '15px', paddingLeft: "5px", paddingRight: "5px", marginTop: '10px'}}>
                        <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form>
                        <div className={classes.table}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Address1</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Address2</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">City</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">District</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">State</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">Pin</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="subtitle1">GST</Typography></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.rows}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </div>
                <Dialog
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.show} 
                    onClose={this.handleClose}
                    disableEnforceFocus
                    fullWidth={true}
                    maxWidth='sm'>
                    <DialogTitle id="alert-dialog-title" style={{paddingBottom: 5}}>{ this.state.new_warehouse.id ? 'Modify Warehouse' : 'Create Warehouse' }</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.warehouseForm()}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" size="medium" color="primary" type="submit" onClick={this.createModifyWarehouse} style={{marginRight: "10px"}}>Save</Button>
                        {this.state.show ? (<Button variant="outlined" size="medium" color="primary" onClick={this.handleClose}>Close</Button>): ""}
                    </DialogActions>
                </Dialog>
                <OverlayTrigger placement="top" overlay={tooltip("Add Warehouse")} >
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </OverlayTrigger>
            </div>
        );
    }

    warehouseForm = (submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <TextField
                        id="name"
                        label="Warehouse Name"
                        placeholder="Warehouse Name"
                        name="name"
                        defaultValue={this.state.new_warehouse.name}
                        className={this.props.classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        required
                        fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TextField
                            id="address1"
                            label="Address1"
                            defaultValue={this.state.new_warehouse.address1}
                            placeholder="Address1"
                            name="address1"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            fullWidth
                            required
                            />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TextField
                            id="address2"
                            label="Address2"
                            defaultValue={this.state.new_warehouse.address2}
                            placeholder="Address2"
                            name="address2"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            id="city"
                            label="City"
                            defaultValue={this.state.new_warehouse.city}
                            placeholder="City"
                            name="city"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            required
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            id="district"
                            label="District"
                            defaultValue={this.state.new_warehouse.district}
                            placeholder="District"
                            name="district"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div style={{marginTop: 8}}>
                        <SelectDropdown 
                            required 
                            //error={this.state.error_fields.fc_id} 
                            fullWidth={true}
                            label="State" 
                            hint="State" 
                            name="state" 
                            options={STATES.map(s => ({...s, name: `${s.label} [GST Code: ${s.state_code}]`, id: s.label}))} 
                            value={this.state.new_warehouse.state} 
                            onChange={this.handleChange} />
                        </div>
                        {/* <TextField
                            id="state"
                            label="State"
                            defaultValue={this.state.new_warehouse.state}
                            placeholder="State"
                            name="state"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            required
                            fullWidth
                            /> */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            id="pin"
                            label="Pin"
                            defaultValue={this.state.new_warehouse.pin}
                            placeholder="Pin"
                            name="pin"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            required
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            id="gst"
                            label="GST"
                            defaultValue={this.state.new_warehouse.gst}
                            placeholder="GST"
                            name="gst"
                            className={this.props.classes.textField}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            fullWidth
                            required
                            />
                    </Grid>
            </Grid>
        </Form>
    )

}

class WarehouseDetails extends Component {

    render(){
      const warehouse = this.props.warehouse;
      return (
        <TableRow key={warehouse.id}>
            <TableCell component="th" scope="row">
                {warehouse.name}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.address1 || "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.address2 || "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.city || "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.district || "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.state || "-"}</TableCell>
            <TableCell align="center" style={{whiteSpace: 'pre-wrap'}}>{warehouse.pin || "-"}</TableCell>
            <TableCell align="center">{warehouse.gst}</TableCell>
            <TableCell align="right" style={{paddingLeft: 8, paddingRight: 8, width: 62}}>
                <OverlayTrigger placement="bottom" overlay={tooltip("Remove Warehouse")}>
                    <Glyphicon style={{marginRight: "10px"}} glyph="trash" onClick={(e) => this.props.remove(e, warehouse.id)}/>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={tooltip("Edit Warehouse")} >
                    <Glyphicon style={{marginRight: "10px"}} glyph="pencil" onClick={(e) => this.props.edit(e, warehouse)}/>
                </OverlayTrigger>
            </TableCell>
        </TableRow>
      );
    }
}

export default withStyles(styles)(WareHouse);