import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import MyDialog from '../../components/MyDialog'

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const CartonDetails = ({ cartons: prevCartons = [], open, loading = false, onClose }) => {

    const [cartons, setCartons] = useState([])

    useEffect(() => {
        setCartons(prevCartons)
    }, [prevCartons])

    return (
        <MyDialog
            maxWidth={"sm"}
            content={
                <Table size="small">
                    <TableHead>
                        <Titles />
                    </TableHead>
                    <TableBody>
                        {cartons.map((carton, index) => (
                            <CartonRow
                                key={index}
                                index={index}
                                carton={carton}
                            />
                        ))}
                    </TableBody>
                </Table>
            }
            title='Carton Details'
            open={open}
            loading={loading}
            close={onClose} />
    )
}

const Titles = () => (
    <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Weight</TableCell>
        <TableCell align='right'>Dimensions</TableCell>
    </TableRow>
)


const CartonRow = ({ index, carton, onChange, onRemove }) => {
    return (
        <StyledTableRow>
            <TableCell size='small'>{index + 1}</TableCell>
            <TableCell size='small'>
                {carton.weight} KG
            </TableCell>
            <TableCell size='small' align='right'>
                {carton.dimension} cm
            </TableCell>
        </StyledTableRow>
    )
}

export default CartonDetails