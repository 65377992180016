import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
// import { Navbar, NavItem, Nav, NavDropdown} from "react-bootstrap";
// import logo from './logo.svg';
import './css/App.css';
import Routes from "./routes";
// import { LinkContainer } from "react-router-bootstrap";

// import { css } from 'react-emotion';
// import { CircleLoader } from 'react-spinners';
import { ROLES } from './Roles';
import { UserService } from './Services/UserService';

import MySnackbarContent from './components/SnackBar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StoreIcon from '@material-ui/icons/Store';
import LocalMallIcon from '@material-ui/icons/LocalMall';
// import AssessmentIcon from '@material-ui/icons/Assessment';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
// import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import LabelIcon from '@material-ui/icons/Label';
import ProgressDialog from './components/ProgressDialog';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import WebIcon from '@material-ui/icons/Web';
import PeopleIcon from '@material-ui/icons/People';
import {
  withStyles,
  Icon,
  Snackbar,
  Dialog as DialogMUI,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  CssBaseline,
  IconButton,
  Fade,
  LinearProgress,
  Container,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';

// const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: red;
// `;

const drawerWidth = 300;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionDesktop: {
    display: 'flex',
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  accountCircle: {
    marginRight: theme.spacing(3)
  },
  version: {
    backgroundColor: theme.palette.primary.light,
  }
});

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      loading: false,
      latitude: null,
      longitude: null,
      alertMessages: [],
      show: false,
      isAlertVisible: false,
      modules_enabled: {
        customer_app_options: false,
        inventory_management: false,
        b2c_module: true,
        b2b_module: true,
        promotions_module: true
      },
      showStore: false,
      showPromotions: false,
      sb: {
        open: false,
        autohide: 6000,
      },
      openDialog: false,
      open: false,
      title: "",
      progressOpen: false,
      progressMessage: null,
    };

    this.updateUser = this.updateUser.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.addAlertMessage = this.addAlertMessage.bind(this);
    this.alertPopover = this.alertPopover.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user: user });
    if (user == null) {
      this.props.history.push("/login");
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  closeConfirmDialog = () => {
    this.setState({ openDialog: false });
  }

  handleSBClose = () => {
    this.setState(prevState => ({
      sb: {
        ...prevState.sb,
        open: false,
        message: '',
        variant: 'info'
      }
    }));
  }

  showSnackBar = (message, variant) => {
    this.setState(prevState => ({
      sb: {
        ...prevState.sb,
        open: true,
        message: message,
        variant: variant
      }
    }));
  }

  showConfirmDialog = (dialog_message, dialog_title, confirmAction) => {
    this.setState({
      dialog_message: dialog_message,
      dialog_title: dialog_title,
      openDialog: true,
      confirmAction: confirmAction
    });
  }

  setTitle = (message) => {
    this.setState({
      title: message
    });
  }

  alertPopover() {
    if (this.state.isAlertVisible) {
      this.handleShow();
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  async componentDidMount() {
    try {
      if (this.state.user !== null) {
        this.userHasAuthenticated(true);
      }
      if (this.state.user) {
        let body = {
          mobile_no: this.state.user.mobile_no,
          code: this.state.user.code
        };
        UserService.authenticate(body)
          .then((data) => {
            if (data.id && data.status === 1) {
              this.updateUser(data);
              localStorage.setItem('user', JSON.stringify(data));
              this.userHasAuthenticated(true);
              this.props.history.push(this.props.location.pathname);


              if (data['app-version'] && data['app-version'] !== global.appVersion) {
                this.showSnackBar('New App Update Available. Please click Reload or CTRL+SHIFT+R', 'info', 'RELOAD', () => {
                  window.location.reload(true)
                })
              }
            } else {
              this.props.history.push("/login");
            }
          })
          .catch((error) => {
            this.props.history.push("/login");
          });
      }
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  addAlertMessage(alertMessages) {
    this.setState(prevState => ({
      alertMessages: [...prevState.alertMessages, alertMessages]
    }));
  }

  isLoading(loading) {
    this.setState({ loading: loading });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = event => {
    this.userHasAuthenticated(false);
    localStorage.removeItem('user');
    this.props.history.push("/login");
  }

  updateUser(user) {
    this.setState({ user: user });
  }

  showProgressDialog = (message = null) => {
    this.setState({
      progressOpen: true,
      progressMessage: message
    });
  }

  updateProgressMessage = (message = null) => {
    this.setState({
      progressMessage: message
    });
  }

  closeProgressDialog = () => {
    this.setState({
      progressOpen: false,
      progressMessage: null
    });
  }

  render() {

    const { classes } = this.props;
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
      updateUser: this.updateUser,
      isLoading: this.isLoading,
      loading: this.state.loading,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      addAlertMessage: this.addAlertMessage,
      modules_enabled: this.state.modules_enabled,
      showStore: this.state.showStore,
      showPromotions: this.state.showPromotions,
      showSnackBar: this.showSnackBar,
      showConfirmDialog: this.showConfirmDialog,
      closeConfirmDialog: this.closeConfirmDialog,
      setTitle: this.setTitle,
      showProgressDialog: this.showProgressDialog,
      updateProgressMessage: this.updateProgressMessage,
      closeProgressDialog: this.closeProgressDialog,
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <div className="root">
          <Fade
            in={this.state.loading}
            style={{
              transitionDelay: this.state.loading ? '50ms' : '0ms',
            }}
            unmountOnExit
          >
            <LinearProgress id="test" style={{ height: 5, position: "fixed", top: 64, width: "98%" }} color="secondary" />
          </Fade>
          <CssBaseline />
          <AppBar position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: this.state.open,
            })}
          >
            <Toolbar disableGutters={!this.state.open}>
              {this.state.isAuthenticated &&
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              }
              {!this.state.open &&
                <Typography variant="h6" color="inherit" className="grow">
                  {this.state.title}
                </Typography>
              }
              {this.state.isAuthenticated &&
                <Button color="inherit" className={classes.sectionMobile} style={{ position: "absolute", right: 130 }}>{this.state.user.name}</Button>
              }
              {this.state.isAuthenticated ?
                <Button color="inherit" style={{ position: "absolute", right: 20 }} onClick={this.handleLogout}>LogOut</Button>
                :
                <Button color="inherit" style={{ position: "absolute", right: 20, color: "white" }}><Link to="/login" style={{ color: "inherit", textDecoration: "none" }}>Login</Link></Button>
              }
            </Toolbar>
          </AppBar>
          {this.state.isAuthenticated &&
            <Drawer
              variant="temporary"
              anchor="left"
              className={classNames(classes.drawer, {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
              })}
              classes={{
                paper: classNames({
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open,
                }),
              }}
              open={this.state.open}
              onClick={this.handleDrawerClose}
            >
              <div>
                <div className={classes.toolbar}>
                  <IconButton onClick={this.handleDrawerClose}>
                    {/* <AccountCircleIcon className={classes.accountCircle}/> */}
                    {this.state.isAuthenticated &&
                      <section className={classes.sectionDesktop}>{this.state.user.name}</section>
                    }
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <div style={{ height: "86vh", overflow: "auto" }}>
                  <Divider />
                  {/* {
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <ListItem button key="Dashboard">
                      <ListItemIcon><HomeIcon /></ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                  </Link>
              } */}
                  {this.state.user.roles.includes(ROLES.ADMIN) &&
                    <Link to="/items" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Items">
                        <ListItemIcon><EventSeatIcon /></ListItemIcon>
                        <ListItemText primary="Items" />
                      </ListItem>
                    </Link>
                  }
                  {(this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.STORE_MANAGER)) &&
                    <Link to="/" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Shipments">
                        <ListItemIcon><AssignmentIcon /></ListItemIcon>
                        <ListItemText primary="Shipments" />
                      </ListItem>
                    </Link>
                  }
                  {/* {(this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.STORE_MANAGER)) &&
                    <Link to="/shipments/v2" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Shipments">
                        <ListItemIcon><AssignmentIcon /></ListItemIcon>
                        <ListItemText primary="Shipments v2" />
                      </ListItem>
                    </Link>
                  } */}
                  {(this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.STORE_MANAGER)) &&
                    <Link to="/returns" style={{ textDecoration: 'none' }}>
                      <ListItem button key="Returns/Transfers">
                        <ListItemIcon><TransferWithinAStationIcon /></ListItemIcon>
                        <ListItemText primary="Returns/Transfers" />
                      </ListItem>
                    </Link>
                  }
                  {/* { (this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.STORE_MANAGER)) &&
                  <Link to="/transfer" style={{ textDecoration: 'none' }}>
                    <ListItem button key="Manage Inter FC Transfers">
                      <ListItemIcon><TransferWithinAStationIcon /></ListItemIcon>
                      <ListItemText primary="Manage Inter FC Transfers" />
                    </ListItem>
                  </Link>
              } */}
                  {this.state.user.roles.includes(ROLES.ADMIN) &&
                    <div>
                      <Divider />
                      <Typography variant="subtitle1" align="left" className="sub-menu">
                        Users
                      </Typography>
                      <Link to="/employees" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Employees">
                          <ListItemIcon><PeopleIcon /></ListItemIcon>
                          <ListItemText primary="Employees" />
                        </ListItem>
                      </Link>
                    </div>
                  }
                  {this.state.user.roles.includes(ROLES.ADMIN) &&
                    <div>
                      <Divider />
                      <Typography variant="subtitle1" align="left" className="sub-menu">
                        Planner
                      </Typography>
                      <Link to="/planner" style={{ textDecoration: 'none' }}>
                        <ListItem button key="Shipment Planner Tool">
                          <ListItemIcon><Icon>layers</Icon></ListItemIcon>
                          <ListItemText primary="Shipment Planner Tool" />
                        </ListItem>
                      </Link>
                    </div>
                  }
                  {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                    <div>
                      <Divider />
                      <Typography variant="subtitle1" align="left" className="sub-menu">
                        Masters
                      </Typography>
                      {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/master/fc" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Manage Fulfillment Centers">
                            <ListItemIcon><LocalMallIcon /></ListItemIcon>
                            <ListItemText primary="Manage Fulfillment Centers" />
                          </ListItem>
                        </Link>
                      }
                      {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/master/warehouse" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Manage Warehouses">
                            <ListItemIcon><StoreIcon /></ListItemIcon>
                            <ListItemText primary="Manage Warehouses" />
                          </ListItem>
                        </Link>
                      }
                    </div>
                  }
                  {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                    <div>
                      <Divider />
                      <Typography variant="subtitle1" align="left" className="sub-menu">
                        Reports
                      </Typography>
                      {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/report/itemreport" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Shipped Item Report">
                            <ListItemIcon><DescriptionIcon /></ListItemIcon>
                            <ListItemText primary="Shipped Item Report" />
                          </ListItem>
                        </Link>
                      }
                    </div>
                  }
                  {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                    <div>
                      <Divider />
                      <Typography variant="subtitle1" align="left" className="sub-menu">
                        Tools
                      </Typography>
                      {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/tools/shipping-label-splitter" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Shipping Label Splitter">
                            <ListItemIcon><LabelIcon /></ListItemIcon>
                            <ListItemText primary="Shipping Label Splitter" />
                          </ListItem>
                        </Link>
                      }
                      {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/tools/invoice-template" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Invoice Template">
                            <ListItemIcon><WebIcon /></ListItemIcon>
                            <ListItemText primary="Invoice Template" />
                          </ListItem>
                        </Link>
                      }
                      {(this.state.user.roles.includes(ROLES.ADMIN)) &&
                        <Link to="/tools/return-template" style={{ textDecoration: 'none' }}>
                          <ListItem button key="Return/Transfer Template">
                            <ListItemIcon><WebIcon /></ListItemIcon>
                            <ListItemText primary="Return/Transfer Template" />
                          </ListItem>
                        </Link>
                      }
                      <div>
                        <Divider />
                        <Typography variant="subtitle1" align="left" className="sub-menu">
                          Legacy
                        </Typography>
                        {(this.state.user.roles.includes(ROLES.ADMIN) || this.state.user.roles.includes(ROLES.STORE_MANAGER)) &&
                          <Link to="/shipments/legacy" style={{ textDecoration: 'none' }}>
                            <ListItem button key="Shipments">
                              <ListItemIcon><AssignmentIcon /></ListItemIcon>
                              <ListItemText primary="Shipments" />
                            </ListItem>
                          </Link>
                        }
                        {this.state.user.roles.includes(ROLES.ADMIN) &&
                          <Link to="/planner/legacy" style={{ textDecoration: 'none' }}>
                            <ListItem button key="Shipment Planner Tool">
                              <ListItemIcon><Icon>layers</Icon></ListItemIcon>
                              <ListItemText primary="Shipment Planner Tool" />
                            </ListItem>
                          </Link>
                        }
                      </div>
                    </div>
                  }
                  <Divider />
                </div>
              </div>
              <div>
                <Container maxWidth="sm" style={{ position: "absolute", bottom: 0 }} className={classes.version}>
                  <Typography variant="subtitle2" style={{ textAlign: "center", marginBottom: 4, paddingTop: 4 }}>
                    Version: {global.appVersion}
                  </Typography>
                </Container>
              </div>
            </Drawer>
          }
        </div>
        <Routes childProps={childProps} style={{ width: "50%" }} />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.sb.open}
          autoHideDuration={this.state.sb.autohide}
          onClose={this.handleSBClose}>
          <MySnackbarContent
            onClose={this.handleSBClose}
            variant={this.state.sb.variant}
            message={this.state.sb.message}
          />
        </Snackbar>
        <DialogMUI
          disableEnforceFocus
          open={this.state.openDialog}
          onClose={this.closeConfirmDialog}
          fullWidth={true}
          maxWidth='sm'
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {this.state.dialog_title ? <DialogTitle id="alert-dialog-title">{this.state.dialog_title}</DialogTitle> : ''}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.dialog_message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeConfirmDialog} color="primary">
              {this.state.confirmAction ? "Cancel" : "Ok"}
            </Button>
            {
              this.state.confirmAction &&
              <Button onClick={this.state.confirmAction} color="primary" autoFocus>
                Confirm
              </Button>
            }
          </DialogActions>
        </DialogMUI>
        <ProgressDialog open={this.state.progressOpen} message={this.state.progressMessage} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(App));
