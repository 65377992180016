import Config from '../Config';
import { RestService } from './RestService';

export const OrganisationService = {
    getOrganisationDetails: async function(){
        return RestService.get(Config.get_organisation_details, {})
        .then(response => response.json());
    },
    updateOrganisationDetails: async function(headers, body){
        return RestService.post(`${Config.update_organisation_details}`, headers, body);
        // .then(response => response.json());
    }
};