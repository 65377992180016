import Config from '../Config';
import { RestService } from './RestService';

export const ShipmentService = {
    getShipmentsReport: async function (start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction) {
        return RestService.get(`${Config.get_shipments_report}from=${start}&offset=${numberItems}${warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${filter ? `&filter=${filter}` : ''}${fc_id ? `&fc_id=${fc_id}` : ''}${interstate ? `&interstate=${interstate}` : ''}${status ? `&statuses=${status}` : ''}${item_id ? `&item_id=${item_id}` : ''}${startdate ? `&startdate=${startdate}` : ''}${enddate ? `&enddate=${enddate}` : ''}&sort=${sort}&direction=${direction}`, {})
            .then(response => response.json());
    },
    getShipments: async function (start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction) {
        return RestService.get(`${Config.get_shipments}from=${start}&offset=${numberItems}${warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${filter ? `&filter=${filter}` : ''}${fc_id ? `&fc_id=${fc_id}` : ''}${interstate ? `&interstate=${interstate}` : ''}${status ? `&statuses=${status}` : ''}${item_id ? `&item_id=${item_id}` : ''}${startdate ? `&startdate=${startdate}` : ''}${enddate ? `&enddate=${enddate}` : ''}&sort=${sort}&direction=${direction}`, {})
            .then(response => response.json());
    },
    getShipmentsV2: async function (start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction) {
        return RestService.get(`${Config.get_shipments_v2}from=${start}&offset=${numberItems}${warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${filter ? `&filter=${filter}` : ''}${fc_id ? `&fc_id=${fc_id}` : ''}${interstate ? `&interstate=${interstate}` : ''}${status ? `&statuses=${status}` : ''}${item_id ? `&item_id=${item_id}` : ''}${startdate ? `&startdate=${startdate}` : ''}${enddate ? `&enddate=${enddate}` : ''}&sort=${sort}&direction=${direction}`, {})
            .then(response => response.json());
    },
    getExport: async function (start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction) {
        return RestService.get(`${Config.get_shipments_export}from=${start}&offset=${numberItems}${warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${filter ? `&filter=${filter}` : ''}${fc_id ? `&fc_id=${fc_id}` : ''}${interstate ? `&interstate=${interstate}` : ''}${status ? `&statuses=${status}` : ''}${item_id ? `&item_id=${item_id}` : ''}${startdate ? `&startdate=${startdate}` : ''}${enddate ? `&enddate=${enddate}` : ''}&sort=${sort}&direction=${direction}`, {})
    },
    getExportV2: async function (start, numberItems, startdate, enddate, warehouse_id, fc_id, interstate, status, item_id, filter, sort, direction) {
        return RestService.get(`${Config.get_shipments_export_v2}from=${start}&offset=${numberItems}${warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${filter ? `&filter=${filter}` : ''}${fc_id ? `&fc_id=${fc_id}` : ''}${interstate ? `&interstate=${interstate}` : ''}${status ? `&statuses=${status}` : ''}${item_id ? `&item_id=${item_id}` : ''}${startdate ? `&startdate=${startdate}` : ''}${enddate ? `&enddate=${enddate}` : ''}&sort=${sort}&direction=${direction}`, {})
    },
    getShipmentById: async function (id) {
        return RestService.get(`${Config.get_shipment_id}id=${id}`, {})
            .then(response => response.json());
    },
    removeShipmentImage: async function (id) {
        return RestService.post(`${Config.remove_item_image}id=${id}`, {}, {})
            .then(response => response.json());
    },
    getShipmentDoc: async function (id) {
        return RestService.get(`${Config.get_shipment_doc}id=${id}`, {});
    },
    createShipment: async function (headers, body) {
        return RestService.post(`${Config.create_shipment}`, headers, body)
            .then(async response => {
                return response.json().then(json => {
                    return response.ok ? json : Promise.reject(json)
                });

                // if(response.ok) {
                //     return response.json();
                // } else if(response.status === 409){
                //     throw new Error('shipment_id_taken');
                // }
            });
    },
    createShipmentV2: async function (headers, body) {
        return RestService.post(`${Config.create_shipment_v2}`, headers, body)
            .then(async response => {
                return response.json().then(json => {
                    return response.ok ? json : Promise.reject(json)
                })
            });
    },
    uploadAppointmentDoc: async function (body) {
        return RestService.post(`${Config.upload_appointment_doc}`, {}, body)
            .then(response => response.json())
    },
    uploadSignedDoc: async function (body) {
        return RestService.post(`${Config.upload_signed_doc}`, {}, body)
            .then(response => response.json())
    },
    uploadItemImage: async function (body) {
        return RestService.post(`${Config.upload_item_image}`, {}, body)
            .then(response => response.json())
    },
    updateAppointmentDate: async function (body) {
        return RestService.post(`${Config.update_appointment}`, {}, body)
            .then(response => response.json())
    },
    // getProjectNames: async function(){
    //     return RestService.get(`${Config.get_project_names}`, {})
    //     .then(response => response.json());
    // },
    getTotalCount: async function (warehouse_id, fc_id, item_id, status, interstate, filter, startdate, enddate, version) {
        return RestService.get(`${Config.total_count}data_type=SHIPMENT${warehouse_id ? `&warehouse_id=${warehouse_id}` : ''}${filter ? `&filter=${filter}` : ''}${fc_id ? `&fc_id=${fc_id}` : ''}${interstate ? `&interstate=${interstate}` : ''}${status ? `&statuses=${status}` : ''}${item_id ? `&item_id=${item_id}` : ''}${startdate ? `&startdate=${startdate}` : ''}${enddate ? `&enddate=${enddate}` : ''}${version ? `&version=${version}` : ''}`, {})
            .then(response => response.json());
    },
    removeShipment: async function (id) {
        return RestService.post(`${Config.remove_shipment}id=${id}`, {})
            .then(response => response.json());
    },
    changeShipmentStatus: async function (headers, body) {
        return RestService.post(`${Config.change_shipment_status}`, headers, body);
        // .then(response => response.json());
    },
};