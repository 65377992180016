import Config from '../Config';

export const RestService = {
    get: async function (url, headers) {
        headers["Content-Type"] = "application/json";
        headers["sysbrew-x-token"] = Config.token;
        return fetch(url, {
            method: "GET",
            headers: headers
        });
    },
    post: async function (url, headers, body) {
        headers["Content-Type"] = "application/json";
        headers["sysbrew-x-token"] = Config.token;
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: body ? JSON.stringify(body) : null
        });
    },
    postFormData: async function (url, headers, body) {
        headers["enctype"] = "multipart/form-data";
        headers["sysbrew-x-token"] = Config.token;
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: body
        })
    }
};
