import React from 'react';
import { Toolbar, Typography, Tooltip, IconButton, Icon, CircularProgress } from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles, alpha, lighten } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = theme => ({
	parent: {
		minWidth: 300
	},
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	m5: {
		minWidth: 300,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.black, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.black, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: "black"
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 120,
			'&:focus': {
				width: 280,
			},
		},
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		right: '18%',
		marginTop: -14,
		marginLeft: -12,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
});

const EnhancedTableToolbar = props => {

	const classes = props.classes;

	const { numSelected = 0, exportReport, title, subtitle, refresh, onSearchTextChange, showProgress, loading, searchText, searchPlaceholder, SelectionComponent } = props
	let defaultPlaceholder = 'Search...';

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<div classes={classes.parent}>
				{numSelected > 0 ? (
					<Typography className={clsx(classes.title, classes.m5)} color="inherit" variant="subtitle1" component="div">
						{numSelected} selected
					</Typography>
				) : (
					title ?
						<Typography variant="h6" id="tableTitle" style={{ minWidth: 200 }}>
							{title} {subtitle ? subtitle : ""}
						</Typography> : null
				)}
			</div>
			<div style={{ flex: '1 2 100%' }} />
			{numSelected > 0 ? (
				<SelectionComponent />
			) : (
				<>
					{onSearchTextChange &&
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder={defaultPlaceholder}
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onFocus={(e) => e.target.placeholder = searchPlaceholder || defaultPlaceholder}
								onBlur={(e) => e.target.placeholder = defaultPlaceholder}
								onChange={(e) => onSearchTextChange(e)}
								value={searchText ? searchText : ""}
							/>
						</div>
					}
					{exportReport &&
						<div style={{ color: 'red' }} className={classes.wrapper}>
							<Tooltip title="Export">
								<IconButton aria-label="export" onClick={exportReport} disabled={loading}>
									<Icon>save_alt</Icon>
								</IconButton>
							</Tooltip>
							{showProgress && loading && <CircularProgress size={30} className={classes.buttonProgress} />}
						</div>
					}
					{
						refresh &&
						<div style={{ color: 'red' }}>
							<Tooltip title="Refresh">
								<IconButton aria-label="refresh" onClick={refresh}>
									<Icon>refresh</Icon>
								</IconButton>
							</Tooltip>
						</div>
					}
				</>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	exportReport: PropTypes.func,
	title: PropTypes.any,
	subtitle: PropTypes.string,
	refresh: PropTypes.func,
	onSearchTextChange: PropTypes.func,
	searchText: PropTypes.string,
	showProgress: PropTypes.bool,
	loading: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
	numSelected: PropTypes.number,
	selectionComponent: PropTypes.any,
};

export default withStyles(styles)(EnhancedTableToolbar);