import React, { Component } from "react";
import "../css/employee.css";
import { Form, Button, OverlayTrigger, Modal, Glyphicon } from 'react-bootstrap';
import {tooltip, popover} from '../Utils';
import { UserService } from "../Services/UserService";
import { RoleService } from "../Services/RoleService";
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import {
    Paper,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core';

const styles = theme => ({
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      paddingTop : theme.spacing(1), 
      paddingLeft: theme.spacing(1), 
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    table: {
        minWidth: 500,
    },
  });

class Employee extends Component {

    constructor(props) {
      super(props);
      this.state = {
        data: [],
        originalData: [],
        rows: [],
        new_employee: { name: "", mobile_no: "", code: "", email: "", roles: []},
        show: false,
        master_roles: new Map(),
        rolesCheckBox: []
      }

      this.toggleStatus = this.toggleStatus.bind(this);
      this.createModifyEmployee = this.createModifyEmployee.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
      this.edit = this.edit.bind(this);
      this.remove = this.remove.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.hasAdmin = this.hasAdmin.bind(this);
    }
 
    componentDidMount(){
        this.props.setTitle("Employees");
        this.fetchRoles();
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({
            new_employee: { name: "", mobile_no: "", code: "", email: "", roles: []}
        });
        this.resetCheckBoxes();
    }

    handleShow() {
        this.setState({ show: true });
    }

    fetchData(){
        this.props.isLoading(true);
        UserService.getEmployeesList()
        .then((data) => {
            console.log(data);
            this.setState({data: data.users,  originalData: data.users});
            this.props.setTitle(`Employees (${data.users.length})`);
            this.updateRows(data.users);
            this.props.isLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
        });
    }

    updateRows(data){
        let rows = [];
        data.forEach((user) => {
            rows.push(<UserDetails key={user.id} user={user} toggleStatus={this.toggleStatus} edit={this.edit} remove={this.remove} roles={this.state.master_roles}/>);
        });
        this.setState({rows: rows});
    }

    toggleStatus(e,user){
        e.preventDefault();
        UserService.toggleStatus(user.id)
        .then((data) => {
            console.log(data);
            const status = data.status === 0 ? "Deactivated": "Activated";
            this.props.showSnackBar(`${status} employee ${data.name} Account`, 'info');
            this.fetchData();
        })
        .catch(error => console.log(error));
    }

    fetchRoles(){
        this.props.isLoading(true);
        RoleService.getRoleList()
        .then((data) => {
            data = data.roles;
            let roleDetailMap = new Map();
            data.forEach(role => {
                roleDetailMap.set(role.id, role);
            });
            this.setState({master_roles: roleDetailMap},  this.fetchData());
            let rows = [];
            data.forEach((role) => {
                rows.push(
                    <OverlayTrigger key={role.id} placement="left" overlay={popover(role.description)}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={false}
                                    onChange={this.handleChangeCheckBox}
                                    color="primary"
                                    name={role.id}
                                    />
                            }
                            label={role.name}
                            />
                    </OverlayTrigger>
                );
            });
            this.setState({rolesCheckBox: rows});
            this.props.isLoading(false);
        })
        .catch(error => {
            console.log(error);
            this.props.isLoading(false);
        });
    }

    filterCriteria(e){
        this.props.isLoading(true);
        if(e)
            e.preventDefault();
        const searchText = this.refs.inputText.value;
        let filteredList = this.state.originalData.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
        if(this.refs.inputText.value.length === 0){
            filteredList = this.state.originalData;
        }
        this.setState({
            data: filteredList,
            message: filteredList.length > 0 ? '' : 'No matching Employee'
        });
        this.updateRows(filteredList);
        this.props.isLoading(false);
    }

    handleChange(e) {
        e.preventDefault();
        let change = this.state.new_employee;
        change[e.target.name] = e.target.value;
        this.setState(change);
    }

    handleChangeCheckBox(e) {
        let change = this.state.new_employee;
        if(e.target.checked && !change['roles'].includes(e.target.name)){
            change['roles'].push(e.target.name);
        } else {
            var index = change['roles'].indexOf(e.target.name);
            change['roles'].splice(index, 1);
        }
        this.setState({new_employee: change});
    }

    hasAdmin() {
        let hasAdminRole = false;
        this.state.new_employee.roles.forEach((roleId) => {
            if(this.state.master_roles.get(roleId).name === "ADMIN"){
                hasAdminRole = true;
            }
        });
        return hasAdminRole;
    }

    createModifyEmployee(e){
        e.preventDefault();
        if(this.state.new_employee.name && this.state.new_employee.mobile_no && this.state.new_employee.code.length >= 4 && this.state.new_employee.roles.length > 0){
            if(this.hasAdmin() || (!this.hasAdmin() && (!this.state.new_employee.id || (this.state.new_employee.id && this.state.new_employee.id !== this.props.user.id)))){
                this.props.showConfirmDialog("Confirm?", undefined, () => {
                    this.createEmployeeAPI(this.state.new_employee);
                    this.props.closeConfirmDialog();
                });
            } else {
                this.props.showConfirmDialog("Cannot remove Admin role for currently logged in user", undefined, undefined);
            }
        } else if(this.state.new_employee.name && this.state.new_employee.mobile_no && this.state.new_employee.roles.length === 0){
            this.props.showSnackBar(`Please select atleast one role`, 'error');
        } else if(this.state.new_employee.code.length < 4){
            this.props.showSnackBar(`Code should be atleast 4 characters`, 'error');
        } else {
            this.props.showSnackBar(`Please fill all the details and continue`, 'error');
        }
    }

    remove(e, user){
        e.preventDefault();
        if(this.props.user.id !== user.id){
            this.props.showConfirmDialog("Confirm removal of Employee?", undefined, () => {
                this.removeEmployeeAPI(this.dialog, user.id);
                this.props.closeConfirmDialog();
            });
        } else {
            this.props.showConfirmDialog("Cannot remove yourself", undefined, undefined);
        }
    }

    removeEmployeeAPI(dialog, id){
        UserService.removeEmployee(id)
        .then((data) => {
            console.log(data);
            this.fetchData();
        })
        .catch((error) => {
            this.props.showConfirmDialog("Could not remove Employee. Please try again.", undefined, undefined);
        });
        
    }

    edit(e, user){
        e.preventDefault();
        if(user){
            let v= this.handleChangeCheckBox;
            this.setState({new_employee: user}, function () {
                let rows = [];
                this.state.master_roles.forEach((value, key, map) => {
                    rows.push(
                        <OverlayTrigger key={key} placement="left" overlay={popover(value.description)}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={user.roles.includes(value.id)}
                                        onChange={v}
                                        color="primary"
                                        name={value.id}
                                        />
                                }
                                label={value.name}
                            />
                        </OverlayTrigger>
                    );
                });
                this.setState({rolesCheckBox: rows});
                this.handleShow();
            });
        } else {
            this.handleShow();
        }
    }

    resetCheckBoxes(){
        let rows = [];
        this.state.master_roles.forEach((value, key, map) => {
            rows.push(
                <OverlayTrigger key={key} placement="left" overlay={popover(value.description)}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={false}
                                    onChange={this.handleChangeCheckBox}
                                    color="primary"
                                    name={value.id}
                                    />
                            }
                            label={value.name}
                        />
                </OverlayTrigger>
            );
        });
        this.setState({rolesCheckBox: rows});
    }

    createEmployeeAPI(new_employee){
        //new_employee.code = 1234;
        let body = new_employee;
        this.props.isLoading(true);
        let headers = {user_id : this.props.user.id };
        UserService.createEmployee(headers, body)
        .then((response) => {
            if(response.status === 409){
                this.props.showSnackBar(`Employee already exists with the same mobile no. Please check again.`, 'error');
            } else {
                console.log(response);
                this.setState({
                    new_employee: { name: "", mobile_no: "", code: "", roles: []}
                });
                this.fetchData();
                if(this.state.show) this.handleClose();
            }
            this.props.isLoading(false);
        })
        .catch((error) => {
            this.props.isLoading(false);
            this.props.showConfirmDialog(`Failed to create Employee. Please try again.`, undefined, undefined);
        });
        
    }

    render() {
        let message = this.state.message;
        let {classes} = this.props;
        return (
            <div className="Home">
                {/* <h3 style={{marginBottom: '20px'}}>Employees <Badge>{this.state.data.length}</Badge></h3> */}
                <div style={{marginTop: 40}}>
                    <Paper className={classes.root}>
                        <form onSubmit={(e) => this.filterCriteria(e)} ref="searchForm">
                            <input ref="inputText" type="text" name="searchText" placeholder="Search" onChange={(e) => this.filterCriteria(e)} style={{width: "100%", marginBottom: "10px", padding: "5px", borderColor:"red"}} autoComplete="off"></input>
                        </form>
                        <p style={{color:"red", paddingLeft:"20px"}}>{message}</p>
                            { !message &&
                                <Table className={classes.table}>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell><Typography variant="subtitle1">Name</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="subtitle1">Mobile No</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="subtitle1">Roles</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="subtitle1">Email</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="subtitle1">Status</Typography></TableCell>
                                        <TableCell align="center"><Typography variant="subtitle1">Created By</Typography></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.rows}
                                    </TableBody>
                                </Table>
                            }
                    </Paper>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose} style={{marginTop : "5%"}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.show ? "Employee" : ""}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.userForm( "Edit Employee", this.createModifyEmployee, "Save")}
                    </Modal.Body>
                </Modal>
                <OverlayTrigger placement="top" overlay={tooltip("Add Employee")}>
                    <Fab onClick={this.edit} color="primary" aria-label="Add" className={this.props.classes.fab}>
                        <AddIcon />
                    </Fab>
                </OverlayTrigger>
            </div>
        );
    }

    userForm = (title, submitFunction, buttonText, orientation) => (
        <Form {...orientation} className="" onSubmit={submitFunction}>
            <TextField
                id="name"
                label="Name"
                placeholder="Name"
                name="name"
                defaultValue={this.state.new_employee.name}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                />
            <TextField
                id="mobile_no"
                label="mobile_no"
                placeholder="Mobile no"
                name="mobile_no"
                defaultValue={this.state.new_employee.mobile_no}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                pattern="[0-9]{10}"
                type="text"
                InputProps={{
                    inputProps: { min: 0, maxLength: 10 }
                }}
                />
            <TextField
                id="outlined-email-input"
                label="Email"
                className={this.props.classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                defaultValue={this.state.new_employee.email}
                onChange={this.handleChange}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                />
            <TextField
                id="code"
                label="code"
                placeholder="Code"
                name="code"
                defaultValue={this.state.new_employee.code}
                className={this.props.classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                required
                fullWidth
                pattern="[0-9]{4}"
                type="text"
                InputProps={{
                    inputProps: { min: 0, maxLength: 4 }
                }}
                />
                <FormGroup row>
                    {this.state.rolesCheckBox}
                </FormGroup>
                <Button type="submit" onClick={submitFunction} style={{marginRight: "10px"}}>{buttonText}</Button>
                {this.state.show ? (<Button onClick={this.handleClose}>Close</Button>): ""}
        </Form>
    )

}

class UserDetails extends Component {

    render(){
      const user = this.props.user;
      const roles = this.props.roles;
        return (
            <TableRow key={user.id} style={{backgroundColor : user.status === 0 ?  "#f6685e" : "#a2cf6e", color: "white"}}>
                <TableCell component="th" scope="row"><Typography variant="subtitle2">{user.name}</Typography></TableCell>
                <TableCell align="center"><Typography variant="subtitle2">{user.mobile_no}</Typography></TableCell>
                <TableCell align="center"><Typography variant="subtitle2">
                    {
                        user.roles.reduce(function(accumulator, currentValue){
                            return accumulator + ", " + roles.get(currentValue).name ;
                        }, "").substring(1)
                    }
                    </Typography>
                </TableCell>
                <TableCell align="center"><Typography variant="subtitle2">{user.email ? user.email : "-"}</Typography></TableCell>
                <TableCell align="center"><Typography variant="button">{user.status === 0 ? "Deactive" : "Active"}</Typography></TableCell>
                <TableCell align="center"><Typography variant="caption">{user.created_by}</Typography></TableCell>
                <TableCell align="right">
                    <OverlayTrigger placement="bottom" overlay={tooltip("Activate/Deactivate")}>
                        <Glyphicon style={{marginRight: "10px"}} glyph="off" onClick={(e) => this.props.toggleStatus(e, user)}/>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltip("Edit")}>
                        <Glyphicon style={{marginRight: "10px"}} glyph="pencil" onClick={(e) => this.props.edit(e, user)}/>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltip("Remove Employee")}>
                        <Glyphicon style={{marginRight: "10px"}} glyph="trash" onClick={(e) => this.props.remove(e, user)}/>
                    </OverlayTrigger>
                </TableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(Employee);