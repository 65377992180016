const organisation = "aarti";

const host = process.env.NODE_ENV === 'production' ? "https://smapi.asgmantra.in/" : "https://smapi.asgmantra.in/";

const Config = {
    token: "NjYzYzgyNzYyNGNmOWQzMmJjNzcxNjU2YTkwMDVmYjhlMDEzNGE3NThkNjExMWJjZWZhZGUwZGU1NDE2OGI1Ng==",

    authenticate_url: `${host}authenticate.php?organisation=${organisation}&`,
    get_roles: `${host}get_roles.php?organisation=${organisation}&`,
    toggle_user_status: `${host}toggle_user_status.php?organisation=${organisation}&`,
    total_count: `${host}total_count.php?organisation=${organisation}&`,
    //NEW

    // TOOLS
    get_job_actions: `${host}get_job_actions.php?organisation=${organisation}&`,
    create_job_action: `${host}create_job_action.php?organisation=${organisation}&`,
    remove_job_action: `${host}remove_job_action.php?organisation=${organisation}&`,

    // Items
    get_items: `${host}get_items.php?organisation=${organisation}&`,
    create_item: `${host}create_item.php?organisation=${organisation}&`,
    remove_item: `${host}remove_item.php?organisation=${organisation}&`,
    disable_item: `${host}disable_item.php?organisation=${organisation}&`,

    //Employees
    get_employees_url: `${host}get_users.php?organisation=${organisation}&type=EMPLOYEE&`,
    create_employee: `${host}create_user.php?organisation=${organisation}&type=EMPLOYEE&`,
    remove_employee: `${host}remove_user.php?organisation=${organisation}&type=EMPLOYEE&`,

    //Fulfillment Centers
    get_fc: `${host}get_fc.php?organisation=${organisation}&`,
    create_fc: `${host}create_fc.php?organisation=${organisation}&`,
    remove_fc: `${host}remove_fc.php?organisation=${organisation}&`,

    //Warehouses
    get_warehouses: `${host}get_warehouses.php?organisation=${organisation}&`,
    create_warehouse: `${host}create_warehouse.php?organisation=${organisation}&`,
    remove_warehouse: `${host}remove_warehouse.php?organisation=${organisation}&`,

    //Shipments
    get_shipments_report: `${host}get_shipments.report.php?organisation=${organisation}&`,
    get_shipments: `${host}get_shipments.php?organisation=${organisation}&`,
    get_shipments_v2: `${host}get_shipments.v2.php?organisation=${organisation}&`,
    get_shipments_export: `${host}get_shipments_export.php?organisation=${organisation}&`,
    get_shipments_export_v2: `${host}get_shipments_export.v2.php?organisation=${organisation}&`,
    get_shipment_id: `${host}get_shipment_id.php?organisation=${organisation}&`,
    remove_item_image: `${host}remove_item_image.php?organisation=${organisation}&`,
    get_shipment_doc: `${host}get_shipment_doc.php?organisation=${organisation}&`,
    get_project_names: `${host}get_project_names.php?organisation=${organisation}&`,
    remove_shipment: `${host}remove_shipment.php?organisation=${organisation}&`,
    create_shipment: `${host}create_shipment.php?organisation=${organisation}&`,
    create_shipment_v2: `${host}create_shipment.v2.php?organisation=${organisation}&`,
    change_shipment_status: `${host}change_shipment_status.php?organisation=${organisation}&`,
    upload_appointment_doc: `${host}upload_appointment_doc.php?organisation=${organisation}&`,
    update_appointment: `${host}update_appointment.php?organisation=${organisation}&`,
    upload_item_image: `${host}upload_item_image.php?organisation=${organisation}&`,
    upload_signed_doc: `${host}upload_signed_doc.php?organisation=${organisation}&`,

    //Returns
    get_returns: `${host}get_returns.php?organisation=${organisation}&`,
    get_returns_export: `${host}get_returns_export.php?organisation=${organisation}&`,
    remove_returns: `${host}remove_returns.php?organisation=${organisation}&`,
    create_returns: `${host}create_returns.php?organisation=${organisation}&`,
    change_returns_status: `${host}change_returns_status.php?organisation=${organisation}&`,

    //Job Status Transactions
    get_job_status_transactions: `${host}get_job_status_transactions.php?organisation=${organisation}&`,
    create_job_status_transaction: `${host}create_job_transactions.php?organisation=${organisation}&`,

    //Store Transactions
    get_store_transactions: `${host}get_store_transactions.php?organisation=${organisation}&`,
    create_store_transaction: `${host}create_store_transactions.php?organisation=${organisation}&`,

    //Organisation
    get_organisation_details: `${host}get_organisation_details.php?organisation=${organisation}&`,
    update_organisation_details: `${host}update_organisation_information.php?organisation=${organisation}&`,

    //Upload Utils
    upload_url: `${host}upload_label.php?organisation=${organisation}&`,
    upload_fba_url: `${host}upload_fba_label.php?organisation=${organisation}&`,
    upload_smytten_url: `${host}upload_smytten_label.php?organisation=${organisation}&`,
    
    import_stock_transfer: `${host}stock_transfer_upload.php?organisation=${organisation}&`,

    defaultAlerts: {
        import: {
            hr: "18",
            min: "0"
        }
    }
}

export default Config;

export const RowCountOptions = [10, 25, 50, 100];