import React, { Component } from "react";
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

class ItemDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
        }
    }


    handleChange = (e, field) => {
        this.props.handleItemChange(e, field);
        if ((parseFloat(e.target.value) || 0) <= 0) {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });
        }
    }

    render() {
        const item = this.props.item;
        const item_details = this.props.master_items.filter(i => i.id === item.item_id)[0];
        return (
            <ListItem key={item.item_id} role={undefined} dense button>
                <ListItemText primary={<Typography type="subtitle1">{item.item_name}</Typography>}
                    secondary={<Typography type="title"><b>FNSKU:</b> {item_details.fnsku}</Typography>} style={{ width: "10%" }} />
                <ListItemText primary={<Typography type="title"><b>HSN:</b> {item_details.hsn_code}</Typography>} style={{ width: "5%" }} />
                <ListItemText primary={<Typography type="title"><b>Rate:</b> {item.rate || item_details.rate}</Typography>} style={{ width: "5%" }} />
                <TextField
                    label="Units"
                    error={this.state.error}
                    defaultValue={item.units}
                    onChange={(e) => this.handleChange(e, 'units')}
                    type="number"
                    name={item.item_id}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={this.props.classes.qtyField}
                    margin="dense"
                    variant="outlined"
                    required
                    helperText="Enter no of units"
                />
                <ListItemSecondaryAction>
                    <IconButton aria-label="Delete" onClick={(e) => this.props.removeItem(e, item)} className={this.props.classes.icon}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
}

export default ItemDetails 