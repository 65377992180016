import Config from '../Config';
import { RestService } from './RestService';

export const WarehouseService = {
    getWarehouses: async function(){
        return RestService.get(`${Config.get_warehouses}`, {})
        .then(response => response.json());
    },
    create: async function(headers, body){
        return RestService.post(`${Config.create_warehouse}`, headers, body);
        // .then(response => response.json());
    },
    remove: async function(headers, id){
        return RestService.post(`${Config.remove_warehouse}id=${id}`, headers, {});
        // .then(response => response.json());
    }
};