import React, { Component } from "react";
import { Avatar, Checkbox, Icon, IconButton, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { format_display, getShipmentStatusBadge, getTimeInIST, toInt } from '../Utils';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';

class ShipmentDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    handleClose = (open) => {
        this.setState({ open })
    }

    render() {
        const { shipment, isSelected, handleClick } = this.props;
        const classes = this.props.classes;
        const labelId = `enhanced-table-checkbox-${shipment.id}`;

        return (
            <TableRow key={shipment.id}>
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isSelected(shipment.id)}
                        onClick={(e) => handleClick(e, shipment.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell scope="row" align="center" padding='none'>
                    {shipment.interstate === 1 ? <Avatar className={classes.orangeAvatar}>INT</Avatar> : <Avatar className={classes.purpleAvatar}>LOC</Avatar>}
                </TableCell>
                <TableCell component="th" scope="row" align="center" padding='none'>
                    <Typography variant="subtitle2">{shipment.shipment_identifier}</Typography>
                </TableCell>
                <TableCell align="center" padding='none'><Typography variant="subtitle2"> {shipment.invoice_no}</Typography></TableCell>
                <TableCell align="center" padding='none'><Typography variant="subtitle2">{shipment.warehouse_name}</Typography></TableCell>
                <TableCell align="center" padding='none'><Typography variant="subtitle2">{shipment.fc_name}</Typography></TableCell>
                <TableCell align="center" padding='none'>
                    <Typography variant="body1" color="textSecondary">{toInt(shipment.interstate) === 1 ? "Interstate" : "Local"}</Typography>
                    {shipment.vehicle_no && <Typography variant="subtitle2" color="textSecondary">{shipment.vehicle_no}</Typography>}
                </TableCell>
                <TableCell align="center" padding='none'>{getTimeInIST(shipment.shipment_date).format(format_display)}</TableCell>
                <TableCell align="center" padding='none' style={{ paddingTop: 10 }}>
                    {shipment.appointment && shipment.appointment_doc ? <CheckCircleIcon style={{ color: green[500] }} /> : <CancelIcon style={{ color: red[500] }} />}
                </TableCell>
                <TableCell align="center" padding='none' style={{ paddingTop: 10 }}>
                    {shipment.signed_doc ? <CheckCircleIcon style={{ color: green[500] }} /> : <CancelIcon style={{ color: red[500] }} />}
                </TableCell>
                <TableCell align="center" padding='none' style={{ paddingTop: 10 }}>
                    <Typography variant="subtitle2" onClick={(e) => this.props.changeStatus(e, shipment)}>
                        {getShipmentStatusBadge(shipment.status)}
                        <br />
                        {getTimeInIST(shipment.modified_date).format(format_display)}
                    </Typography>
                </TableCell>
                <TableCell align="right" padding='none'>
                    <div style={{ marginTop: 5 }}>
                        <Tooltip title="View Details" arrow>
                            <IconButton size="small" variant="outlined" style={{ marginRight: 10 }} onClick={(e) => this.props.showDetails()}>
                                <ViewListIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Label" arrow>
                            <IconButton size="small" variant="outlined" style={{ marginRight: 10 }} onClick={(e) => this.props.printLabel()}>
                                <LabelIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Invoice & Packing List" arrow>
                            <IconButton size="small" variant="outlined" style={{ marginRight: 10 }} onClick={(e) => this.props.printInvoice(e, shipment)}>
                                <ReceiptIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Packing List" arrow>
                            <IconButton size="small" color="primary"  style={{marginRight: 10}} onClick={(e) => this.props.showPackingList(e, shipment)}>
                                <FormatListNumberedIcon />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Duplicate Shipment" arrow>
                            <IconButton size="small" style={{ marginRight: 10 }} onClick={(e) => this.props.duplicateShipment(e, shipment)}>
                                <FileCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {shipment.status !== '2' && shipment.status !== '3' &&
                            <Tooltip title="Edit" arrow>
                                <IconButton size="small" variant="contained" style={{ marginRight: 10 }} onClick={(e) => this.props.edit(e, shipment)}>
                                    <Icon fontSize="small">edit</Icon>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            shipment.status !== '2' &&
                            <Tooltip title="Remove Shipment" arrow>
                                <IconButton size="small" color="secondary" style={{ marginRight: 10 }} onClick={(e) => this.props.removeShipment(e, shipment)}>
                                    <Icon fontSize="small">delete</Icon>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </TableCell>
            </TableRow>
        );
    }
}

export default ShipmentDetails