import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "../css/login.css";
import { UserService } from "../Services/UserService";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoading: false,
        mobile_no: "",
        code: "",
        user: this.props.user
    };
  }

  componentDidMount(){
    const user = JSON.stringify(localStorage.getItem('user'));
    // console.log(user);
    if(user == null) {
        this.props.history.push("/");
    };
  }

  validateForm() {
    return this.state.mobile_no.length > 9 && this.state.code.length > 3;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
        let body = { mobile_no : this.state.mobile_no,
                    code : this.state.code
                    };
        console.log(JSON.stringify(body));
        UserService.authenticate(body)
        .then((data) => {
            if ( data.id && data.status === 1 ) {
                this.props.showSnackBar("Logged in" , 'success');
                this.props.updateUser(data);
                console.log("Logged in");
                console.log(data);
                localStorage.setItem('user', JSON.stringify(data));
                this.props.userHasAuthenticated(true);
                this.props.history.push("/");
            } else if(data.status === 0){
              this.props.showSnackBar("Your account has been deactivated. Contact Admin" , 'error');
              this.setState({ isLoading: false });
            }
        })
        .catch((error) => {
          this.props.showSnackBar("Failed to Login. invalid credentials" , 'error');
            this.setState({ isLoading: false });
        });

        
      } catch (e) {
        alert(e.message);
        this.setState({ isLoading: false });
      }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="mobile_no" bsSize="large">
            <ControlLabel>Mobile</ControlLabel>
            <FormControl
              autoFocus
              required
              type="text"
              value={this.state.mobile_no}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="code" bsSize="large">
            <ControlLabel>Code</ControlLabel>
            <FormControl
              ref="code"
              value={this.state.code}
              required
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Login"
                loadingText="Logging in…"
                />
        </form>
      </div>
    );
  }
}