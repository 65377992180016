import Config from '../Config';
import {RestService} from './RestService';

export const UserService = {
    getEmployeesList: async function(start, numberItems, filter, sort, direction){
        return RestService.get(`${Config.get_employees_url}`, {})
        .then(response => response.json());
    },
    createEmployee: async function(headers, body) {
        return RestService.post(`${Config.create_employee}`, headers, body)
        .then(response => response.json());
    },
    removeEmployee: async function(id) {
        return RestService.post(`${Config.remove_employee}id=${id}`, {})
        .then(response => response.json());
    },
    toggleStatus: async function(id) {
        return RestService.get(`${Config.toggle_user_status}user_id=${id}`, {})
        .then(response => response.json())
    },
    getContractorList: async function(){
        return RestService.get(`${Config.get_contractors_url}`, {})
        .then(response => response.json());
    },
    removeContractor: async function(id) {
        return RestService.post(`${Config.remove_contractor}id=${id}`, {})
        .then(response => response.json());
    },
    createContractor: async function(headers, body) {
        return RestService.post(`${Config.create_contractor}`, headers, body)
        .then(response => response.json());
    },

    getTotalCount: async function(type) {
        return RestService.get(`${Config.total_count}type=${type}&data_type=USER`, {})
        .then(response => response.json());
    },
    authenticate: async function(body){
        return RestService.post(`${Config.authenticate_url}`, {}, body)
                .then(response => response.json());
    },
};